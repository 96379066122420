import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import useLogout from "../hooks/useLogout";
import SehatSpinnerIcon from "../icons/sehat-smooth.gif";

function SehatSpinner() {
  const performLogout = useLogout();
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          src={SehatSpinnerIcon}
          alt="Sehat Spinner"
          height="128px"
          width="128px"
        />
        <Stack
          direction="row"
          sx={{ mr: "auto", ml: "1em", mb: "6px", mt: "9px" }}
        >
          <Typography
            variant="h1"
            marginRight={"0.2em"}
            color={theme.palette.primary.main}
          >
            Sehat
          </Typography>
          <Typography variant="h1" color={theme.palette.secondary.main}>
            Sanjha
          </Typography>
        </Stack>
      </div>
      <button
        onClick={performLogout}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1000,
          color: `${theme.palette.primary.main}`,
          border: "none",
          padding: "10px 20px",
          cursor: "pointer",
          background: "none",
        }}
      >
        Logout
      </button>
    </>
  );
}

export function SmallSehatSpinner({ size = 64 }: { size?: number }) {
  const sizeString = `${size}px`;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={SehatSpinnerIcon}
        alt="Sehat Spinner"
        height={sizeString}
        width={sizeString}
      />
    </Box>
  );
}

export default SehatSpinner;
