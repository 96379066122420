import React from "react";
import { Box } from "@mui/material";

interface EditModeHeadingProps {
  type: "Add" | "Edit";
  title: string;
}

const EditModeHeading: React.FC<EditModeHeadingProps> = ({ type, title }) => {
  return (
    <>
      <Box
        sx={{
          marginBottom: "6px",
          display: "flex",
          justifyContent: "left",
        }}
      >
        <h1
          style={{
            margin: "0",
            fontSize: "18px",
            color: "#343a40",
            fontWeight: "normal",
          }}
        >
          {type === "Add" ? <>Add</> : <>Edit</>} {title}
        </h1>
      </Box>
      <Box
        sx={{ marginBottom: "2px", display: "flex", justifyContent: "left" }}
      >
        <h2
          style={{
            margin: "0",
            fontSize: "16px",
            color: "#6c757d",
            fontWeight: "normal",
          }}
        >
          Please {type === "Add" ? <>add</> : <>update</>} {title} details
        </h2>
      </Box>
    </>
  );
};

export default EditModeHeading;
