import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  Typography,
  Box,
  TextFieldProps,
} from "@mui/material";

interface MultiSelectFieldProps
  extends Omit<TextFieldProps, "multiple" | "onChange" | "value"> {
  name: string;
  label: string;
  options: string[];
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  value?: string[];
  required?: boolean;
  onChange?: (value: string[]) => void;
}

const MultiSelectListField: React.FC<MultiSelectFieldProps> = ({
  name,
  label,
  options = [],
  error = false,
  helperText = "",
  value = [],
  onChange,
  required = false,
  ...rest
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleChange = (option: string) => {
    if (option === "Select All") {
      const allOptions = options.filter(
        (opt) =>
          opt !== "Select All" && !opt.startsWith("No ") && opt !== "Other"
      );
      setSelectedOptions(allOptions);
      onChange?.(allOptions);
    } else if (option.startsWith("No ")) {
      setSelectedOptions([option]);
      onChange?.([option]);
    } else {
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];

      setSelectedOptions(updatedOptions);
      onChange?.(updatedOptions);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color={active ? "#008AD8" : "text.secondary"}
        textAlign="left"
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "2px" }}>*</span>}
      </Typography>

      <Box sx={{ width: "100%", textAlign: "left" }}>
        <Select
          multiple
          size="small"
          value={selectedOptions}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          onChange={() => {}}
          displayEmpty
          renderValue={(selected) =>
            selected.length === 0 ? <em>--</em> : selected.join(", ")
          }
          fullWidth
          sx={{
            height: "clamp(35px, 4vh, 40px)",
            border: `1px solid ${
              required && value.length === 0
                ? "orange"
                : error
                ? "red"
                : active
                ? "#008AD8"
                : "#CCD4DB"
            }`,
            backgroundColor: "white",
            fontSize: "16px",
            textAlign: "left",
            borderRadius: "8px",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={() => handleChange(option)}
              />
              {option}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <Typography variant="caption" color="error" sx={{ marginTop: "4px" }}>
            {helperText}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default MultiSelectListField;
