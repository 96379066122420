import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography, IconButton, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { RootState } from "../../../../state/reducers";
import MsgIndcatorIcon from "../../../../assets/MsgIndication.svg";
import {
  getClassConflict,
  extractNumber,
  CheckTitle,
} from "../../../../utils/medsUtils";
import WarningText from "../../../WarningText";
import { Medicine } from "./Medicines";

interface ListViewProps {
  Bin: Medicine;
  MedsTradeNames: string[];
}

const ListView: React.FC<ListViewProps> = ({
  Bin,
  MedsTradeNames,
}): JSX.Element => {
  const keys = Object.keys(Bin);
  const values = Object.values(Bin);

  const allergenNames = useSelector(
    (state: RootState) => state.allergiesBin.allergenNames
  );
  const AllergenConflictPardonMedicines = useSelector(
    (state: RootState) => state.binsUtilArrays.AllergenConflictPardons
  );
  const SameClassPardonMedicines = useSelector(
    (state: RootState) => state.binsUtilArrays.SameClassPardons
  );
  const { TradeNameGlossary } = useSelector(
    (state: RootState) => state.MedicineBinGlossary
  );

  let incomplete = false;
  let highDosage = false;
  let SameClass = false;
  let AllergenConflict = false;
  let dosage = 0;

  for (let i = 0; i < values.length; i++) {
    if (
      keys[i] !== "Deleted" &&
      keys[i] !== "Delete_Reason" &&
      keys[i] !== "Reviewed_by" &&
      keys[i] !== "Reviewed_at" &&
      keys[i] !== "Discontinued_by" &&
      keys[i] !== "Note" &&
      (values[i] === "" || values[i] === undefined)
    ) {
      incomplete = true;
      break;
    }
  }

  const number: string = Bin.Number;
  const unit: string = Bin.Unit;
  const salt: string = Bin.Salt;
  const route: string = Bin.Route;
  const freq: string = Bin.Frequency;
  const tradeName: string = Bin.Trade_Name;

  if (
    Bin.Salt.toLowerCase().includes("paracetamol") ||
    Bin.Salt.toLowerCase().includes("aspirin") ||
    Bin.Salt.toLowerCase().includes("ibuprofen")
  ) {
    dosage = extractNumber(Bin.Number) * extractNumber(Bin.Frequency);
    if (dosage > 4000) {
      highDosage = true;
    } else {
      highDosage = false;
    }
  }

  const ClassConflictStatus = getClassConflict(
    Bin.Trade_Name,
    MedsTradeNames,
    TradeNameGlossary
  );
  if (
    !SameClassPardonMedicines.includes(Bin.Trade_Name) &&
    ClassConflictStatus[0] === "true"
  ) {
    SameClass = true;
  }

  const glossaryClasses = TradeNameGlossary[Bin.Trade_Name]?.Class?.split(
    ","
  ).map((cls: string) => cls.trim());
  if (
    !AllergenConflictPardonMedicines.includes(Bin.Trade_Name) &&
    ((glossaryClasses &&
      allergenNames.some((name) => glossaryClasses.includes(name))) ||
      allergenNames.includes(Bin.Trade_Name))
  ) {
    AllergenConflict = true;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "8px",
          marginTop: "0vh",
          marginBottom: "1vh",
          cursor: "pointer",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack>
            <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
              <Typography
                component="div"
                variant="h6"
                sx={{
                  color: incomplete ? "orange" : "Black",
                  mr: 1,
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {CheckTitle({
                  key: "Trade_Name",
                  value: tradeName,
                  complete: !incomplete,
                  trimLength: 15,
                })}
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: "gray",
                  mr: 1,
                  fontSize: "16px",
                }}
              >
                {CheckTitle({ key: "Number", value: number, trimLength: 4 })}
                {CheckTitle({ key: "Unit", value: unit, trimLength: 2 })}
              </Typography>
              {/* Tooltip Icon */}
              {AllergenConflictPardonMedicines.includes(Bin.Trade_Name) && (
                <Tooltip title="This medicine is in conflict with an allergen and was acknowledged by the doctor">
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoIcon fontSize="small" sx={{ color: "#FF8080" }} />
                  </IconButton>
                </Tooltip>
              )}

              {/* Same Class Icon */}
              {SameClassPardonMedicines.includes(Bin.Trade_Name) && (
                <Tooltip title="This medicine belongs to a medicine class already added, and was acknowledged by the doctor">
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoIcon fontSize="small" sx={{ color: "#FF8080" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Box
              width={"100%"}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                component="span"
                sx={{ color: "gray", fontSize: "16px", textAlign: "left" }}
              >
                {CheckTitle({ key: "Salt", value: salt })}
              </Typography>
            </Box>
            <WarningText
              condition={highDosage}
              message="Dosage Exceeds Limit"
              color="red"
              iconSrc={MsgIndcatorIcon}
            />
            <WarningText
              condition={SameClass}
              message="Drug class already added"
              color="orange"
              iconSrc={MsgIndcatorIcon}
            />
            <WarningText
              condition={AllergenConflict}
              message="Listed as Allergy"
              color="orange"
              iconSrc={MsgIndcatorIcon}
            />
          </Stack>
          <Stack direction="column">
            <Typography
              component="span"
              sx={{
                color: "gray",
                mr: 1,
                margin: 0,
                textAlign: "right",
                display: "block",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {CheckTitle({ key: "Route", value: route })}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: "gray",
                mr: 1,
                margin: 0,
                textAlign: "right",
                display: "block",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {CheckTitle({ key: "Frequency", value: freq })}
            </Typography>
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default ListView;
