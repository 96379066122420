import React, { useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Typography, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SystemStyleObject } from "@mui/system";

interface DropdownFieldProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: string[];
  required?: boolean;
  width?: string | number;
  sx?: SystemStyleObject;
  HighDosageAlert?: boolean;
  disabled?: boolean; // Optional prop to disable the dropdown
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  label,
  value,
  onChange,
  options,
  required = false,
  width = "100%",
  sx = {},
  HighDosageAlert = false,
  disabled = false, // Default is false
}) => {
  const [active, setActive] = useState(false);

  return (
    <FormControl
      sx={{ width: width || "100%", ...sx }}
      disabled={disabled} // Disable the entire FormControl if disabled is true
    >
      {/* Typography is used for the label */}
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color={active ? "#008AD8" : "text.secondary"}
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>

      <Select
        size="small"
        value={value}
        onChange={onChange}
        onFocus={() => setActive(true)} // Set active to true when focused
        onBlur={() => setActive(false)} // Set active to false when focus is lost
        displayEmpty
        renderValue={(selected) => (!selected ? <em>--</em> : selected)}
        fullWidth
        disabled={disabled} // Disable the dropdown if disabled is true
        sx={{
          height: "clamp(35px, 4vh, 40px)",
          border: `1px solid ${
            value === ""
              ? "orange"
              : active
              ? "#008AD8"
              : HighDosageAlert
              ? "red"
              : "#CCD4DB"
          }`,
          backgroundColor: "white",
          fontSize: "16px",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiSelect-select": {
            textAlign: "left",
          },
        }}
      >
        <MenuItem value="" disabled>
          <em>--</em>
        </MenuItem>
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownField;
