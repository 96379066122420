export const AlertStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
  width: "100%",
};

export const successAlertStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1,
  width: "60%",
  opacity: 1,
};

export const boxStyle = {
  backgroundColor: "#F8F9FA",
  padding: "9px",
  borderRadius: "8px",
  border: "1px solid #D3D3D3",
  position: "relative",
};

export const editableContainerStyle = {
  backgroundColor: "#EEF4F7BD",
  padding: "9px",
  borderRadius: "8px",
  border: "1px solid #D3D3D3",
  marginTop: "16px",
  marginBottom: "0.5vh",
};

export const commonStyles = {
  stack: (opacity: number) => ({
    cursor: "pointer",
    width: "100%",
    opacity,
  }),
  stack2: (opacity: number) => ({
    cursor: "pointer",
    opacity,
  }),
  title: (incomplete: boolean) => ({
    color: incomplete ? "orange" : "gray",
    textAlign: "left",
    display: "block",
    fontWeight: "400",
    whiteSpace: "pre-wrap",
    fontSize: "18px",
  }),
  value: (incomplete: boolean) => ({
    color: incomplete ? "orange" : "#AEBECD",
    margin: 0,
    textAlign: "left",
    display: "block",
    fontSize: "16px",
  }),
  subvalue: () => ({
    color: "#AEBECD",
    margin: 0,
    textAlign: "left",
    display: "block",
    fontSize: "16px",
  }),
  EditBox: () => ({
    backgroundColor: "#F8F9FA",
    padding: "14px",
    borderRadius: "8px",
    border: "1px solid darkgray",
    position: "relative",
  }),
  EditTitle: (incomplete: boolean) => ({
    margin: 0,
    fontWeight: "normal",
    fontSize: "18px",
    color: incomplete ? "orange" : "inherit",
  }),
};
