import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface BinProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  buttons?: { name: string; onClick: () => void }[]; // Optional buttons list
}

const Bin: React.FC<BinProps> = ({ title, icon, children, buttons }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        borderRadius: "25px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <CardHeader
        avatar={icon}
        action={
          buttons &&
          buttons.length > 0 && ( // Conditionally render the ellipses icon
            <>
              <IconButton
                aria-label="more"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MoreHorizIcon style={{ color: "white", fontSize: 20 }} />
              </IconButton>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {buttons.map((button, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      button.onClick();
                      handleMenuClose();
                    }}
                  >
                    {button.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )
        }
        title={title}
        titleTypographyProps={{
          variant: "h6",
          sx: { fontWeight: "bold", color: "white" },
        }}
        sx={{
          backgroundColor: "#AAD8F2",
          padding: "8px 16px",
        }}
      />
      <CardContent sx={{ padding: 1 }}>{children}</CardContent>
    </Card>
  );
};

export default Bin;
