import React from "react";
import { Stack, Typography } from "@mui/material";
import { CheckTitle } from "../../../../utils/medsUtils";
import { CheckFamilyHxIncomplete } from "../../../../utils/BinsCompleteness";
import { FamilyHxAttributes } from "./FamilyHx";
import { MedConditionAttributes } from "./MedConditionView";
import MedConditionView from "./MedConditionView";

interface ListViewProps {
  FamilyHistory: FamilyHxAttributes;
}

const ListView: React.FC<ListViewProps> = ({ FamilyHistory }): JSX.Element => {
  const incomplete = CheckFamilyHxIncomplete(FamilyHistory);
  return (
    <>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h4"
            component="div"
            sx={{
              color: incomplete ? "orange" : "Black",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            {CheckTitle({
              key: "Relation",
              value: FamilyHistory.Relation,
              complete: !incomplete,
            })}
          </Typography>
          <Typography
            component="span"
            sx={{
              color: "#AEBECD",
              mr: 1,
              margin: 0,
              textAlign: "right",
              display: "block",
              fontSize: "16px",
            }}
          >
            {FamilyHistory.Age_at_death !== "" &&
            FamilyHistory.Status === "Deceased" ? (
              <>
                Died at{" "}
                {CheckTitle({
                  key: "Age_at_death",
                  value: FamilyHistory.Age_at_death,
                })}{" "}
                years of age
              </>
            ) : (
              ""
            )}
          </Typography>
        </Stack>
        {FamilyHistory.Medical_condition.map(
          (item: MedConditionAttributes, idx: number) => (
            <div style={{ width: "100%" }}>
              <MedConditionView key={idx} MedCondition={item} />
            </div>
          )
        )}
        <Stack direction="row" justifyContent="left">
          <Typography
            component="span"
            sx={{
              color: "#AEBECD",
              margin: 0,
              padding: 0,
              display: "block",
              fontSize: "16px",
            }}
          >
            {CheckTitle({
              key: "Additional_notes",
              value: FamilyHistory.Additional_notes,
            })}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default ListView;
