import { ExerciseAttributes } from "../components/v2/Bins/SocialHx/SocialHx";

export class SocialHxFormatters {
  static capitalizeFirstWord(value: string | undefined): string {
    if (!value?.trim()) {
      return "";
    }
    return value.trim().replace(/^\w/, (char) => char.toUpperCase());
  }

  static TrimString(value: string | undefined): string {
    if (!value?.trim()) {
      return "";
    }
    return value.length > 30 ? `${value.slice(0, 30)}...` : value;
  }

  static formatSleepTitle(
    Sleep_Same_Time: string | undefined,
    Sleep_Duration: string | undefined,
    Sleep_Quality: string | undefined,
    Insomnia_Symptoms: string | undefined
  ) {
    if (!Sleep_Same_Time?.trim()) {
      return { SleepTitle: "", incomplete: true };
    }
    const incomplete =
      !Sleep_Quality?.trim() ||
      !Sleep_Duration?.trim() ||
      !Insomnia_Symptoms?.trim();
    let SleepTitle =
      Sleep_Same_Time.trim().toLowerCase() === "yes" ? "Regular" : "Irregular";
    SleepTitle = this.capitalizeFirstWord(SleepTitle);
    return { SleepTitle, incomplete };
  }

  static formatStressTitle(
    Stress_Level: string | undefined,
    Stress_Management: string[] | undefined,
    Stress_Symptoms: string[] | undefined
  ) {
    if (!Stress_Level?.trim()) {
      return { StressTitle: "", incomplete: true };
    }
    const incomplete = [Stress_Management, Stress_Symptoms].some(
      (arr) => !arr?.length
    );
    const { formattedValue } = this.formatField(Stress_Level, "Stress");
    const StressTitle = this.capitalizeFirstWord(formattedValue);
    return { StressTitle, incomplete };
  }

  static formatDiet(
    Set_Meal_Schedule: string | undefined,
    Diet_Pattern: string[] | undefined,
    cups_fruit: string | undefined,
    cups_veggies: string | undefined,
    helpings_packaged: string | undefined,
    specific_consumptions: string[] | undefined,
    sugar_spoons: string | undefined,
    Artificial_Sweetener: string | undefined
  ) {
    let DietTitle = "";
    let incomplete = false;
    if (!Set_Meal_Schedule?.trim()) {
      DietTitle = "";
    }
    incomplete =
      !Set_Meal_Schedule?.trim() ||
      !Diet_Pattern?.length ||
      !cups_fruit?.trim() ||
      !cups_veggies?.trim() ||
      !helpings_packaged?.trim() ||
      !specific_consumptions?.length ||
      !sugar_spoons?.trim() ||
      !Artificial_Sweetener?.trim();
    DietTitle =
      Set_Meal_Schedule?.trim().toLowerCase() === "yes"
        ? "Follows a set meal schedule"
        : Set_Meal_Schedule?.trim().toLowerCase() === "no"
        ? "Doesn't follow a set meal schedule"
        : "Dietary Habits";
    DietTitle = this.capitalizeFirstWord(DietTitle);
    const DietSubtitle = this.capitalizeFirstWord(
      this.formatDietSubtitle(cups_fruit, cups_veggies)
    );
    return { DietTitle, DietSubtitle, incomplete };
  }

  static formatDietSubtitle(
    cups_fruit: string | undefined,
    cups_veggies: string | undefined
  ) {
    // Regex to remove "cups" from the values
    const cleanValue = (value: string | undefined): string | undefined =>
      value?.replace(/\s*cups\s*/gi, "").trim();

    // Clean the input values
    const fruit = cleanValue(cups_fruit);
    const veggies = cleanValue(cups_veggies);

    // Generate the subtitle based on cleaned values
    if (!fruit && !veggies) {
      return "";
    } else if (fruit && !veggies) {
      return `${fruit} cups of fruits`;
    } else if (veggies && !fruit) {
      return `${veggies} cups of veggies`;
    } else if (fruit && veggies) {
      return `${fruit} cups of fruits and ${veggies} cups of veggies`;
    }
    return "";
  }

  static formatStressManagement(
    Stress_Management: string[] | undefined
  ): string {
    const StressSubtitle = Stress_Management?.join(" ");
    return StressSubtitle || "";
  }

  static formatField(
    fieldValue: string | undefined,
    fieldName: string,
    Occupation_Type?: string
  ): { formattedValue: string; incomplete: boolean } {
    if (!fieldValue || fieldValue.trim() === "") {
      return { formattedValue: "", incomplete: true };
    }
    const cleanedValue = fieldValue
      .replace(new RegExp(`^${fieldName}:?\\s*`, "i"), "")
      .trim();
    const formattedValue = this.capitalizeFirstWord(cleanedValue);
    if (Occupation_Type === "") {
      return { formattedValue, incomplete: true };
    }
    return { formattedValue, incomplete: false };
  }

  static extractNumber(str: string | undefined): number {
    if (!str) {
      return 0;
    }
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  }

  static getExerciseInfo(Exercise: ExerciseAttributes[] | undefined) {
    if (!Exercise || Exercise.length === 0) {
      return {
        ExerciseTitle: "",
        ExerciseSubtitle: "",
        incomplete: true,
      };
    }

    let maxDuration = 0;
    let maxExerciseName = "";
    let maxExerciseFrequency = "";
    let maxExerciseDuration = "";
    let incomplete = false;

    Exercise.forEach((exercise) => {
      const frequency = this.extractNumber(exercise.Exercise_Frequency);
      const duration = this.extractNumber(exercise.Exercise_Duration);
      if (
        exercise &&
        exercise.Exercise_Name !== "No Exercise" &&
        (!exercise.Exercise_Name ||
          !exercise.Exercise_Frequency ||
          !exercise.Exercise_Duration)
      ) {
        incomplete = true;
      }
      const totalDuration = frequency * duration;
      if (totalDuration > maxDuration) {
        maxDuration = totalDuration;
        maxExerciseName = exercise.Exercise_Name || "";
        maxExerciseFrequency = exercise.Exercise_Frequency || "";
        maxExerciseDuration = exercise.Exercise_Duration || "";
      } else if (
        totalDuration === 0 &&
        exercise.Exercise_Name === "No Exercise"
      ) {
        maxExerciseName = "No Exercise";
        maxExerciseFrequency = "";
        maxExerciseDuration = "";
      }
    });

    //adding regex to
    const processExerciseFrequency = (frequency: string) => {
      return frequency.replace(/(\d+)\s*day(?:s)?/i, "$1").trim();
    };

    const processExerciseDuration = (duration: string) => {
      return duration.replace(/minute(?:s)?$/i, "").trim();
    };

    const ExerciseTitle = this.capitalizeFirstWord(maxExerciseName || "");

    const processedDuration = processExerciseDuration(maxExerciseDuration);
    const processedFrequency = processExerciseFrequency(maxExerciseFrequency);

    const ExerciseSubtitle = this.capitalizeFirstWord(
      maxDuration > 0 && (processedDuration || processedFrequency)
        ? `${processedDuration ? `${processedDuration} minutes` : ""}` +
            `${processedDuration && processedFrequency ? ", " : ""}` +
            `${processedFrequency ? `${processedFrequency} days` : ""}`
        : ""
    );

    return {
      ExerciseTitle,
      ExerciseSubtitle,
      incomplete,
    };
  }
}
