import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SocialHxAttributes } from "../components/v2/Bins/SocialHx/SocialHx";

const initialState: SocialHxAttributes & {
  socialhxOtherOptions: Record<string, boolean>;
} = {
  Marital_Status: "",
  Identity: "",
  Set_Meal_Schedule: "",
  Diet_Pattern: [],
  cups_fruit: "",
  cups_veggies: "",
  helpings_packaged: "",
  specific_consumptions: [""],
  sugar_spoons: "",
  Artificial_Sweetener: "",
  Routine: "",
  Stress_Level: "",
  Occupation: "",
  Occupation_Type: "",
  Education: "",
  Exercise: [],
  Sleep_Quality: "",
  Sleep_Duration: "",
  Sleep_Same_Time: "",
  Insomnia_Symptoms: "",
  Stress_Symptoms: [""],
  Stress_Management: [""],
  ReviewedBy: "",
  ReviewedAt: "",
  Other: "",
  Diet_Pattern_Other: "",
  Stress_Symptoms_Other: "",
  Stress_Management_Other: "",
  socialhxOtherOptions: {
    Marital_Status: false,
    Identity: false,
    Routine: false,
    Occupation_Type: false,
    Exercise_Name: false,
    Sleep_Quality: false,
    Stress_Symptoms: false,
    Stress_Management: false,
    Diet_Pattern: false,
  },
};

const socialHxSlice = createSlice({
  name: "socialHx",
  initialState,
  reducers: {
    setField(
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<{ field: keyof SocialHxAttributes; value: any }>
    ) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setSocialHxOption(
      state,
      action: PayloadAction<{ option: string; value: boolean }>
    ) {
      const { option, value } = action.payload;
      if (option in state.socialhxOtherOptions) {
        state.socialhxOtherOptions[option] = value;
      } else {
        console.warn(
          `Option "${option}" does not exist in socialhxOtherOptions`
        );
      }
    },
  },
});

export const { setField, setSocialHxOption } = socialHxSlice.actions;
export default socialHxSlice.reducer;
