import React from "react";
import { useSelector } from "react-redux";
import {
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { RootState } from "../../../../state/reducers";

function PatientStatusWidget({
  status,
  setStatus,
}: {
  status: string;
  setStatus: (value: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };

  const isBinsActive = useSelector(
    (state: RootState) => state.binsActive.BinsActivate
  );

  return (
    <Stack direction="column" sx={{ marginBottom: "1vh", width: "100%" }}>
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: "#AEBECD",
          fontWeight: "500",
          fontSize: "18px",
        }}
      >
        Patient Status
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: "#F8F9FA",
          border: "1px solid #CCD4DB",
          padding: "4px 8px",
          borderRadius: 2,
          alignItems: "center",
          gap: 1,
          flexShrink: 0,
        }}
      >
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={status}
            onChange={handleChange}
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <FormControlLabel
              value="NoSignificant"
              control={<Radio disabled={!isBinsActive} />}
              label="Not Significant"
              sx={{
                marginRight: 1,
                color: "#929FB1",
                fontSize: "16px",
                whiteSpace: "normal",
                "& .MuiFormControlLabel-label": {
                  fontSize: "16px",
                },
              }}
            />
            <FormControlLabel
              value="Unknown"
              control={<Radio disabled={!isBinsActive} />}
              label="Unknown"
              sx={{
                color: "#929FB1",
                fontSize: "16px",
                whiteSpace: "normal",
                "& .MuiFormControlLabel-label": {
                  fontSize: "16px",
                },
              }}
            />
            <FormControlLabel
              value="Adopted"
              control={<Radio disabled={!isBinsActive} />}
              label="Adopted"
              sx={{
                marginRight: 1,
                color: "#929FB1",
                fontSize: "16px",
                whiteSpace: "normal",
                "& .MuiFormControlLabel-label": {
                  fontSize: "16px",
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
}

export default PatientStatusWidget;
