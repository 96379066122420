/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import useCurrentClinicId from "../../../../hooks/useCurrentClinic";
import useActiveVisit from "../../../../hooks/useActiveVisit";
import {
  BenchmarkInput,
  useBenchmarkSavingMutation,
} from "../../../../graphql/generated";
import { RootState } from "../../../../state/reducers";

const useAllergies = (token = "") => {
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const clinicId = useCurrentClinicId();
  const { visitId } = useActiveVisit(clinicId);
  const currentDate = new Date().toISOString().split("T")[0];
  const [visitToken, setVisitToken] = useState(token);

  const [createBenchmarkMutation] = useBenchmarkSavingMutation();

  const benchmark = useCallback(
    (allergies: any) => {
      const alternate = visitToken.concat(currentPhysician, currentDate);
      const benchmarkInput: BenchmarkInput = {
        benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? alternate),
        socialHx: "",
        familyHx: "",
        meds: "",
        habits: "",
        discMeds: "",
        MedWarn: "",
        pastMedicalHx: "",
        discPastMedicalHx: "",
        vaccine: "",
        obgyn: "",
        allergies: JSON.stringify({ allergies }),
      };
      console.log(benchmarkInput);
      createBenchmarkMutation({ benchmark: benchmarkInput })
        .then((response) => {
          console.log("[ALLERGIES] Mutation successful:", response);
        })
        .catch((error) => {
          console.error("[ALLERGIES] Mutation failed:", error);
        });
    },
    [
      createBenchmarkMutation,
      currentDate,
      currentPhysician,
      visitId,
      visitToken,
    ]
  );

  return { benchmark, setVisitToken };
};

export default useAllergies;
