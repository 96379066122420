import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "ramda";
import {
  Stack,
  Typography,
  IconButton,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SelectChangeEvent } from "@mui/material";
import { getFieldOptions } from "../../../../utils/BinUtils";
import { RootState } from "../../../../state/reducers";
import {
  ErrorComponent,
  WarningComponent,
  MedsAllergyWarning,
  DeleteConfirmation,
  DiscontinueReason,
} from "../../../DialgueBoxes";
import SearchField from "../ui-components/SearchField";
import {
  getClassConflict,
  getAllergenConflict,
  extractNumber,
} from "../../../../utils/medsUtils";
import {
  addAllergenConflictPardon,
  addSameClassPardon,
} from "../../../../state/BinsUtilArraysSlice";
import InputField from "../ui-components/InputField";
import DropdownField from "../ui-components/DropdownFIeld";
import DateField from "../ui-components/DateFieldOG";
import EditModeButton from "../ui-components/BinButtons";
import EditModeHeading from "../ui-components/EditModeHeadings";
import { AlertStyle } from "../../../../utils/constants";
import useMedicine from "./useMedicine";
import { Medicine } from "./Medicines";

const AddOrEditMedicine = ({
  Bin,
  handleClose,
  onSave,
  onDelete,
  onDiscontinue,
  inEditing,
  MedsTradeNames,
}: {
  Bin: Medicine | null | undefined;
  handleClose: () => void;
  onSave: (newMedicine: Medicine, KeyTradeName: string) => void;
  onDelete: (KeyTradeName: string) => void;
  onDiscontinue: (discMedicine: Medicine, KeyTradeName: string) => void;
  inEditing: boolean;
  MedsTradeNames: string[];
}) => {
  const dispatch = useDispatch();

  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const allergenNames = useSelector(
    (state: RootState) => state.allergiesBin.allergenNames
  );
  const AllergenConflictPardonMedicines = useSelector(
    (state: RootState) => state.binsUtilArrays.AllergenConflictPardons
  );
  const SameClassPardonMedicines = useSelector(
    (state: RootState) => state.binsUtilArrays.SameClassPardons
  );
  const { TradeNameGlossary } = useSelector(
    (state: RootState) => state.MedicineBinGlossary
  );

  const TradeNamesList = TradeNameGlossary
    ? Object.keys(TradeNameGlossary)
    : [];

  const { addMedWarn } = useMedicine();

  const discontinuedByValue = currentPhysician ?? "[Couldn't Fetch Physician]";

  const KeyTradeName = inEditing === false ? "" : Bin?.Trade_Name || "";
  const [numberValue, setNumberValue] = useState<string>(
    inEditing === false ? "" : Bin?.Number || ""
  );
  const [tradeName, setTradeNameInput] = useState<string>(
    inEditing === false ? "" : Bin?.Trade_Name || ""
  );
  const [salt, setSaltInput] = useState<string>(
    inEditing === false ? "" : Bin?.Salt || ""
  );
  const [unitValue, setUnitValue] = useState<string>(
    inEditing === false ? "" : Bin?.Unit || ""
  );
  const [frequencyValue, setFrequencyValue] = useState<string>(
    inEditing === false ? "" : Bin?.Frequency || ""
  );
  const [routeValue, setRouteValue] = useState<string>(
    inEditing === false ? "" : Bin?.Route || ""
  );
  const [prescribedBy, setPrescribedByInput] = useState<string>(
    Bin?.Prescribed_by && Bin.Prescribed_by !== ""
      ? Bin.Prescribed_by
      : currentPhysician ?? "[Couldn't Fetch Physician]"
  );
  const [startDateValue, setStartDateValue] = useState<string>(
    Bin?.Start_date || ""
  );
  const [endDateValue, setEndDateValue] = useState<string>(Bin?.End_date || "");
  const deleteValue = Bin?.Deleted || "";
  const ReviewedAt = Bin?.Reviewed_at || "";
  const ReviewedBy = Bin?.Reviewed_by || "";
  const [Note, setNote] = useState<string>(Bin?.Note || "");
  const [delName, setDelName] = useState(Bin?.Trade_Name || "");

  const [isOngoing, setIsOngoing] = useState(endDateValue === "Ongoing");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDiscotinueConfirmation, setShowDiscontinueConfirmation] =
    useState(false);
  const [showHighDosageError, setShowHighDosageError] = useState(false);
  const [showSameClassWarning, setShowSameClassWarning] = useState(false);
  const [showAllergenConflictWarning, setAllergenConflictWarning] =
    useState(false);

  const [dosageValue, setDosageValue] = useState(0);
  const [delReason, setDelReason] = useState("");
  const [HighDosageAlert, setHighDosageAlert] = useState(
    inEditing
      ? extractNumber(Bin?.Number || "") * extractNumber(Bin?.Frequency || "") >
        4000
        ? true
        : false
      : false
  );
  const [ClassConflictStatus, setClassConflictStatus] = useState(
    getClassConflict(Bin?.Trade_Name || "", MedsTradeNames, TradeNameGlossary)
  );
  const [AllergenConflictStatus, setAllergenConflictStatus] = useState(
    getAllergenConflict(Bin?.Trade_Name || "", allergenNames, TradeNameGlossary)
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tradeOptions, setTradeOptions] = useState<any>(
    TradeNameGlossary ? TradeNameGlossary[Bin?.Trade_Name || ""] : []
  );

  useEffect(() => {
    if (tradeName !== KeyTradeName && tradeOptions) {
      setNumberValue(tradeOptions.Number?.split(",")[0] || "");
      setUnitValue(tradeOptions.Unit?.split(",")[0] || "");
      setFrequencyValue(tradeOptions.Frequency?.split(",")[0] || "");
      setRouteValue(tradeOptions.Route?.split(",")[0] || "");
      setNote(tradeOptions.Notes?.split(",")[0] || "");
    } else {
      setNumberValue(Bin?.Number || "");
      setUnitValue(Bin?.Unit || "");
      setFrequencyValue(Bin?.Frequency || "");
      setRouteValue(Bin?.Route || "");
      setNote(Bin?.Note || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeOptions]);

  const handleTradeNameChange = (value: string) => {
    setTradeNameInput(value);
    setTradeOptions(TradeNameGlossary[value]);
    setSaltInput(
      TradeNameGlossary[value]
        ? TradeNameGlossary[value].Salt?.split(",")[0]
        : ""
    );
    setClassConflictStatus(
      getClassConflict(value, MedsTradeNames, TradeNameGlossary)
    );
    setAllergenConflictStatus(
      getAllergenConflict(value, allergenNames, TradeNameGlossary)
    );
  };

  const handleSaltChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaltInput(event.target.value);
  };

  const handleSaltOptions = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSaltInput(value);
  };

  const handleNumberChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setNumberValue(value);
    if (
      salt.toLowerCase().includes("paracetamol") ||
      salt.toLowerCase().includes("aspirin") ||
      salt.toLowerCase().includes("ibuprofen")
    ) {
      const dosage = extractNumber(value) * extractNumber(frequencyValue);
      // console.log("Number changed, number interpretted as: ", extractNumber(value), " Frequency: ", extractNumber(frequencyValue), "dosage: ", dosage);
      setDosageValue(dosage);
      setHighDosageAlert(dosage > 4000);
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFrequencyValue(value);
    if (
      salt.toLowerCase().includes("paracetamol") ||
      salt.toLowerCase().includes("aspirin") ||
      salt.toLowerCase().includes("ibuprofen")
    ) {
      const dosage = extractNumber(value) * extractNumber(numberValue);
      // console.log("Frequency changed, freqiency interpretted as: ", extractNumber(value), " number: ", extractNumber(frequencyValue), "dosage: ", dosage);
      setDosageValue(dosage);
      setHighDosageAlert(dosage > 4000);
    }
  };

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUnitValue(value);
  };

  const handleRouteChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setRouteValue(value);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateValue(value);
  };

  const handlePrescribedByChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrescribedByInput(event.target.value);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOngoing(event.target.checked);
    if (event.target.checked) {
      setEndDateValue("Ongoing"); // Clear the end date if "Ongoing" is checked
    }
  };

  // console.log("[MEDS Update/Delete Testing], in editable, BinToEditIndex is: ", binToEditIndex);
  // console.log("[MEDS Update/Delete Testing], in editable, KeyTradeName is: ", KeyTradeName);
  // console.log("[MEDS NOTES TESTING] optionsArray: ", JSON.stringify(OptionsArray), "index: ", binToEditIndex);

  const handleWriteMedLog = (MedLog: string) => {
    addMedWarn(MedLog);
  };

  const handleSave = () => {
    if (HighDosageAlert) {
      setShowHighDosageError(true);
      return;
    }
    if (
      ClassConflictStatus[0] === "true" &&
      !SameClassPardonMedicines.includes(tradeName)
    ) {
      setShowSameClassWarning(true);
      return;
    }
    if (
      AllergenConflictStatus[0] === "true" &&
      !AllergenConflictPardonMedicines.includes(tradeName)
    ) {
      setAllergenConflictWarning(true);
      return;
    }
    const newState = {
      Trade_Name: tradeName || "",
      Salt: salt || "",
      Number: numberValue || "",
      Unit: unitValue || "",
      Route: routeValue || "",
      Frequency: frequencyValue || "",
      Start_date: startDateValue || "",
      End_date: endDateValue || "",
      Prescribed_by: prescribedBy || "",
      Deleted: deleteValue || "",
      Delete_Reason: "",
      Discontinued_by: "",
      Reviewed_by: ReviewedBy || "",
      Reviewed_at: ReviewedAt || "",
      Note: Note || "",
    };
    onSave(newState, KeyTradeName);
    handleCancel();
    handleClose();
  };

  const handleDeleteMedicine = (TradeName: string) => {
    onDelete(KeyTradeName);
  };

  const handleDiscontinueMedicine = (TradeName: string) => {
    TradeName = TradeName.toString();
    const newState = {
      Trade_Name: TradeName || "",
      Salt: salt || "",
      Number: numberValue || "",
      Unit: unitValue || "",
      Route: routeValue || "",
      Frequency: frequencyValue || "",
      Start_date: startDateValue || "",
      End_date: endDateValue || "",
      Prescribed_by: prescribedBy || "",
      Deleted: "true",
      Delete_Reason: delReason || "",
      Discontinued_by: discontinuedByValue || "",
      Reviewed_by: ReviewedBy || "",
      Reviewed_at: ReviewedAt || "",
      Note: Note || "",
    };
    onDiscontinue(newState, KeyTradeName);
  };

  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    setShowDiscontinueConfirmation(false);
    setDelName("");
  };

  const handleDeleteConfirm = () => {
    // console.log("Delete confirmed, now remove it from list");
    handleDeleteMedicine(delName);
    handleCancel();
    handleClose();
  };

  const handleDiscontinueConfirm = () => {
    handleDiscontinueMedicine(delName);
    handleCancel();
    handleClose();
  };

  const handleSameClassWarningCancel = () => {
    setShowSameClassWarning(false);
    dispatch(addSameClassPardon(tradeName));
    const timestamp = new Date().toLocaleString();
    handleWriteMedLog(
      `Same Class Conflict Warning was shown for ${tradeName} Medicine at time: ${timestamp} and Doctor Pressed Save`
    );
  };

  const handleAllergenConflictWarningCancel = () => {
    setAllergenConflictWarning(false);
    dispatch(addAllergenConflictPardon(tradeName));
    const timestamp = new Date().toLocaleString();
    handleWriteMedLog(
      `Allergen Conflict Warning was shown for ${tradeName} Medicine at time: ${timestamp} and Doctor Pressed Save`
    );
  };

  console.log("TradeNameGlossary is: ", TradeNameGlossary);
  console.log("Pardoned medicines is: ", SameClassPardonMedicines);
  console.log("Class conflict status is: ", ClassConflictStatus);

  // console.log("[IN MEDS editable] Edit index is: ", binToEditIndex);

  return (
    <>
      <div>
        {inEditing ? (
          <EditModeHeading type={"Edit"} title="Medicine" />
        ) : (
          <EditModeHeading type={"Add"} title="Medicine" />
        )}
      </div>

      <Box
        sx={{
          backgroundColor: "#F8F9FA",
          padding: "9px",
          borderRadius: "8px",
          border: "1px solid #D3D3D3",
          position: "relative",
          paddingTop: "5px",
        }}
      >
        <IconButton
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "red",
            padding: "0",
            "& .MuiSvgIcon-root": { fontSize: "16px" },
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ marginTop: "12px" }}>
          <SearchField
            label="Trade Name"
            options={TradeNamesList}
            value={tradeName}
            onChange={handleTradeNameChange} // Handle value directly
            required={true}
            defaultOther={false}
          />
        </div>

        <div style={{ marginTop: "8px" }}>
          {tradeOptions ? (
            tradeOptions.Salt.split(",").length === 1 &&
            tradeOptions.Salt !== "" ? (
              <InputField
                label="Salt"
                value={salt}
                onChange={handleSaltChange}
                readOnly={true}
                required
              />
            ) : tradeOptions.Salt !== "" ? (
              <DropdownField
                label="Salt"
                value={salt}
                onChange={handleSaltOptions}
                options={tradeOptions.Salt.split(",")}
                required
              />
            ) : (
              <InputField
                label="Salt"
                value={salt}
                onChange={handleSaltChange}
                required
              />
            )
          ) : (
            <InputField
              label="Salt"
              value={salt}
              onChange={handleSaltChange}
              required
            />
          )}
        </div>

        {/* Number and Unit in one row */}
        {/* [MED QA2] if empty string, then pass a emptyindicator bool and make label red */}
        <div style={{ marginTop: "8px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {tradeOptions ? (
              <DropdownField
                label="Strength"
                value={numberValue}
                onChange={handleNumberChange}
                options={tradeOptions.Number.split(",")}
                required
                width="48%"
                HighDosageAlert={HighDosageAlert}
              />
            ) : (
              <>
                <InputField
                  label="Strength"
                  value={numberValue}
                  onChange={handleNumberChange}
                  required
                  width="48%"
                />
              </>
            )}
            {tradeOptions ? (
              <DropdownField
                label="Unit"
                value={unitValue}
                onChange={handleUnitChange}
                options={tradeOptions.Unit.split(",")}
                required
                width="48%"
              />
            ) : (
              <>
                <InputField
                  label="Unit"
                  value={unitValue}
                  onChange={handleUnitChange}
                  required
                  width="48%"
                />
              </>
            )}
          </Box>
        </div>

        {/* Route and Frequency in one row */}
        <div style={{ marginTop: "8px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DropdownField
              label="Route"
              value={routeValue}
              onChange={handleRouteChange}
              options={
                tradeOptions
                  ? tradeOptions.Route.split(",")
                  : getFieldOptions("meds", "Route")
              }
              required
              width="48%"
            />
            <DropdownField
              label="Frequency"
              value={frequencyValue}
              onChange={handleFrequencyChange}
              options={
                tradeOptions
                  ? tradeOptions.Frequency.split(",")
                  : getFieldOptions("meds", "Frequency")
              }
              required
              width="48%"
              HighDosageAlert={HighDosageAlert}
            />
          </Box>
        </div>

        <div style={{ marginTop: "8px" }}>
          <DateField
            label="Start Date (MM/DD/YY)"
            value={startDateValue}
            onChange={handleStartDateChange}
            required={false}
            width="100%"
          />
        </div>

        <Box
          mt={1}
          width={"100%"}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isOngoing}
                onChange={handleCheckboxChange}
                id="ongoing-checkbox"
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Medicine is currently Ongoing
              </Typography>
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                textAlign: "left",
              },
            }}
          />
        </Box>

        {!isOngoing && (
          <div style={{ marginTop: "8px" }}>
            <DateField
              label="End Date (MM/DD/YY)"
              value={endDateValue}
              onChange={handleEndDateChange}
              required
              width="100%"
            />
          </div>
        )}

        <div style={{ marginTop: "8px" }}>
          <InputField
            label="Prescribed By"
            value={prescribedBy}
            onChange={handlePrescribedByChange}
            required
          />
        </div>

        <div style={{ marginTop: "8px" }}>
          <InputField
            label="Note"
            value={Note}
            onChange={handleNoteChange}
            required={false}
          />
        </div>

        <Stack
          direction="row"
          alignContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          justifyContent={"center"}
          justifyItems={"center"}
          justifySelf={"center"}
          spacing={2}
          sx={{ marginTop: "2vh", marginBottom: "2vh" }}
        >
          {inEditing && (
            <>
              <EditModeButton
                type={"delete"}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Delete
              </EditModeButton>

              <EditModeButton
                type={"discontinue"}
                onClick={() => setShowDiscontinueConfirmation(true)}
              >
                Discontinue
              </EditModeButton>
            </>
          )}

          <EditModeButton
            type={"save"}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </EditModeButton>
        </Stack>

        {showDeleteConfirmation && (
          <div style={AlertStyle}>
            <DeleteConfirmation
              handleDeleteClose={handleCancel}
              handleDeleteConfirm={handleDeleteConfirm}
              name={delName}
            />
          </div>
        )}

        {showDiscotinueConfirmation && (
          <div style={AlertStyle}>
            <DiscontinueReason
              handleDiscontinueClose={handleCancel}
              handleDiscontinueConfirm={handleDiscontinueConfirm}
              name={delName}
              setDelReason={setDelReason}
            />
          </div>
        )}

        {showHighDosageError && (
          <div style={AlertStyle}>
            <ErrorComponent
              handleCancel={() => {
                setShowHighDosageError(false);
              }}
              name={delName}
              dosageValue={dosageValue}
            />
          </div>
        )}

        {showSameClassWarning && (
          <div style={AlertStyle}>
            <WarningComponent
              handleCancel={() => {
                setShowSameClassWarning(false);
              }}
              handleSave={() => {
                compose(handleSave, handleSameClassWarningCancel)();
              }}
              medName={delName}
              conflictName={ClassConflictStatus[2]}
              conflictClass={ClassConflictStatus[1]}
            />
          </div>
        )}

        {showAllergenConflictWarning && (
          <div style={AlertStyle}>
            <MedsAllergyWarning
              handleCancel={() => {
                setAllergenConflictWarning(false);
              }}
              handleSave={() => {
                compose(handleSave, handleAllergenConflictWarningCancel)();
              }}
              medName={delName}
              conflictName={AllergenConflictStatus[1]}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default AddOrEditMedicine;
