import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useCurrentClinicId from "../../../../hooks/useCurrentClinic";
import useActiveVisit from "../../../../hooks/useActiveVisit";
import {
  BenchmarkInput,
  useBenchmarkSavingMutation,
} from "../../../../graphql/generated";
import { RootState } from "../../../../state/reducers";
import { setField } from "../../../../state/SocialHxSlice";
import { SocialHxAttributes } from "./SocialHx";

const useBenchmarkSocialHx = (token = "") => {
  const clinicId = useCurrentClinicId();
  const { visitId } = useActiveVisit(clinicId);
  const currentDate = new Date().toISOString().split("T")[0];
  const [visitToken, setVisitToken] = useState(token);
  const [createBenchmarkMutation] = useBenchmarkSavingMutation();
  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const alternate = visitToken.concat(
    currentPhysician ?? "[Couldn't Fetch Physician]",
    currentDate
  );

  const benchmark = useCallback(
    (SocialHx: SocialHxAttributes) => {
      const benchmarkInput: BenchmarkInput = {
        benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? alternate),
        socialHx: JSON.stringify({ SocialHx }),
        familyHx: "",
        meds: "",
        habits: "",
        discMeds: "",
        MedWarn: "",
        pastMedicalHx: "",
        discPastMedicalHx: "",
        vaccine: "",
        obgyn: "",
        allergies: "",
      };
      console.log(benchmarkInput);
      createBenchmarkMutation({ benchmark: benchmarkInput })
        .then((response) => {
          console.log("[SOCIAL HX] Mutation successful:", response);
        })
        .catch((error) => {
          console.error("[SOCIAL HX] Mutation failed:", error);
        });
    },
    [createBenchmarkMutation, visitId, alternate]
  );

  return { benchmark, setVisitToken };
};

export const useSetSocialHxFields = () => {
  // Initialize state variables for each field
  const dispatch = useDispatch();
  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  return (correctedBin: SocialHxAttributes) => {
    if (!correctedBin) {
      return;
    }
    dispatch(
      setField({ field: "Marital_Status", value: correctedBin.Marital_Status })
    );
    dispatch(setField({ field: "Identity", value: correctedBin.Identity }));
    dispatch(
      setField({
        field: "Set_Meal_Schedule",
        value: correctedBin.Set_Meal_Schedule,
      })
    );
    dispatch(
      setField({ field: "Diet_Pattern", value: correctedBin.Diet_Pattern })
    );
    dispatch(setField({ field: "cups_fruit", value: correctedBin.cups_fruit }));
    dispatch(
      setField({ field: "cups_veggies", value: correctedBin.cups_veggies })
    );
    dispatch(
      setField({
        field: "helpings_packaged",
        value: correctedBin.helpings_packaged,
      })
    );
    dispatch(
      setField({
        field: "specific_consumptions",
        value: correctedBin.specific_consumptions,
      })
    );
    dispatch(
      setField({ field: "sugar_spoons", value: correctedBin.sugar_spoons })
    );
    dispatch(
      setField({
        field: "Artificial_Sweetener",
        value: correctedBin.Artificial_Sweetener,
      })
    );
    dispatch(setField({ field: "Routine", value: correctedBin.Routine }));
    dispatch(
      setField({ field: "Stress_Level", value: correctedBin.Stress_Level })
    );
    dispatch(setField({ field: "Occupation", value: correctedBin.Occupation }));
    dispatch(
      setField({
        field: "Occupation_Type",
        value: correctedBin.Occupation_Type,
      })
    );
    dispatch(setField({ field: "Education", value: correctedBin.Education }));
    dispatch(setField({ field: "Exercise", value: correctedBin.Exercise }));
    dispatch(
      setField({ field: "Sleep_Quality", value: correctedBin.Sleep_Quality })
    );
    dispatch(
      setField({ field: "Sleep_Duration", value: correctedBin.Sleep_Duration })
    );
    dispatch(
      setField({
        field: "Sleep_Same_Time",
        value: correctedBin.Sleep_Same_Time,
      })
    );
    dispatch(
      setField({
        field: "Insomnia_Symptoms",
        value: correctedBin.Insomnia_Symptoms,
      })
    );
    dispatch(
      setField({
        field: "Stress_Symptoms",
        value: correctedBin.Stress_Symptoms,
      })
    );
    dispatch(
      setField({
        field: "Stress_Management",
        value: correctedBin.Stress_Management,
      })
    );
    dispatch(setField({ field: "Other", value: correctedBin.Other }));
    dispatch(
      setField({
        field: "ReviewedBy",
        value: correctedBin.ReviewedBy || currentPhysician || "",
      })
    );
    dispatch(
      setField({
        field: "ReviewedAt",
        value: correctedBin.ReviewedAt || new Date().toISOString(),
      })
    );
  };
};

export const useSetReviewedBy = () => {
  const dispatch = useDispatch();
  return (reviewedBy: string) => {
    dispatch(setField({ field: "ReviewedBy", value: reviewedBy }));
  };
};

export const useSetReviewedAt = () => {
  const dispatch = useDispatch();
  return (reviewedAt: string = new Date().toISOString()) => {
    dispatch(setField({ field: "ReviewedAt", value: reviewedAt }));
  };
};

export default useBenchmarkSocialHx;
