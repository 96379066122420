import React, { useState, useEffect, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { SocialHxFormatters } from "../../../../utils/socialHxFormatters";
import DropdownField from "../ui-components/DropdownFIeld";
import EditModeButton from "../ui-components/BinButtons";
import { getFieldOptions } from "../../../../utils/BinUtils";
import {
  editableContainerStyle,
  commonStyles,
} from "../../../../constants/StyleConstants";
import { ExerciseAttributes } from "./SocialHx";

interface ExerciseItemProps {
  index: number;
  exercise: ExerciseAttributes;
  handleExerciseChange: (
    index: number,
    field: keyof ExerciseAttributes,
    val: string
  ) => void;
  handleDeleteRequest: (index: number, name: string) => void;
}

export const ExerciseItem: React.FC<ExerciseItemProps> = ({
  index,
  exercise,
  handleExerciseChange,
  handleDeleteRequest,
}) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(
    exercise.Exercise_Name === "" ? true : false
  );
  const ExerciseOptions = getFieldOptions("socialHx", "Exercise_Name");
  const ExerciseFrequencyOptions = getFieldOptions(
    "socialHx",
    "Exercise_Frequency"
  );
  const ExerciseDurationOptions = getFieldOptions(
    "socialHx",
    "Exercise_Duration"
  );

  const isNoExercise = useMemo(
    () => exercise.Exercise_Name === "No Exercise",
    [exercise.Exercise_Name]
  );

  useEffect(() => {
    if (exercise.Exercise_Name === "No Exercise") {
      handleExerciseChange(index, "Exercise_Frequency", "");
      handleExerciseChange(index, "Exercise_Duration", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise.Exercise_Name]);

  return (
    <Box sx={{ marginBottom: "16px" }}>
      {!isEditOpen ? (
        <div
          onClick={() => {
            setIsEditOpen(true);
          }}
        >
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography component="span" sx={commonStyles.subvalue}>
              {SocialHxFormatters.capitalizeFirstWord(exercise.Exercise_Name)}
            </Typography>
            <Typography component="span" sx={commonStyles.subvalue}>
              {exercise.Exercise_Frequency}, {exercise.Exercise_Duration}
            </Typography>
          </Stack>
        </div>
      ) : (
        <Box sx={editableContainerStyle}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <DropdownField
              label="Exercise Name"
              value={exercise.Exercise_Name || ""}
              onChange={(e: SelectChangeEvent<string>) =>
                handleExerciseChange(index, "Exercise_Name", e.target.value)
              }
              options={ExerciseOptions}
              required
              width="100%"
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <DropdownField
                label="# Days in a week"
                value={exercise.Exercise_Frequency || ""}
                onChange={(e: SelectChangeEvent<string>) =>
                  handleExerciseChange(
                    index,
                    "Exercise_Frequency",
                    e.target.value
                  )
                }
                options={ExerciseFrequencyOptions}
                required
                width="50%"
                disabled={isNoExercise} // Disable if "No Exercise" is selected
              />
              <DropdownField
                label="# of minutes per day"
                value={exercise.Exercise_Duration || ""}
                options={ExerciseDurationOptions}
                onChange={(e) =>
                  handleExerciseChange(
                    index,
                    "Exercise_Duration",
                    e.target.value
                  )
                }
                required
                width="45%"
                disabled={isNoExercise} // Disable if "No Exercise" is selected
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              alignContent={"center"}
              sx={{ width: "100%" }}
            >
              <EditModeButton
                type="delete"
                onClick={() =>
                  handleDeleteRequest(index, exercise.Exercise_Name)
                }
              >
                Delete
              </EditModeButton>
              <EditModeButton
                type="save"
                onClick={() => {
                  setIsEditOpen(false);
                }}
              >
                Save
              </EditModeButton>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
