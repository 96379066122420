import { forwardRef, useCallback, useRef, useState } from "react";
//import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import useAudioProcessing from "../../hooks/useAudioProcessing";

import useMemoizedRemoveNils from "../../hooks/useMemoizedRemoveNils";
import useCurrentClinicId from "../../hooks/useCurrentClinic";
import useScrollWithOutput from "../../hooks/useScrollWithOutputHook";
import RecordingButtonRow from "../../components/RecordingButtonRow";
import {
  PatientQuery,
  Vitals,
  usePatientMedicationQuery,
  usePatientQuery,
  useVitalsQuery,
} from "../../graphql/generated";
import useActiveVisit from "../../hooks/useActiveVisit";
import SehatSpinner from "../../components/SehatSpinner";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import VitalsBar from "./VitalsBar";
import VisitInteractionPanel, { MedicationInfo } from "./VisitInteractionPanel";
import { DefaultVitals } from "./VitalsBar";

declare global {
  interface Window {
    socket: object;
  }
}

function VisitInProgressRoute() {
  const clinicId = useCurrentClinicId();

  const scrollRef = useRef<HTMLUListElement>(null);
  const [realTime, setRealTime] = useState(true);

  const navigate = useNavigate();

  useScrollWithOutput(scrollRef);

  const { visit, visitId, needToLoadVisit } = useActiveVisit(clinicId);

  const { data: patientData } = usePatientQuery({
    id: visit?.patientId || "",
    patientIdType: visit?.patientIdType || "",
  });

  const { data: vitalsData } = useVitalsQuery({
    eventType: "V",
    eventId: visit?.visitId || "",
    patientId: visit?.patientId || "",
    patientIdType: visit?.patientIdType || "",
  });
  const vitals = vitalsData?.vitals;

  const { data: medicationData } = usePatientMedicationQuery({
    id: visit?.patientId || "",
    patientIdType: visit?.patientIdType || "",
  });
  const medications = useMemoizedRemoveNils(
    medicationData?.patient?.medications
  );

  const onDone = useCallback(() => {
    navigate("/plan");
  }, [navigate]);

  const {
    recordingInProgress,
    transcription,
    questions,
    startProcessing,
    stopProcessing,
    cancelProcessing,
    refreshResults,
    audioSessionId,
    assessmentReady,
  } = useAudioProcessing(clinicId, visitId);

  const readyToStartRecording = audioSessionId.length > 0;

  if (needToLoadVisit) {
    return <SehatSpinner />;
  }
  // if (visit == null) {
  //   return <div>No current visit</div>;
  // }

  return (
    <VisitInProgressUiView
      vitals={vitals ? vitals : DefaultVitals}
      medications={medications}
      patient={patientData}
      onStart={startProcessing}
      onStop={stopProcessing}
      onCancel={cancelProcessing}
      onDone={onDone}
      onRefresh={refreshResults}
      transcription={transcription}
      questions={questions}
      recordingInProgress={recordingInProgress}
      readyToStart={readyToStartRecording}
      planReady={assessmentReady}
      realTime={realTime}
      setRealTime={setRealTime}
      ref={scrollRef}
    />
  );
}

interface VisitInProgressUiViewProps {
  patient: PatientQuery | undefined;
  vitals: Vitals | null | undefined;
  medications: MedicationInfo[];
  onStart: () => void;
  onStop: () => void;
  onCancel: () => void;
  onDone: () => void;
  onRefresh: () => void;
  recordingInProgress: boolean;
  planReady: boolean;
  readyToStart: boolean;
  transcription: TranscriptionSection[];
  questions: string[] | null;
  realTime: boolean;
  setRealTime: (realTime: boolean) => void;
}

function VisitInProgressUiViewInternal(
  {
    patient,
    vitals,
    medications,
    onStart,
    onStop,
    onCancel,
    onDone,
    onRefresh,
    recordingInProgress,
    transcription,
    questions,
    readyToStart,
    planReady,
    realTime,
    setRealTime,
  }: VisitInProgressUiViewProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  return (
    <Stack spacing="1em" ml="1em" height="100%">
      <VitalsBar vitals={vitals ? vitals : DefaultVitals} patient={patient} />
      <RecordingButtonRow
        onStart={onStart}
        onStop={onStop}
        recordingInProgress={recordingInProgress}
        readyToStart={true}
        waitingForModel={!readyToStart}
        onDone={onDone}
        enableDone={planReady}
      />
      <VisitInteractionPanel
        medications={medications}
        vitals={vitals ? vitals : DefaultVitals}
        transcription={transcription}
        questions={questions}
        ref={ref}
      />
    </Stack>
  );
}

const VisitInProgressUiView = forwardRef(VisitInProgressUiViewInternal);

export default VisitInProgressRoute;
