import { Stack, Typography, Box } from "@mui/material";
import { getEmptyBins } from "../../../../utils/BinUtils";

type SubCategory = { [key: string]: string | null };
type BinType = { [key: string]: SubCategory };

export function SocialHxInActive() {
  const Bin = getEmptyBins("socialHx") as BinType;
  return (
    <Stack
      spacing={0}
      direction="column"
      justifyContent={"flex-start"}
      alignContent={"left"}
    >
      {Object.entries(Bin).map(([mainCategory, subCategories]) => (
        <Box key={mainCategory} sx={{ textAlign: "left", marginLeft: "2px" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "black",
              mt: mainCategory === "Personal" ? 0 : 1,
              fontSize: "18px",
            }}
          >
            {mainCategory.replace(/_/g, " ")}
          </Typography>
          {Object.entries(subCategories).map(([subCategory, value]) => (
            <Box
              key={subCategory}
              sx={{ textAlign: "left", marginLeft: "4px" }}
            >
              <Typography
                component="span"
                sx={{ color: "gray", fontSize: "16px" }}
              >
                {subCategory.replace(/_/g, " ")}
              </Typography>
              <Typography
                component="span"
                sx={{ color: value ? "gray" : "gray", fontSize: "16px" }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Stack>
  );
}
