/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FIELD_CONFIGS,
  stringKeys,
  arrayKeys,
} from "../constants/UtilConstants";
import { getFieldOptions } from "./BinUtils";
import { SocialHxFormatters } from "./socialHxFormatters";

const DEFAULT_VALUES: Record<string, any> = {
  meds: {
    Deleted: "false",
    Start_date: () => new Date().toISOString().split("T")[0],
    End_date: "Ongoing",
    default: "",
  },
  familyHx: {
    Medical_condition: [],
    default: "",
  },
};

function correctItemFields(item: any, fields: string[], defaults: any): any {
  if (typeof item !== "object" || item === null || Array.isArray(item)) {
    return null;
  }

  const correctedItem = { ...item };

  // Add missing fields with default values
  for (const field of fields) {
    if (!(field in correctedItem)) {
      correctedItem[field] =
        typeof defaults[field] === "function"
          ? defaults[field]()
          : defaults[field] ?? defaults.default;
    }
  }

  // Correct existing fields
  for (const key in correctedItem) {
    if (key in defaults) {
      correctedItem[key] =
        typeof defaults[key] === "function"
          ? defaults[key]()
          : correctedItem[key];
    } else if (
      key !== "Medical_condition" &&
      typeof correctedItem[key] !== "string"
    ) {
      correctedItem[key] = defaults.default;
    } else if (
      key === "Medical_condition" &&
      !Array.isArray(correctedItem[key])
    ) {
      correctedItem[key] = [];
    }
  }

  return correctedItem;
}

export default function CleanBin(Bin: any, title: string): any {
  const fields = FIELD_CONFIGS[title];
  const defaults = DEFAULT_VALUES[title];

  if (!fields || !defaults) {
    return Bin;
  }

  const BinArray = Array.isArray(Bin) ? [...Bin] : [Bin];

  return BinArray.map((item) =>
    correctItemFields(item, fields, defaults)
  ).filter(Boolean);
}

export function cleanSocialHxBin(Bin: any) {
  console.log(`bin in clean is: ${JSON.stringify(Bin)}`);

  if (Array.isArray(Bin)) {
    //since the LLM returned array, placing this check as social hx expects a object.
    Bin = Bin[0];
  }
  // Ensure all stringKeys exist in Bin with a default value of ""
  for (const key of stringKeys) {
    if (!(key in Bin)) {
      Bin[key] = "";
    }
  }
  for (const key of arrayKeys) {
    if (!(key in Bin)) {
      Bin[key] = [];
    }
  }

  const keys = Object.keys(Bin);
  for (const key of keys) {
    const value = Bin[key as keyof typeof Bin];
    if (stringKeys.includes(key)) {
      if (typeof Bin[key] !== "string") {
        Bin[key] = "";
      } else {
        const fieldOptions = getFieldOptions("socialHx", key) as string[];
        const normalizedOptions = fieldOptions.map((option) =>
          option.toLowerCase().trim()
        );
        if (!normalizedOptions.includes(value.toLowerCase().trim())) {
          Bin[key] = "";
        }
      }
    }

    if (arrayKeys.includes(key)) {
      // Handle arrayKeys
      if (Array.isArray(value)) {
        // Leave as is if already an array
        continue;
      } else if (typeof value === "string") {
        // Convert comma-separated string to an array
        Bin[key] = value
          .split(",")
          .map((item) => SocialHxFormatters.capitalizeFirstWord(item.trim()))
          .filter((item) => item !== "");
      } else {
        // Reset to an empty array if the value is neither string nor array
        Bin[key] = [];
      }
    }

    if (key === "Exercise") {
      if (!Array.isArray(value)) {
        Bin[key] = [];
      }
    }
  }
  console.log(
    "[DEBUGGING SOCIALHX DATA] in cleansocialhxbin, returning Bin is: ",
    Bin
  );
  return Bin;
}
