/* eslint-disable @typescript-eslint/no-explicit-any */
import stringSimilarity from "string-similarity";
import * as Papa from "papaparse";
import { Medicine } from "../components/v2/Bins/Medicines/Medicines";
import {
  FrequencyConversions,
  ListOfNotCapitalizations,
  ListOfHeadings,
} from "../constants/UtilConstants";

export interface MedicineCSVReturn {
  Trade_Name: string;
  Class: string;
  Salt: string;
  Number: string;
  Unit: string;
  Route: string;
  Frequency: string;
  Notes: string;
}
export const GetMedicineGlossaryByTradeName = async () => {
  const response = await fetch("/FormattedSheet.csv");
  const csvText = await response.text();
  const TradeNameGlossary: Record<string, MedicineCSVReturn> = {};
  // Parse the CSV file
  Papa.parse(csvText, {
    header: true,
    skipEmptyLines: true,
    complete: (result: any) => {
      const data = result.data;

      data.forEach((row: any) => {
        const tradeNames =
          row["TRADE/BRAND NAME"]
            ?.trim()
            .split(",")
            .map((name: string) => name.trim()) || [];
        const saltName = row["SALT NAME"]?.trim() || "";

        const mappedRow: MedicineCSVReturn = {
          Trade_Name: "", // Placeholder for splitting entries
          Class: row.CLASS?.trim() || "",
          Salt: saltName,
          Number: row.DOSES?.trim() || "",
          Unit: row.UNITS?.trim() || "",
          Route: row.ROUTES?.trim() || "",
          Frequency: row["FREQUENCY FORMATTED"]?.trim() || "",
          Notes: row.NOTES?.trim() || "",
        };

        // Helper function to add a unique entry
        const addUniqueEntry = (key: string, entry: MedicineCSVReturn) => {
          if (TradeNameGlossary[key]) {
            const existingEntry = TradeNameGlossary[key];

            // Append new values ensuring no duplicates
            existingEntry.Class = appendNonEmptyUnique(
              existingEntry.Class,
              entry.Class
            );
            existingEntry.Salt = appendNonEmptyUnique(
              existingEntry.Salt,
              entry.Salt
            );
            existingEntry.Number = appendNonEmptyUnique(
              existingEntry.Number,
              entry.Number
            );
            existingEntry.Unit = appendNonEmptyUnique(
              existingEntry.Unit,
              entry.Unit
            );
            existingEntry.Route = appendNonEmptyUnique(
              existingEntry.Route,
              entry.Route
            );
            existingEntry.Frequency = appendNonEmptyUnique(
              existingEntry.Frequency,
              entry.Frequency
            );
            existingEntry.Notes = appendNonEmptyUnique(
              existingEntry.Notes,
              entry.Notes
            );
          } else {
            TradeNameGlossary[key] = { ...entry, Trade_Name: key };
          }
        };

        // Add entries for each trade name
        tradeNames.forEach((tradeName: string) => {
          if (tradeName) {
            addUniqueEntry(tradeName, mappedRow);
          }
        });

        // Add an entry for the salt name
        if (saltName) {
          addUniqueEntry(saltName, mappedRow);
        }
      });
    },
  });

  return TradeNameGlossary;
};

interface TradeNameGlossaryItem {
  Trade_Name: string;
  Class: string;
  Salt: string;
  Number: string;
  Unit: string;
  Route: string;
  Frequency: string;
  Notes: string;
}

// Helper function to append non-empty and unique values to a field
const appendNonEmptyUnique = (
  existingValue: string,
  newValue: string
): string => {
  const existingArray = existingValue
    .split(",")
    .map((val) => val.trim())
    .filter(Boolean);
  const newArray = newValue
    .split(",")
    .map((val) => val.trim())
    .filter(Boolean);
  const combinedArray = [...existingArray, ...newArray].filter(
    (val, index, self) => self.indexOf(val) === index
  );
  return combinedArray.join(", ");
};

const getMostSimilarTradeName = (
  tradeName: string,
  TradeNamesList: string[]
): string | null => {
  if (!TradeNamesList.length) {
    return null;
  }
  const bestMatch = stringSimilarity.findBestMatch(tradeName, TradeNamesList);
  return bestMatch.bestMatch.target;
};

export function PopulateMedsBin(
  MedsArray: Medicine[],
  TradeNameGlossary: Record<string, TradeNameGlossaryItem>
): Medicine[] {
  if (!TradeNameGlossary) {
    return MedsArray;
  }

  const TradeNamesList = Object.keys(TradeNameGlossary);

  // Process MedsArray
  const updatedMedsArray = MedsArray.map((med: Medicine) => {
    // Find the most similar trade name
    if (med.Trade_Name === "" && med.Salt !== "") {
      med.Trade_Name = med.Salt;
    }
    const mostSimilarTradeName = getMostSimilarTradeName(
      med.Trade_Name,
      TradeNamesList
    );
    if (!mostSimilarTradeName || !TradeNameGlossary[mostSimilarTradeName]) {
      return med; // If no match, return the object as is
    }

    const glossaryEntry = TradeNameGlossary[mostSimilarTradeName];

    // Check if the med value exists in the glossary entry's corresponding values array
    const shouldRetainValue = (
      currentValue: string,
      glossaryValue: string
    ): boolean => {
      if (!glossaryValue) {
        return false;
      }

      const normalize = (str: string): string =>
        str.toLowerCase().replace(/[.,]/g, "").trim();

      const glossaryValuesArray = glossaryValue
        .split(",")
        .map((val: string) => normalize(val));

      const normalizedCurrentValue = normalize(currentValue);

      // Generalized mapping using regex
      const mappedValue = normalizedCurrentValue.replace(
        /every (\d+) hours as needed/,
        "every $1 hours prn"
      );

      return glossaryValuesArray.includes(mappedValue);
    };

    // Check if the glossary value has only one option
    const shouldAssignSingleValue = (glossaryValue: string): string | null => {
      const glossaryValuesArray = glossaryValue
        .split(",")
        .map((val: string) => val.trim());
      return glossaryValuesArray.length === 1 ? glossaryValuesArray[0] : null;
    };

    // Handle Frequency mapping with regex transformation
    const mappedFrequency = med.Frequency
      ? med.Frequency.replace(
          /every (\d+) hours as needed/i,
          "Every $1 hours PRN"
        )
      : med.Frequency;

    // Update med fields from the glossary if the value should not be retained
    return {
      ...med,
      Number: shouldRetainValue(med.Number, glossaryEntry.Number)
        ? med.Number
        : shouldAssignSingleValue(glossaryEntry.Number) || "",
      Unit: shouldRetainValue(med.Unit, glossaryEntry.Unit)
        ? med.Unit
        : shouldAssignSingleValue(glossaryEntry.Unit) || "",
      Route: shouldRetainValue(med.Route, glossaryEntry.Route)
        ? med.Route
        : shouldAssignSingleValue(glossaryEntry.Route) || "",
      Frequency: shouldRetainValue(med.Frequency, glossaryEntry.Frequency)
        ? mappedFrequency
        : shouldAssignSingleValue(glossaryEntry.Frequency) || "",
      Salt: shouldRetainValue(med.Salt, glossaryEntry.Salt)
        ? med.Salt
        : shouldAssignSingleValue(glossaryEntry.Salt) || "",
      Trade_Name: shouldRetainValue(med.Trade_Name, glossaryEntry.Trade_Name)
        ? med.Trade_Name
        : shouldAssignSingleValue(glossaryEntry.Trade_Name) || "",
    };
  });

  return updatedMedsArray;
}

export const getClassConflict = (
  tradeName: string,
  MedsTradeNames: string[],
  TradeNameGlossary: Record<string, TradeNameGlossaryItem>
): string[] => {
  if (!TradeNameGlossary[tradeName]) {
    return ["false", "", ""];
  }
  const classes = TradeNameGlossary[tradeName]?.Class?.split(",").map(
    (cls: string) => cls.trim()
  );
  if (!classes) {
    return ["false", "", ""];
  }
  for (const medicine of MedsTradeNames) {
    if (medicine === tradeName) {
      continue;
    }
    const medicineClasses = TradeNameGlossary[medicine]?.Class?.split(",").map(
      (cls: string) => cls.trim()
    );
    for (const cls of classes) {
      if (medicineClasses && medicineClasses.includes(cls)) {
        return ["true", cls, medicine];
      }
    }
  }
  return ["false", "", ""];
};

export const getAllergenConflict = (
  tradeName: string,
  allergenNames: string[],
  TradeNameGlossary: Record<string, TradeNameGlossaryItem>
): string[] => {
  if (!TradeNameGlossary[tradeName]) {
    return ["false", ""];
  }
  const classes = TradeNameGlossary[tradeName]?.Class?.split(",").map(
    (cls: string) => cls.trim()
  );
  if (!classes) {
    return ["false", "", ""];
  }
  for (const allergen of allergenNames) {
    if (classes.includes(allergen) || tradeName === allergen) {
      return ["true", allergen];
    }
  }
  return ["false", ""];
};

export function extractNumber(str: string): number {
  const lowerStr = str.toLowerCase().trim();
  if (lowerStr in FrequencyConversions) {
    return FrequencyConversions[lowerStr];
  }
  const match = str.match(/\d+/);
  return match ? parseInt(match[0]) : 0;
}

function capitalizeFirstWord(str: string): string {
  if (typeof str !== "string") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function CheckTitle({
  key,
  value,
  complete = true,
  trimLength = 20,
}: {
  key: string;
  value: string;
  complete?: boolean;
  trimLength?: number;
}): string {
  const keyname = key.replace(/_/g, " ");
  let value2 = "";
  if (!ListOfNotCapitalizations.includes(keyname)) {
    value2 = capitalizeFirstWord(value);
  } else {
    value2 = value;
  }
  if (complete === false && ListOfHeadings.includes(keyname)) {
    if (value2 !== undefined && value2 !== "" && value2 !== null) {
      return value2;
    } else {
      return keyname;
    }
  } else if (ListOfHeadings.includes(keyname)) {
    return value2;
  } else if (value2 !== undefined && value2 !== "" && value2 !== null) {
    return value2.length > trimLength
      ? `${value2.slice(0, trimLength)}...`
      : value2;
  } else {
    return "";
  }
}
