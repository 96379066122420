import { useCallback, useMemo } from "react";
import { type Theme, useTheme, SxProps, Button, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// this is a wrapper around the material card component that
// applies specific app appearance.

// 'secondary' cards use the primary color. this is intentional.
function chooseSecondaryColor(
  theme: Theme,
  fade: "none" | "faded" | "veryFaded" | undefined
) {
  switch (fade) {
    case "faded":
      return theme.palette.primaryFaded.main;
    case "veryFaded":
      return theme.palette.primaryVeryFaded.main;
    default:
      return theme.palette.primary.main;
  }
}
export interface AmchurCardProps {
  title: string | React.ReactNode;
  role: "primary" | "secondary";
  titleAlign?: "center" | "left";
  inTextAlign?: "center" | "left";
  fade?: "none" | "faded" | "veryFaded";
  sx?: SxProps<Theme>;
  allowEdit?: boolean;
  onEditStateChanged?: (b: boolean) => void;
  inEditMode?: boolean;
  allowComparison?: boolean;
  inCompMode?: boolean;
  onCompModeChanged?: (b: boolean) => void;
  children: React.ReactNode;
  isActive?: boolean;
  titleColor?: string;
  titleVarient?: string;
}
function AmchurCard({
  title,
  role,
  titleAlign,
  inTextAlign,
  fade,
  sx,
  allowEdit = false,
  inEditMode = false,
  onEditStateChanged = () => {},
  allowComparison = false,
  inCompMode = false,
  onCompModeChanged = () => {},
  children,
  isActive = true,
  titleColor = "white",
  titleVarient = "h4",
}: AmchurCardProps) {
  const theme = useTheme();
  const backgroundColor1 =
    role === "primary"
      ? theme.palette.secondary.main
      : chooseSecondaryColor(theme, fade);

  //change bakground color again on basis of isActive, if false, make it gray
  const backgroundColor2 = isActive
    ? backgroundColor1
    : theme.palette.grey[700];
  //if role is primary, then keep it as is
  const backgroundColor =
    role === "primary" ? backgroundColor1 : backgroundColor2;

  const toggleEdit = useCallback(() => {
    onEditStateChanged(!inEditMode);
  }, [inEditMode, onEditStateChanged]);

  const toggleMode = useCallback(() => {
    onCompModeChanged(!inCompMode);
  }, [inCompMode, onCompModeChanged]);

  const action = useMemo(() => {
    if (inCompMode) {
      return (
        <Button onClick={toggleMode}>
          <ArrowBackIcon style={{ color: "white" }} />
        </Button>
      );
    }
    if (allowEdit) {
      return allowComparison ? (
        <Box gap={2}>
          <Button variant="text" onClick={toggleMode}>
            <CompareArrowsIcon style={{ color: "white" }} />
          </Button>
          <Button variant="text" onClick={toggleEdit}>
            {inEditMode ? (
              <CheckIcon style={{ color: "white" }} />
            ) : (
              <EditIcon style={{ color: "white" }} />
            )}
          </Button>
        </Box>
      ) : (
        <Button variant="text" onClick={toggleEdit}>
          {inEditMode ? (
            <CheckIcon style={{ color: "white" }} />
          ) : (
            <EditIcon style={{ color: "white" }} />
          )}
        </Button>
      );
    }
    return undefined;
  }, [
    allowEdit,
    allowComparison,
    inCompMode,
    toggleMode,
    inEditMode,
    toggleEdit,
  ]);

  const fullSx = {
    width: "100%",
    borderRadius: "25px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    ...sx,
  };
  return (
    <Card sx={fullSx}>
      <CardHeader
        titleTypographyProps={{ variant: "h3" }}
        title={title}
        action={action}
        sx={{
          backgroundColor: backgroundColor,
          paddingY: "6px",
          minHeight: "3.2em",
          color: titleColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          "& .MuiCardHeader-action": {
            position: "absolute",
            right: 16,
            top: "50%",
            transform: "translateY(-50%)",
          },
        }}
      />
      <CardContent
        sx={{
          overflowY: "scroll",
          textAlign: inTextAlign || "center",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}

export default AmchurCard;
