import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetMedicineGlossaryByTradeName } from "../utils/medsUtils";
import { MedicineCSVReturn } from "../utils/medsUtils";

export const fetchTradeNameGlossary = createAsyncThunk(
  "glossaries/fetchTradeNameGlossary",
  async () => {
    const response = await GetMedicineGlossaryByTradeName();
    return response;
  }
);

const initialState = {
  TradeNameGlossary: {} as Record<string, MedicineCSVReturn>,
  loading: false,
  error: null as string | null,
};

const MedicineglossarySlice = createSlice({
  name: "glossaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeNameGlossary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTradeNameGlossary.fulfilled, (state, action) => {
        state.TradeNameGlossary = action.payload;
        state.loading = false;
      })
      .addCase(fetchTradeNameGlossary.rejected, (state, action) => {
        state.error =
          action.error.message || "Failed to fetch TradeNameGlossary";
        state.loading = false;
      });
  },
});

export default MedicineglossarySlice.reducer;
