import React from "react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography, Button } from "@mui/material";
import CleanBin from "../../../../utils/BinsCleanupFunction";
import Alert from "../ui-components/Alert";
import Bin from "../HelperComponents/BinCardV2";
import { getFieldOptions } from "../../../../utils/BinUtils";
import PatientStatusWidget from "../ui-components/PatientStatusWidget";
import InputField from "../ui-components/InputField";
import { RootState } from "../../../../state/reducers";
import { ListModeButton } from "../ui-components/BinButtons";
import MarkAsReviewedBox from "../../../MarkAsReviewedBox";
import ListView from "./ListView";
import useFamilyHx from "./useFamilyHx";
import { MedConditionAttributes } from "./MedConditionView";
import { ReactComponent as FamilyHxBinIcon } from "./../../../../assets/MedicineBinIcon.svg";
import AddOrEditFamilyHx from "./AddOrEditFamilyHx";

export interface FamilyHxAttributes {
  Relation: string;
  Status: string;
  Age_at_death: string;
  Medical_condition: MedConditionAttributes[];
  Additional_notes: string;
  Reviewed_by: string;
  Reviewed_at: string;
}

interface FamilyHxBinProps {
  data?: FamilyHxAttributes[];
  visitToken?: string;
}

const FamilyHx: React.FC<FamilyHxBinProps> = ({ data, visitToken = "" }) => {
  const PriorityOrder = getFieldOptions("familyHx", "Relation");
  const PriorityOrderAdopted = getFieldOptions("familyHx", "Relation_adopted");
  const [familyHxBin, setFamilyHxBin] = useState<FamilyHxAttributes[] | null>(
    null
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const [ItemsDisplay, setItemsDisplay] = useState<FamilyHxAttributes[] | null>(
    null
  );
  const [AddNew, setAddNew] = useState<boolean>(false);
  const [inEditing, setInEditing] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [SuccessMsg, setSuccessMsg] = useState<string>("");
  const [binToEdit, setBinToEdit] = useState<FamilyHxAttributes>();
  const [status, setStatus] = useState<string>("");
  const [unknownReason, setunknownReason] = useState<string>("");
  const [ReviewedBy, setReviewedBy] = useState<string>(
    familyHxBin && familyHxBin.length > 0
      ? familyHxBin[0]?.Reviewed_by || ""
      : ""
  );
  const [ReviewedAt, setReviewedAt] = useState<string>(
    familyHxBin && familyHxBin.length > 0
      ? familyHxBin[0]?.Reviewed_at || ""
      : ""
  );

  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );

  const isBinsActive = useSelector(
    (state: RootState) => state.binsActive.BinsActivate
  );

  useEffect(() => {
    const correctedArray = CleanBin(data, "familyHx");
    //this is placed temporarily here to sorted list is always consistent. will fix this properly after demo.
    correctedArray.sort((a: FamilyHxAttributes, b: FamilyHxAttributes) => {
      const aPriority =
        PriorityOrderAdopted.indexOf(a.Relation) !== -1
          ? PriorityOrderAdopted.indexOf(a.Relation)
          : PriorityOrder.indexOf(a.Relation);
      const bPriority =
        PriorityOrderAdopted.indexOf(b.Relation) !== -1
          ? PriorityOrderAdopted.indexOf(b.Relation)
          : PriorityOrder.indexOf(b.Relation);
      return (
        (aPriority === -1 ? Infinity : aPriority) -
        (bPriority === -1 ? Infinity : bPriority)
      );
    });
    if (correctedArray !== undefined) {
      setFamilyHxBin(correctedArray);
      setItemsDisplay(correctedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); //disabled because else it was creating issue and over-writing edits on every re-render.

  const { benchmark } = useFamilyHx();

  const toggleExpanded = () => setExpanded(!expanded);

  const toggleAddNewOn = () => {
    setAddNew(true);
  };

  const toggleEditModeOn = () => {
    setInEditing(true);
  };

  const handleUnknownReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setunknownReason(value);
  };

  const handleRevertStatus = () => {
    setStatus("");
    const newRelation = {
      Relation: "",
      Status: "",
      Age_at_death: "",
      Additional_notes: "",
      Medical_condition: [{ Name: "", Age: "" }],
      Reviewed_by: "",
      Reviewed_at: "",
    };
    benchmark([newRelation]);
  };

  useEffect(() => {
    familyHxBin?.sort((a: FamilyHxAttributes, b: FamilyHxAttributes) => {
      const aPriority =
        PriorityOrderAdopted.indexOf(a.Relation) !== -1
          ? PriorityOrderAdopted.indexOf(a.Relation)
          : PriorityOrder.indexOf(a.Relation);
      const bPriority =
        PriorityOrderAdopted.indexOf(b.Relation) !== -1
          ? PriorityOrderAdopted.indexOf(b.Relation)
          : PriorityOrder.indexOf(b.Relation);
      return (
        (aPriority === -1 ? Infinity : aPriority) -
        (bPriority === -1 ? Infinity : bPriority)
      );
    });
  }, [PriorityOrder, PriorityOrderAdopted, familyHxBin]);

  const initialItemsDisplay = useMemo(() => {
    if (familyHxBin === null) {
      return [];
    }
    return familyHxBin.length > 0 &&
      familyHxBin[0].Medical_condition.length >= 4
      ? [familyHxBin[0]]
      : familyHxBin.slice(0, 2);
  }, [familyHxBin]);

  useEffect(() => {
    setItemsDisplay(expanded ? familyHxBin : initialItemsDisplay);
  }, [expanded, familyHxBin, initialItemsDisplay]);

  const onMarkAsReviewed = (reviewed: boolean) => {
    if (reviewed && familyHxBin) {
      const today = new Date();
      const datePart = today.toLocaleDateString();
      const timePart = today.toLocaleTimeString();
      const reviewtime = datePart + " at " + timePart;

      setReviewedBy(currentPhysician ?? "[Couldn't Fetch Physician]");
      setReviewedAt(reviewtime);

      const updatedFamilyHx = familyHxBin?.map((familyHx) => ({
        ...familyHx,
        Reviewed_at: reviewtime,
        Reviewed_by: currentPhysician ?? "[Couldn't Fetch Physician]",
      }));

      setFamilyHxBin(updatedFamilyHx);
      benchmark(updatedFamilyHx);
    } else if (!reviewed && familyHxBin) {
      const updatedFamilyHx = familyHxBin?.map((familyHx) => ({
        ...familyHx,
        Reviewed_at: "",
        Reviewed_by: "",
      }));

      setReviewedBy("");
      setReviewedAt("");

      setFamilyHxBin(updatedFamilyHx);
      benchmark(updatedFamilyHx);
    }
  };

  const handleUnknownHistory = useCallback(() => {
    const newRelation = {
      Relation: "Unknown Family History",
      Status: "",
      Age_at_death: "",
      Additional_notes: unknownReason || "",
      Medical_condition: [],
      Reviewed_by: currentPhysician ?? "[couldnt fetch physician]",
      Reviewed_at: "",
    };
    benchmark([newRelation]);
    setSuccessMsg("Unknown Family History Reason saved Successfully");
    setShowSuccessMessage(true);
  }, [
    unknownReason,
    currentPhysician,
    benchmark,
    setSuccessMsg,
    setShowSuccessMessage,
  ]);

  const handleNotSignificantHistory = useCallback(() => {
    const newRelation = {
      Relation: "No Significant Family History",
      Status: "",
      Age_at_death: "",
      Additional_notes: "",
      Medical_condition: [],
      Reviewed_by: currentPhysician ?? "[couldnt fetch physician]",
      Reviewed_at: "",
    };
    benchmark([newRelation]);
    setSuccessMsg("Marked Not Significant Family Hx Successfully");
    setShowSuccessMessage(true);
  }, [currentPhysician, benchmark, setSuccessMsg, setShowSuccessMessage]);

  const onSave = (newRelation: FamilyHxAttributes, KeyRelation: string) => {
    const newState = [...(familyHxBin ?? [])];
    const index = newState.findIndex((obj) => obj.Relation === KeyRelation);
    if (index !== -1) {
      newState[index] = newRelation;
      setFamilyHxBin(newState);
      benchmark(newState);
      setSuccessMsg("FamilyHx Updated Successfuly");
    } else {
      const newerState = [...(familyHxBin ?? []), newRelation];
      setFamilyHxBin(newerState);
      benchmark(newerState);
      setSuccessMsg("FamilyHx Added Successfuly");
    }
    setShowSuccessMessage(true);
  };

  const onDelete = (KeyRelation: string) => {
    const newState = [...(familyHxBin ?? [])];
    const index = newState.findIndex((obj) => obj.Relation === KeyRelation);
    if (index !== -1) {
      newState.splice(index, 1);
      setFamilyHxBin(newState);
      benchmark(newState);
      setSuccessMsg("Family History Deleted Successfuly");
    }
    setShowSuccessMessage(true);
  };

  useEffect(() => {
    if (status === "NoSignificant") {
      handleNotSignificantHistory();
    }
  }, [handleNotSignificantHistory, status]);

  if (!familyHxBin || !ItemsDisplay) {
    return <></>;
  }

  return (
    <Bin title="Family History" icon={<FamilyHxBinIcon />}>
      {AddNew ? (
        <AddOrEditFamilyHx
          Bin={null}
          onClose={() => setAddNew(false)}
          onSave={onSave}
          onDelete={onDelete}
          inEditing={false}
          status={status}
        />
      ) : inEditing ? (
        <>
          <AddOrEditFamilyHx
            Bin={binToEdit}
            onClose={() => setInEditing(false)}
            onSave={onSave}
            onDelete={onDelete}
            inEditing={true}
            status={status}
          />
        </>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <PatientStatusWidget status={status} setStatus={setStatus} />

          {status === "NoSignificant" ? (
            <Typography
              component="span"
              sx={{ color: "gray", marginBottom: "1vh" }}
            >
              Patient has No Significant Family History
            </Typography>
          ) : status === "Unknown" ? (
            <Stack
              direction="column"
              justifyContent="center"
              sx={{
                backgroundColor: "#F8F9FA",
                border: "1px solid #CCD4DB",
                padding: 1,
                borderRadius: 2,
                alignItems: "center",
                flexWrap: "nowrap",
                width: "100%",
                marginBottom: "1vh",
                opacity: showSuccessMessage ? 0.5 : 1,
              }}
            >
              <div style={{ marginTop: "8px", minWidth: "100%" }}>
                <InputField
                  label="Reason for Unknown family History"
                  value={unknownReason}
                  onChange={handleUnknownReasonChange}
                  required
                  width="100%"
                />
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  handleUnknownHistory();
                }} //add a unknown reason save handler
                sx={{
                  backgroundColor: "#0CAF5D",
                  borderRadius: "8px",
                  width: "6vw",
                  height: "3vh",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                }}
              >
                Save
              </Button>
            </Stack>
          ) : familyHxBin.length === 0 ? (
            <Typography
              component="span"
              sx={{ color: "gray", marginBottom: "1vh" }}
            >
              No Family History Found
            </Typography>
          ) : (
            <>
              {Object.entries(ItemsDisplay).map(([key, items], idx) => (
                <div
                  style={{
                    marginBottom: "1vh",
                    marginTop: "2vh",
                    width: "100%",
                    cursor: "pointer",
                    opacity: showSuccessMessage ? 0.5 : 1,
                  }}
                  onClick={() => {
                    setBinToEdit(items);
                    toggleEditModeOn();
                  }}
                >
                  <ListView key={idx} FamilyHistory={items} />
                </div>
              ))}
            </>
          )}

          {status !== "NoSignificant" && status !== "Unknown" ? (
            <div
              style={{
                display: "flex",
                justifyContent:
                  familyHxBin.length > 2 ? "space-between" : "right",
                width: "100%",
                marginTop: "2vh",
              }}
            >
              {familyHxBin.length > 2 ? (
                <ListModeButton type={"Expand"} onClick={toggleExpanded}>
                  {expanded ? "Collapse All" : "Expand All"}
                </ListModeButton>
              ) : (
                <></>
              )}
              {isBinsActive && (
                <ListModeButton type={"Add"} onClick={toggleAddNewOn}>
                  + Add Relation
                </ListModeButton>
              )}
            </div>
          ) : (
            isBinsActive && (
              <ListModeButton type={"Add"} onClick={handleRevertStatus}>
                + Add Family Hx
              </ListModeButton>
            )
          )}
          {isBinsActive && (
            <MarkAsReviewedBox
              onMarkAsReviewed={onMarkAsReviewed}
              ReviewedBy={ReviewedBy}
              ReviewedAt={ReviewedAt}
            />
          )}
          {showSuccessMessage && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                width: "60%",
                opacity: 1,
              }}
            >
              <Alert
                msg={SuccessMsg}
                setShowAlert={setShowSuccessMessage}
                type={"success"}
              />
            </div>
          )}
        </Stack>
      )}
    </Bin>
  );
};

export default FamilyHx;
