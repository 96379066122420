import React, { useState } from "react";
import { Typography, Box, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SxProps, Theme } from "@mui/material";

interface InputFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  width?: string;
  num?: boolean;
  readOnly?: boolean;
  sx?: SxProps<Theme>;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  required = false,
  width = "100%",
  num = false,
  readOnly = false,
  sx = {},
}) => {
  const [active, setActive] = useState(false);

  return (
    <FormControl sx={{ width: width, ...sx }}>
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color={active ? "#008AD8" : "text.secondary"}
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Box>
        <TextField
          type={num ? "number" : "text"}
          size="small"
          value={value}
          onChange={onChange}
          onFocus={() => setActive(true)} // Set active to true when focused
          onBlur={() => setActive(false)} // Set active to false when focus is lost
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: readOnly,
            sx: {
              height: "clamp(35px, 4vh, 40px)",
              border: `1px solid ${
                required && value === ""
                  ? "orange"
                  : active
                  ? "#008AD8"
                  : "#CCD4DB"
              }`, // Conditional border color
              backgroundColor: "white",
              fontSize: "16px",
              padding: "8px 8px", // Reduce padding to ensure left alignment
              paddingLeft: "0px", // Ensure text starts at the leftmost edge
              borderRadius: "8px",
              "&:focus": {
                backgroundColor: "transparent", // Prevents background color change on focus
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:focus .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "16px",
              color: active ? "#008AD8" : "#B4B8BB", // Conditional label color
            },
          }}
        />
      </Box>
    </FormControl>
  );
};

export default InputField;
