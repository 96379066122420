import { type Theme, SxProps } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

// this is a wrapper around the material card component that
// applies specific app appearance.

export interface BinInfoCardProps {
  title: string | React.ReactNode;
  role: "primary" | "secondary";
  titleAlign?: "center" | "left";
  inTextAlign?: "center" | "left";
  fade?: "none" | "faded" | "veryFaded";
  sx?: SxProps<Theme>;
  allowEdit?: boolean;
  onEditStateChanged?: (b: boolean) => void;
  inEditMode?: boolean;
  allowComparison?: boolean;
  inCompMode?: boolean;
  onCompModeChanged?: (b: boolean) => void;
  children: React.ReactNode;
  isActive?: boolean;
  titleColor?: string;
  titleVarient?: string;
}
function BinInfoCard({
  title,
  role,
  titleAlign,
  inTextAlign,
  fade,
  sx,
  allowEdit = false,
  inEditMode = false,
  onEditStateChanged = () => {},
  allowComparison = false,
  inCompMode = false,
  onCompModeChanged = () => {},
  children,
  isActive = true,
  titleColor = "black",
  titleVarient = "h4",
}: BinInfoCardProps) {
  const textAlign = titleAlign || (role === "primary" ? "center" : "left");
  const fullSx = { width: "100%", borderRadius: "25px", ...sx };
  return (
    <Card sx={fullSx}>
      <CardHeader
        titleTypographyProps={{ variant: "h3", textAlign: textAlign }}
        title={title}
        sx={{
          backgroundColor: "#AAD8F2",
          paddingY: "6px",
          minHeight: "3.2em",
          color: titleColor,
          fontSize: "20px",
        }}
      />
      <CardContent
        sx={{
          overflowY: "scroll",
          textAlign: inTextAlign || "center",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}

export default BinInfoCard;
