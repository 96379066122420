import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Typography } from "@mui/material";
import { textChangeHandler } from "../utils/eventUtils";
import { activateBins } from "../state/BinsActiveSlice";
import AmchurCard from "./AmchurCard";
import { SmallSehatSpinner } from "./SehatSpinner";

export function SymptomChip({ text }: { text: string }) {
  return (
    <Grid xs={"auto"}>
      <Item>
        <Chip label={text} color="primary" variant="outlined" />
      </Item>
    </Grid>
  );
}

export interface DiagnosisPanelProps {
  notes: string;
  revisedNotes: string;
  symptoms: string[];
  waiting: boolean;
  updateNotes: (notes: string) => void;
}

function DiagnosisPanel({
  notes,
  symptoms,
  waiting,
  revisedNotes,
  updateNotes,
}: DiagnosisPanelProps) {
  const intl = useIntl();
  const [inEditMode, setEditMode] = useState(false);
  const [inCompMode, setCompMode] = useState(false);
  const [editedNotes, setEditedNotes] = useState("");

  // intially setting revisedNotes prop state to editedNotes
  useEffect(() => {
    setEditedNotes(revisedNotes);
  }, [revisedNotes, setEditedNotes]);

  return (
    <AmchurCard
      title={intl.formatMessage({ id: "planPage.doctorNote" })}
      role="primary"
      allowEdit={true}
      inEditMode={inEditMode}
      onEditStateChanged={setEditMode}
      allowComparison={false}
      inCompMode={inCompMode}
      onCompModeChanged={setCompMode}
    >
      <Stack align-items="flex-start">
        {waiting && <SmallSehatSpinner />}
        <Grid container spacing={2} mb="5px">
          {symptoms.map((symptom) => (
            <SymptomChip text={symptom} key={symptom} />
          ))}
        </Grid>
        {inCompMode ? (
          <NonEditbaleNotesView notes={notes} />
        ) : (
          <EditbaleNotesView
            notes={editedNotes}
            setNotesState={setEditedNotes}
            inEditMode={inEditMode}
            updateNotes={updateNotes}
          />
        )}
      </Stack>
    </AmchurCard>
  );
}

function NonEditbaleNotesView({ notes }: { notes: string }) {
  return (
    <Box sx={{ textAlign: "left" }}>
      <Typography>{notes}</Typography>
    </Box>
  );
}

interface EditbaleNotesViewProps {
  notes: string;
  setNotesState: (notes: string) => void;
  inEditMode: boolean;
  updateNotes: (notes: string) => void;
}
function EditbaleNotesView({
  notes,
  setNotesState,
  inEditMode,
  updateNotes,
}: EditbaleNotesViewProps) {
  const valueRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  // to control the focus and cursor of doctor notes
  useEffect(() => {
    const copyNotes = valueRef.current?.value;
    const notesLength = copyNotes ? copyNotes?.length : 0;
    valueRef.current?.focus();
    valueRef.current?.setSelectionRange(notesLength, notesLength);
  }, [inEditMode]);

  useEffect(() => {
    if (!inEditMode && notes) {
      dispatch(activateBins());
      updateNotes(notes);
    }
  }, [notes, inEditMode, updateNotes, dispatch]);

  if (!inEditMode) {
    return <NonEditbaleNotesView notes={notes} />;
  }
  return (
    <TextField
      size="small"
      sx={{
        "& fieldset": {
          border: "2px solid transparent",
        },
      }}
      inputRef={valueRef}
      fullWidth
      multiline
      value={notes}
      maxRows={5}
      onChange={textChangeHandler(setNotesState)}
    ></TextField>
  );
}

export default DiagnosisPanel;
