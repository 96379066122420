import React from "react";
import { useState, useEffect } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Stack, Box, Typography } from "@mui/material";
import { RootState } from "../../../../state/reducers";
import CleanBin from "../../../../utils/BinsCleanupFunction";
import { fetchTradeNameGlossary } from "../../../../state/MedicineBinSlice";
import { PopulateMedsBin } from "../../../../utils/medsUtils";
import Alert from "../ui-components/Alert";
import { ListModeButton } from "../ui-components/BinButtons";
import MedicineBinSkeleton from "../ui-components/LoadingSkeletons";
import Bin from "../HelperComponents/BinCardV2";
import { SuccessAlertStyle } from "../../../../utils/constants";
import MarkAsReviewedBox from "../../../MarkAsReviewedBox";
import { ReactComponent as MedicineBinIcon } from "./../../../../assets/MedicineBinIcon.svg";
import AddOrEditMedicine from "./AddOrEditMedicine";
import ListView from "./ListView";
import useMedicine from "./useMedicine";
import MedicineHistory from "./MedicineHistory";

export interface Medicine {
  Trade_Name: string;
  Salt: string;
  Number: string;
  Unit: string;
  Route: string;
  Frequency: string;
  Start_date: string;
  End_date: string;
  Prescribed_by: string;
  Deleted: string;
  Delete_Reason: string;
  Discontinued_by: string;
  Reviewed_by: string;
  Reviewed_at: string;
  Note: string;
}

interface MedicineBinProps {
  data?: Medicine[];
  visitToken?: string;
}

const Medicines: React.FC<MedicineBinProps> = ({ data, visitToken = "" }) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      return Promise.all([dispatch(fetchTradeNameGlossary())])
        .then(() => {
          console.log("Both glossaries fetched successfully");
        })
        .catch((error) => {
          console.error("Error fetching one or both glossaries:", error);
        });
    };
    fetchData()
      .then(() => {
        console.log("Glossary data fetched successfully");
      })
      .catch((error) => {
        console.error("Error fetching glossary data:", error);
      });
  }, [dispatch]);

  const { TradeNameGlossary } = useSelector(
    (state: RootState) => state.MedicineBinGlossary
  );
  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const { benchmark, addDiscMed } = useMedicine();
  const [MedicinesArray, setMedicinesArray] = useState<Medicine[] | null>(null);
  const [DiscontinuedMedsArray, setDiscontinuedMedsArray] = useState<
    Medicine[] | null
  >(null);
  const [ItemsDisplay, setItemsDisplay] = useState<Medicine[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [SuccessMsg, setSuccessMsg] = useState<string>("");
  const [binToEdit, setBinToEdit] = useState<Medicine | null>(null);
  const [AddNew, setAddNew] = useState<boolean>(false);
  const [inEditing, setInEditing] = useState<boolean>(false);
  const [inAllView, setInAllView] = useState<boolean>(false);
  const [ReviewedBy, setReviewedBy] = useState<string>(
    MedicinesArray && MedicinesArray.length > 0
      ? MedicinesArray[0]?.Reviewed_by || ""
      : ""
  );
  const [ReviewedAt, setReviewedAt] = useState<string>(
    MedicinesArray && MedicinesArray.length > 0
      ? MedicinesArray[0]?.Reviewed_at || ""
      : ""
  );
  const isBinsActive = useSelector(
    (state: RootState) => state.binsActive.BinsActivate
  );

  useEffect(() => {
    if (TradeNameGlossary) {
      const cleanArray = CleanBin(data, "meds");
      const populatedArray = PopulateMedsBin(cleanArray, TradeNameGlossary);
      if (populatedArray !== undefined) {
        setMedicinesArray(populatedArray);
        setIsLoading(false);
      }
    }
  }, [data, TradeNameGlossary]);

  useEffect(() => {
    if (MedicinesArray) {
      setItemsDisplay(expanded ? MedicinesArray : MedicinesArray.slice(0, 3));
    }
  }, [expanded, MedicinesArray]);

  const toggleAddNewOn = () => {
    setAddNew(true);
  };
  const toggleEditModeOn = () => {
    setInEditing(true);
  };
  const CloseMedicineHistory = () => {
    setInAllView(false);
  };
  const toggleExpanded = () => setExpanded(!expanded);

  const onMarkAsReviewed = (reviewed: boolean) => {
    if (reviewed && MedicinesArray) {
      const today = new Date();
      const datePart = today.toLocaleDateString();
      const timePart = today.toLocaleTimeString();
      const reviewtime = datePart + " at " + timePart;

      setReviewedBy(currentPhysician ?? "[Couldn't Fetch Physician]");
      setReviewedAt(reviewtime);

      const updatedMedicines = MedicinesArray?.map((medicine) => ({
        ...medicine,
        Reviewed_at: reviewtime,
        Reviewed_by: currentPhysician ?? "[Couldn't Fetch Physician]",
      }));

      setMedicinesArray(updatedMedicines);
      benchmark(updatedMedicines);
    } else if (!reviewed && MedicinesArray) {
      const updatedMedicines = MedicinesArray?.map((medicine) => ({
        ...medicine,
        Reviewed_at: "",
        Reviewed_by: "",
      }));

      setReviewedBy("");
      setReviewedAt("");

      setMedicinesArray(updatedMedicines);
      benchmark(updatedMedicines);
    }
  };

  const onSave = (newMedicine: Medicine, KeyTradeName: string) => {
    const newState = [...(MedicinesArray ?? [])];
    const index = newState.findIndex((obj) => obj.Trade_Name === KeyTradeName);
    if (index !== -1) {
      newState[index] = newMedicine;
      setMedicinesArray(newState);
      benchmark(newState);
      setSuccessMsg("Medicine Updated Successfuly");
    } else {
      const newerState = [...(MedicinesArray ?? []), newMedicine];
      setMedicinesArray(newerState);
      benchmark(newerState);
      setSuccessMsg("Medicine Added Successfuly");
    }
    setShowSuccessMessage(true);
  };

  const onDelete = (KeyTradeName: string) => {
    const newState = [...(MedicinesArray ?? [])];
    const index = newState.findIndex((obj) => obj.Trade_Name === KeyTradeName);
    if (index !== -1) {
      newState.splice(index, 1);
      setMedicinesArray(newState);
      benchmark(newState);
      setSuccessMsg("Medicine Deleted Successfuly");
    }
    setShowSuccessMessage(true);
  };

  const onDiscontinue = (discMedicine: Medicine, KeyTradeName: string) => {
    const newState = [...(MedicinesArray ?? [])];
    const index = newState.findIndex((obj) => obj.Trade_Name === KeyTradeName);
    const newDiscState = [...(DiscontinuedMedsArray ?? []), discMedicine];
    console.log("new Discontinued state meds are: ", newDiscState);
    if (index !== -1) {
      newState.splice(index, 1);
      setMedicinesArray(newState);
      setDiscontinuedMedsArray(newDiscState);
      benchmark(newState);
      addDiscMed(newDiscState);
      setSuccessMsg("Medicine Deleted Successfuly");
      console.log("disc meds: ", newDiscState);
    }
    setShowSuccessMessage(true);
  };

  if (isLoading || !MedicinesArray || !ItemsDisplay) {
    return (
      <Bin title="Medicines" icon={<MedicineBinIcon />}>
        <MedicineBinSkeleton />
      </Bin>
    );
  }
  const MedsTradeNames: string[] = MedicinesArray.map(
    (item: Medicine) => item.Trade_Name
  );

  return (
    <>
      {inAllView && (
        <Box
          sx={{
            position: "fixed",
            top: 100,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            zIndex: 999,
          }}
        >
          <MedicineHistory
            ActiveMeds={MedicinesArray}
            DiscMeds={DiscontinuedMedsArray}
            closeMedicineHistory={CloseMedicineHistory}
          />
        </Box>
      )}
      <Bin
        title="Medicines"
        icon={<MedicineBinIcon />}
        buttons={[
          { name: "Medicine History", onClick: () => setInAllView(true) },
        ]}
      >
        {AddNew ? (
          <AddOrEditMedicine
            Bin={null}
            handleClose={() => setAddNew(false)}
            onSave={onSave}
            onDelete={onDelete}
            onDiscontinue={onDiscontinue}
            inEditing={false}
            MedsTradeNames={MedsTradeNames}
          />
        ) : inEditing ? (
          <>
            <AddOrEditMedicine
              Bin={binToEdit}
              handleClose={() => setInEditing(false)}
              onSave={onSave}
              onDelete={onDelete}
              onDiscontinue={onDiscontinue}
              inEditing={true}
              MedsTradeNames={MedsTradeNames}
            />
          </>
        ) : (
          <Stack spacing={1} position="relative">
            {MedicinesArray.length > 0 ? (
              Object.entries(ItemsDisplay).map(([key, items], idx) => (
                <div
                  style={{
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    width: "100%",
                    cursor: "pointer",
                    opacity: showSuccessMessage ? 0.5 : 1,
                  }}
                  onClick={() => {
                    setBinToEdit(items);
                    toggleEditModeOn();
                  }}
                >
                  <ListView
                    key={idx}
                    Bin={items}
                    MedsTradeNames={MedsTradeNames}
                  />
                </div>
              ))
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography component="span" sx={{ color: "gray" }}>
                    No Medicines found
                  </Typography>
                </Stack>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent:
                  MedicinesArray.length > 3 ? "space-between" : "right",
              }}
            >
              {MedicinesArray.length > 3 && (
                <ListModeButton type={"Expand"} onClick={toggleExpanded}>
                  {expanded ? "Collapse All" : "Expand All"}
                </ListModeButton>
              )}
              {isBinsActive && (
                <ListModeButton type={"Add"} onClick={toggleAddNewOn}>
                  + Add Medicine
                </ListModeButton>
              )}
            </div>
            {isBinsActive && (
              <MarkAsReviewedBox
                onMarkAsReviewed={onMarkAsReviewed}
                ReviewedBy={ReviewedBy}
                ReviewedAt={ReviewedAt}
              />
            )}
            {showSuccessMessage && (
              <div style={SuccessAlertStyle}>
                <Alert
                  msg={SuccessMsg}
                  setShowAlert={setShowSuccessMessage}
                  type={"success"}
                />
              </div>
            )}
          </Stack>
        )}
      </Bin>
    </>
  );
};

export default Medicines;
