import React, { useState } from "react";
import { TextField, Typography, TextFieldProps } from "@mui/material";

interface DateFieldProps extends Omit<TextFieldProps, "type"> {
  name: string;
  label: string;
  required?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({
  name,
  label,
  error = false,
  helperText = "",
  value,
  onChange,
  required,
  ...rest
}) => {
  const [active, setActive] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color="text.secondary"
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </Typography>
      <TextField
        name={name}
        type="date"
        fullWidth
        value={value}
        onChange={onChange}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        error={error}
        helperText={helperText}
        {...rest}
        InputProps={{
          onBlur: (e) => {
            setActive(false);
            if (rest.onBlur) {
              rest.onBlur(e);
            }
          },
          sx: {
            height: "clamp(35px, 4vh, 40px)",
            border: `1px solid ${
              active
                ? "#008AD8"
                : required && value === ""
                ? "orange"
                : "#AEBECD"
            }`,
            backgroundColor: "white",
            fontSize: "16px",
            borderRadius: "8px",
            textAlign: "left",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
      />
    </div>
  );
};

export default DateField;
