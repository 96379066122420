import React from "react";
import { Stack, Typography } from "@mui/material";
import { CheckTitle } from "../../../../utils/medsUtils";
import { CheckMedicalConditionIncomplete } from "../../../../utils/BinsCompleteness";

export interface MedConditionAttributes {
  Name: string;
  Age: string;
}

interface MedConditionProps {
  MedCondition: MedConditionAttributes;
}

const MedConditionView: React.FC<MedConditionProps> = ({ MedCondition }) => {
  const incomplete = CheckMedicalConditionIncomplete(MedCondition);
  return (
    <>
      <div
        style={{ backgroundColor: "white", borderRadius: "8px", width: "100%" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack key="MedCondition" direction="row" alignItems="center">
            <Typography
              component="span"
              variant="h6"
              sx={{
                color: incomplete ? "orange" : "#929FB1",
                margin: 0,
                padding: 0,
                fontSize: "16px",
              }}
            >
              {CheckTitle({
                key: "Medical_condition",
                value: MedCondition.Name,
              })}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              component="span"
              sx={{
                color: "#AEBECD",
                mr: 1,
                margin: 0,
                padding: 0,
                textAlign: "right",
                display: "block",
                fontSize: "16px",
              }}
            >
              {MedCondition.Age !== "" ? (
                <>
                  {" "}
                  Onset:{" "}
                  {CheckTitle({
                    key: "Age",
                    value: MedCondition.Age,
                  })}{" "}
                  Years{" "}
                </>
              ) : (
                ""
              )}
            </Typography>
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default MedConditionView;
