import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Checkbox, Stack } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { areRequiredAttributesPresent } from "../../../../utils/allergies";
import { LIFE_THREATENING_ALLERGY_REACTIONS } from "../../../../constants/allergies";
import { RootState } from "../../../../state/reducers";
import { ListModeButton } from "../ui-components/BinButtons";

interface Allergy {
  Allergen: string;
  Reaction: string[];
  Category: string;
}

interface ListViewProps {
  allergies: Allergy[];
  onClickHandler: (allergy: Allergy, index: number) => void;
  addButtonClickHandler: () => void;
  onReviewHandler: (reviewed: boolean) => void;
}

const ListView: React.FC<ListViewProps> = ({
  allergies,
  onClickHandler,
  addButtonClickHandler,
  onReviewHandler,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const isBinsActive = useSelector(
    (state: RootState) => state.binsActive.BinsActivate
  );
  const [showAll, setShowAll] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [reviewedAt, setReviewedAt] = useState("");

  const visibleAllergies = showAll ? allergies : allergies.slice(0, 3);

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const renderAddAllergyButton = () => (
    <Stack
      flexDirection="row"
      justifyContent={"flex-end"}
      sx={{ width: "100%" }}
    >
      <ListModeButton type={"Add"} onClick={addButtonClickHandler}>
        + Add Allergy
      </ListModeButton>
    </Stack>
  );

  const markAsReviewedHandler = (e: { target: { checked: boolean } }) => {
    const today = new Date();
    const dateString = today.toISOString().split("T")[0];
    setReviewed(e.target.checked);
    setReviewedAt(dateString);
    onReviewHandler(e.target.checked);
  };
  return (
    <>
      {isBinsActive ? (
        <>
          {visibleAllergies?.map((allergy, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              mb={2}
              p={1}
              onClick={() => onClickHandler(allergy, index)}
              bgcolor={
                allergy.Reaction.some((reaction: string) =>
                  LIFE_THREATENING_ALLERGY_REACTIONS.includes(reaction)
                )
                  ? "#FFBFBF33"
                  : "#FFFFFF"
              }
              sx={{
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <Box textAlign={"left"}>
                <Typography
                  fontSize={"16px"}
                  fontWeight={400}
                  fontStyle={"normal"}
                  lineHeight={"normal"}
                  color={
                    areRequiredAttributesPresent(allergy)
                      ? "#000000"
                      : "#DB7B04"
                  }
                >
                  {allergy.Allergen}
                </Typography>
                <Stack flexDirection={"row"}>
                  {allergy.Reaction.some((reaction: string) =>
                    LIFE_THREATENING_ALLERGY_REACTIONS.includes(reaction)
                  ) && (
                    <WarningAmberOutlinedIcon
                      sx={{
                        color: "#FFBFBF",
                        fontSize: 14,
                        mr: 0.5,
                        mt: 0.1,
                        strokeWidth: 5,
                      }}
                    />
                  )}
                  <Typography
                    fontSize={"12px"}
                    fontWeight={400}
                    fontStyle={"normal"}
                    lineHeight={"normal"}
                    color="#757575"
                  >
                    Causes {allergy.Reaction.join(", ")}
                  </Typography>
                </Stack>
              </Box>
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                fontStyle={"normal"}
                lineHeight={"normal"}
                color="#000000"
              >
                {allergy.Category}
              </Typography>
            </Box>
          ))}

          {allergies?.length === 0 && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography component="span" sx={{ color: "gray" }}>
                No Known Allergies
              </Typography>
            </Stack>
          )}

          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            justifyContent={allergies?.length > 3 ? "space-between" : "right"}
            alignItems="center"
            p={1}
          >
            {allergies?.length > 3 ? (
              <ListModeButton type={"Expand"} onClick={toggleShowAll}>
                {showAll ? "Collapse All" : "Expand All"}
              </ListModeButton>
            ) : (
              <></>
            )}
            {renderAddAllergyButton()}
          </Box>

          <Box
            mt={2}
            width={"100%"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
          >
            <Box
              display="flex"
              justifyContent={"flex-start"}
              alignItems="center"
            >
              <Checkbox
                size="small"
                onChange={markAsReviewedHandler}
                sx={{
                  p: 0,
                  mr: 1,
                  fontSize: 9,
                  width: 12,
                  height: 12,
                  "& .MuiSvgIcon-root": {
                    fontSize: 16,
                  },
                }}
              />
              <Typography
                component="span"
                sx={{ color: "#404B5A", fontSize: 12 }}
              >
                Mark as reviewed
              </Typography>
            </Box>
            {reviewed && reviewedAt !== "" && (
              <Typography
                fontSize="10px"
                color="text.secondary"
                fontStyle="italic"
                sx={{
                  whiteSpace: "normal",
                  lineHeight: "1.4",
                  wordWrap: "break-word",
                }}
              >
                Last reviewed by Dr. {currentPhysician} on {reviewedAt}
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Typography component="span" sx={{ color: "gray" }}>
            No Known Allergies
          </Typography>
          {isBinsActive && <Box width={"100%"}>{renderAddAllergyButton()}</Box>}
        </Box>
      )}
    </>
  );
};

export default ListView;
