/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useIntl, type IntlShape } from "react-intl";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { AssessmentInput } from "../gql/graphql";
import { type BinSet } from "../hooks/useAudioProcessing";
import { binTitleMap, defaultSocialHxData } from "../constants/UtilConstants";
import CleanBin from "../utils/BinsCleanupFunction";
import BinInfoCard from "./BinCard";
import Allergies from "./v2/Bins/Allergies/Allergies";
// import PastMedicalHx from "./v2/Bins/PastMedicalHx/PastMedicalHx";
import Medicines from "./v2/Bins/Medicines/Medicines";
// import Habits from "./v2/Bins/Habits/Habits";
import FamilyHx from "./v2/Bins/FamilyHx/FamilyHx";
import SocialHx from "./v2/Bins/SocialHx/SocialHx";

function binTitle(intl: IntlShape, bin: string) {
  // ideally all names should go through i18n, but for now just the ones where
  // we want to rename the field coming from the model.
  //Removing the names we have shifted to V2 for
  if (["drugs", "a_p", "socialHx"].includes(bin)) {
    return intl.formatMessage({ id: `bin.title.${bin}` });
  } else {
    return bin;
  }
}

export interface BinCardStackProps {
  align?: "left" | "right";
  binSet: BinSet;
  revisedBinSet?: BinSet;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady?: boolean;
  isEditable?: boolean;
  isComparable?: boolean;
  Indexes?: number[];
  visitToken?: string;
}

function BinCardStack({
  align,
  binSet,
  revisedBinSet,
  updateAssessment,
  assessmentReady = true,
  isEditable = false,
  isComparable = false,
  Indexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  visitToken = "",
}: BinCardStackProps) {
  const [bins, setBins] = useState<any[]>([]);
  const revisedBins = revisedBinSet === undefined ? binSet : revisedBinSet;
  const [AllergiesData, setAllergiesData] = useState([]);
  const [MedsData, setMedsData] = useState([]);
  const [FamilyHxData, setFamilyHxData] = useState([]);
  const [SocialHxData, setSocialHxData] = useState(defaultSocialHxData);

  useEffect(() => {
    setBins(Indexes.map((index) => binTitleMap[index]));
  }, [align, binSet, revisedBinSet, Indexes]);

  useEffect(() => {
    //Instead of making state variables separately, why dont we just pass revisedBinSet.bin || [] directly to components?
    if (revisedBinSet?.allergies) {
      setAllergiesData(JSON.parse(revisedBinSet?.allergies));
    }
    if (revisedBinSet?.meds) {
      setMedsData(JSON.parse(revisedBinSet?.meds));
    }
    if (revisedBinSet?.familyHx) {
      setFamilyHxData(JSON.parse(revisedBinSet?.familyHx));
    }
    if (revisedBinSet?.socialHx) {
      setSocialHxData(JSON.parse(revisedBinSet?.socialHx));
    }
  }, [revisedBinSet]);
  return (
    <>
      {bins.map((bin) => (
        <React.Fragment key={bin}>
          {bin === "allergies" ? (
            <Allergies data={AllergiesData} visitToken={visitToken} />
          ) : bin === "meds" ? (
            <Medicines data={MedsData} visitToken={visitToken} />
          ) : bin === "familyHx" ? (
            <FamilyHx data={FamilyHxData} visitToken={visitToken} />
          ) : bin === "socialHx" ? (
            <SocialHx data={SocialHxData} visitToken={visitToken} />
          ) : (
            <BinCard
              bin={binSet[bin as keyof BinSet] || "[]"}
              revisedBin={revisedBins[bin as keyof BinSet] || "[]"}
              title={bin}
              updateAssessment={updateAssessment}
              assessmentReady={assessmentReady}
              isEditable={isEditable}
              isComparable={isComparable}
              visitToken={visitToken}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

interface BinCardProps {
  bin: string;
  revisedBin: string;
  title: string;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady: boolean;
  isEditable: boolean;
  isComparable: boolean;
  visitToken: string;
}

const getIconForTitle = (title: string) => {
  switch (title) {
    case "socialHx":
      return (
        <HistoryIcon
          style={{ marginRight: 8, marginBottom: 0, fontSize: 32 }}
        />
      );
    default:
      return null;
  }
};
function BinCard({
  bin,
  revisedBin,
  title,
  updateAssessment = () => {},
  assessmentReady,
  isEditable,
  isComparable,
  visitToken,
}: BinCardProps) {
  const intl = useIntl();
  const [inEditMode, setEditMode] = useState(false);
  const [inCompMode, setCompMode] = useState(false);
  const [isActive, setActive] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [binState, setBinState] = useState<any>([]);
  useEffect(() => {
    // bin contain original bin data while revisedBin contains modified one
    const binData = JSON.parse(revisedBin);
    setBinState(binData);
  }, [revisedBin, title]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateBin = (currState: { [key: string]: any }[]) => {
    const updatedBin = { [title]: JSON.stringify(currState) };
    console.log("updatedBin", updatedBin);
    updateAssessment(updatedBin);
  };

  const handleEditField = (
    index: number,
    updatedObj: { [key: string]: string }
  ) => {
    const newState = [...binState];
    newState[index] = updatedObj;
    console.log("new updated field", newState);
    updateBin(newState);
    setBinState(newState);
  };

  const handleDeleteField = (index: number, key: string) => {
    console.log(
      "handle delete",
      index,
      key,
      "binState",
      [...binState],
      "old way",
      binState
    );
    let newState = [...binState];
    delete newState[index][key];
    newState = newState.filter((obj) => JSON.stringify(obj) !== "{}");
    console.log("newState", newState);
    updateBin(newState);
    setBinState(newState);
  };

  const handleNewField = (key: string, value: string) => {
    const newState = [...binState, { [key]: value }];
    updateBin(newState);
    setBinState(newState);
  };

  const icon = getIconForTitle(title);
  return (
    <BinInfoCard
      title={
        icon ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>{icon}</Box>
              <Box>{binTitle(intl, title)}</Box>
            </Stack>
          </>
        ) : (
          binTitle(intl, title)
        )
      }
      role="secondary"
      inTextAlign="left"
      allowEdit={isEditable && isActive}
      inEditMode={inEditMode}
      onEditStateChanged={setEditMode}
      allowComparison={isComparable && isActive}
      inCompMode={inCompMode}
      onCompModeChanged={setCompMode}
      isActive={isActive}
      titleColor="white"
      titleVarient="h3"
    >
      <BinInfoView
        key={"revised"}
        title={title}
        bin={binState}
        inEditMode={inEditMode}
        inCompMode={inCompMode}
        onEdit={handleEditField}
        onDelete={handleDeleteField}
        onAdd={handleNewField}
        assessmentReady={assessmentReady}
        setActive={setActive}
        setEditMode={setEditMode}
        updateBin={updateBin}
        setBinState={setBinState}
        visitToken={visitToken}
      />
    </BinInfoCard>
  );
}

interface BinInfoViewProps {
  bin: (Record<string, string> | string)[];
  title: string;
  inEditMode: boolean;
  inCompMode: boolean;
  onEdit: (index: number, obj: { [key: string]: string }) => void;
  onDelete: (index: number, key: string) => void;
  onAdd: (key: string, value: string) => void;
  assessmentReady: boolean;
  setActive: (b: boolean) => void;
  setEditMode: (b: boolean) => void;
  updateBin: (currState: { [key: string]: any }[]) => void;
  setBinState: (newState: { [key: string]: any }[]) => void;
  visitToken: string;
}

function BinInfoView({
  bin,
  title,
  inEditMode,
  inCompMode,
  onEdit,
  onDelete,
  onAdd,
  assessmentReady,
  setActive,
  setEditMode,
  updateBin,
  setBinState,
  visitToken,
}: BinInfoViewProps) {
  const intl = useIntl();
  if (bin.length > 0) {
    if (title === "meds" || title === "allergies" || title === "familyHx") {
      return (
        <BinItemView
          title={title}
          bin={bin}
          index={-1}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          updateField={onEdit}
          deleteField={onDelete}
          setActive={setActive}
          setEditMode={setEditMode}
          updateBin={updateBin}
          setBinState={setBinState}
          visitToken={visitToken}
        />
      );
    } else {
      return (
        <Stack gap={2}>
          {bin.map((item, index) => (
            <BinItemView
              title={title}
              bin={item}
              key={index}
              index={index}
              inEditMode={inEditMode}
              inCompMode={inCompMode}
              updateField={onEdit}
              deleteField={onDelete}
              setActive={setActive}
              setEditMode={setEditMode}
              updateBin={updateBin}
              setBinState={setBinState}
              visitToken={visitToken}
            />
          ))}
        </Stack>
      );
    }
  } else {
    return (
      <>
        <BinItemView
          bin={
            assessmentReady
              ? intl.formatMessage({ id: "bin.notMentioned" })
              : ""
          }
          title={title}
          index={-1}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          updateField={onEdit}
          deleteField={onDelete}
          setActive={setActive}
          setEditMode={setEditMode}
          updateBin={updateBin}
          setBinState={setBinState}
          visitToken={visitToken}
        />
      </>
    );
  }
}

interface BinItemViewProps {
  title: string;
  bin: Record<string, string> | string | any;
  index: number;
  inEditMode: boolean;
  inCompMode: boolean;
  updateField: (index: number, obj: { [key: string]: string }) => void;
  deleteField: (index: number, key: string) => void;
  setActive: (b: boolean) => void;
  setEditMode: (b: boolean) => void;
  updateBin: (currState: { [key: string]: any }[]) => void;
  setBinState: (newState: { [key: string]: any }[]) => void;
  visitToken: string;
}
function BinItemView({
  title,
  bin,
  index,
  inEditMode,
  inCompMode,
  updateField,
  deleteField,
  setActive,
  setEditMode,
  updateBin,
  setBinState,
  visitToken,
}: BinItemViewProps) {
  const [itemState, setItemState] = useState<{ [key: string]: string } | any>(
    {}
  );
  const [currState, setCurrState] = useState<{ [key: string]: string } | any>(
    {}
  );
  useEffect(() => {
    if (typeof bin === "object" && bin !== null) {
      setItemState(bin);
      const CleanedBin = CleanBin(bin, title);
      setCurrState(CleanedBin);
      setActive(true);
      if (title === "socialHx") {
        setEditMode(true);
      }
    }
    if (title === "meds" || title === "allergies" || title === "familyHx") {
      setActive(true);
    }
  }, [bin, setActive, setEditMode, title]);

  useEffect(() => {
    if (
      !inEditMode &&
      Object.keys(itemState).length !== 0 &&
      JSON.stringify(itemState) !== JSON.stringify(bin)
    ) {
      console.log("itemState", itemState);
      console.log("index", index);
      updateField(index, itemState);
    }
  }, [inEditMode, bin, index, itemState, updateField]);

  useEffect(() => {}, [currState]);

  const onStateChange = (key: string, value: string) => {
    const newState = { ...itemState, [key]: value };
    setItemState(newState);
  };

  if (inEditMode) {
    return (
      <EditbaleBinItemView
        title={title}
        itemState={itemState}
        index={index}
        updateState={onStateChange}
        deleteField={deleteField}
      />
    );
  } else {
    return <NonEditableBinItemView bin={bin} title={title} />;
  }
}

function NonEditableBinItemView({
  bin,
  title,
}: {
  bin: Record<string, string> | string | any;
  title: string;
}) {
  if (typeof bin === "string") {
    return <></>;
  }
  return (
    <Stack>
      {Object.keys(bin).map((key) => (
        <Box key={key} mb={0}>
          <Typography variant="h6" component="span" sx={{ color: "black" }}>
            {key}:
          </Typography>
          <br />
          <Typography
            component="span"
            sx={{ color: bin[key] ? "gray" : "gray", ml: 1 }}
          >
            {bin[key] || "ND"}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}

interface EditbaleBinItemViewProps {
  title: string;
  index: number;
  itemState: { [key: string]: string };
  updateState: (key: string, value: string) => void;
  deleteField: (index: number, key: string) => void;
}
function EditbaleBinItemView({
  title,
  index,
  itemState,
  updateState,
  deleteField,
}: EditbaleBinItemViewProps) {
  return (
    <Stack>
      {Object.keys(itemState).map((key) => (
        <TextField
          size="small"
          variant="filled"
          key={key}
          label={key}
          value={itemState[key]}
          onChange={(e) => updateState(key, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    console.log("itemState", itemState);
                    deleteField(index, key);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      ))}
    </Stack>
  );
}

export default BinCardStack;
