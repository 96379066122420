import React from "react";
import { Stack, Skeleton } from "@mui/material";

export const MedicineBinSkeleton = () => {
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack spacing={2} direction="column">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ bgcolor: "grey.500", borderRadius: "12px" }}
              width={100}
              height={30}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ bgcolor: "grey.300", borderRadius: "12px" }}
              width={80}
              height={20}
            />
          </Stack>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ bgcolor: "grey.300", borderRadius: "12px" }}
            width={130}
            height={20}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ bgcolor: "grey.400", borderRadius: "12px" }}
            width={80}
            height={20}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ bgcolor: "grey.400", borderRadius: "12px" }}
            width={90}
            height={20}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default MedicineBinSkeleton;
