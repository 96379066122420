import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PardonsState {
  AllergenConflictPardons: string[];
  SameClassPardons: string[];
}

const initialState: PardonsState = {
  AllergenConflictPardons: [],
  SameClassPardons: [],
};

const BinsUtilArraysSlice = createSlice({
  name: "BinsUtilArrays",
  initialState,
  reducers: {
    addAllergenConflictPardon: (state, action: PayloadAction<string>) => {
      if (!state.AllergenConflictPardons.includes(action.payload)) {
        state.AllergenConflictPardons.push(action.payload);
      }
    },
    addSameClassPardon: (state, action: PayloadAction<string>) => {
      if (!state.SameClassPardons.includes(action.payload)) {
        state.SameClassPardons.push(action.payload);
      }
    },
  },
});

export const { addAllergenConflictPardon, addSameClassPardon } =
  BinsUtilArraysSlice.actions;

export default BinsUtilArraysSlice.reducer;
