export const AlertStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
  width: "100%",
};

export const SuccessAlertStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1,
  width: "60%",
  opacity: 1,
};

export const ListOfHeadings = [
  "Marital Status",
  "Occupation",
  "Education",
  "Identity",
  "Set Meal Schedule",
  "Exercise",
  "Routine",
  "Sleep Same Time",
  "Stress Level",
  "Medical condition",
  "Relation",
];
export const ListOfNotCapitalizations = ["Unit"];

export const FrequencyConversions: { [key: string]: number } = {
  "once a day": 1,
  "once daily": 1,
  "twice a day": 2,
  "twice daily": 2,
  "thrice a day": 3,
  "three times a day": 3,
  "three times daily": 3,
  "four times a day": 4,
  "five times a day": 5,
  "six times a day": 6,
  "seven times a day": 7,
  "eight times a day": 8,
  "every 4 hours": 6, // 24 hours divided by 4
  "every 5 hours": 4.8, // Approximation (24 / 5)
  "every 6 hours": 4, // 24 / 6
  "every 8 hours": 3, // 24 / 8
  "every 6-8 hours": 3.5, // Average of 6 and 8
  "every 4-6 hours": 5, // Average of 4 and 6
  "every 4-6 hrs": 5, // Same as above
  "every 4-6 hrs as needed": 5, // Same as above
  "4-6 hours as needed": 5, // Same as above
  "2-3 times daily": 2.5, // Average of 2 and 3
  "3-4 times daily": 3.5, // Average of 3 and 4
  "3-4 days": 0.75, // Assuming dosage is daily; average 3.5 days
  "3-6 days": 0.5, // Average of 3 and 6
  "daily in divided doses": 1, // Indicates once daily
  "three to four times a day": 3.5, // Average of 3 and 4
  "every 6-8 hrs if needed": 3.5, // Average of 6 and 8
  "every four hours": 6, // Same as "every 4 hours"
  "every six hours": 4, // Same as "every 6 hours"
  "up to 600 mg four times a day": 4, // Focus on frequency
  "200-400 mg three times a day": 3, // Focus on frequency
  "3-4 times a day": 3.5, // Average of 3 and 4
  "up to 4 times a day": 4, // Maximum of 4 times a day
  "3 times a day": 3, // Same as "three times a day"
  "once a day PRN": 1,
  "twice a day PRN": 2,
  "three times a day PRN": 3,
  "four times a day PRN": 4,
  "every 8 hours PRN": 3, // Same as "every 8 hours"
  "every 6 hours PRN": 4, // Same as "every 6 hours"
  "every 4 hours PRN": 6, // Same as "every 4 hours"
};

export const FamilyHxIgnoreAttributes = [
  "Medical_condition",
  "Reviewed_by",
  "Reviewed_at",
  "Additional_notes",
];
