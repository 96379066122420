import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack, Box } from "@mui/material";
import { cleanSocialHxBin } from "../../../../utils/BinsCleanupFunction";
import Alert from "../ui-components/Alert";
import Bin from "../HelperComponents/BinCardV2";
import { RootState } from "../../../../state/reducers";
import EditModeButton from "../ui-components/BinButtons";
import MarkAsReviewedBox from "../../../MarkAsReviewedBox";
import { successAlertStyle } from "../../../../constants/StyleConstants";
import useBenchmarkSocialHx, {
  useSetSocialHxFields,
  useSetReviewedAt,
  useSetReviewedBy,
} from "./useSocialHx";
import { ReactComponent as SocialHxBinIcon } from "./../../../../assets/MedicineBinIcon.svg";
import {
  MaritalStatusView,
  IdentityView,
  OccupationView,
  EducationView,
  DietView,
  ExerciseView,
  SleepView,
  RoutineView,
  StressView,
} from "./Views";
import {
  RoutineEdit,
  EducationEdit,
  IdentityEdit,
  MaritalStatusEdit,
  OccupationEdit,
  StressEdit,
  SleepEdit,
  DietEdit,
} from "./Edits";
import ExerciseEdit from "./ExerciseEdit";
import { SocialHxInActive } from "./SocialHxInactive";

export interface ExerciseAttributes {
  Exercise_Name: string;
  Exercise_Frequency: string;
  Exercise_Duration: string;
}

export interface SocialHxAttributes {
  Marital_Status: string;
  Identity: string;
  Set_Meal_Schedule: string;
  Diet_Pattern: string[];
  cups_fruit: string;
  cups_veggies: string;
  helpings_packaged: string;
  specific_consumptions: string[];
  sugar_spoons: string;
  Artificial_Sweetener: string;
  Routine: string;
  Stress_Level: string;
  Occupation: string;
  Occupation_Type: string;
  Education: string;
  Exercise: ExerciseAttributes[];
  Sleep_Quality: string;
  Sleep_Duration: string;
  Sleep_Same_Time: string;
  Insomnia_Symptoms: string;
  Stress_Symptoms: string[];
  Stress_Management: string[];
  Other: string | string[];
  ReviewedBy: string;
  ReviewedAt: string;
  Diet_Pattern_Other: string;
  Stress_Symptoms_Other: string;
  Stress_Management_Other: string;
}

interface SocialHxBinProps {
  data?: SocialHxAttributes;
  visitToken?: string;
}

const SocialHx: React.FC<SocialHxBinProps> = ({ data, visitToken = "" }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [SuccessMsg, setSuccessMsg] = useState<string>("");
  const { benchmark } = useBenchmarkSocialHx();
  const setSocialHxFields = useSetSocialHxFields();
  const setReviewedBy = useSetReviewedBy();
  const setReviewedAt = useSetReviewedAt();
  const [activeEdit, setActiveEdit] = useState<string | null>(null);

  const { ReviewedBy, ReviewedAt } = useSelector(
    (state: RootState) => state.socialHx
  );

  const SocialHxObject = useSelector((state: RootState) => state.socialHx);

  const currentPhysician: string | undefined | null = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );

  const isBinsActive = useSelector(
    (state: RootState) => state.binsActive.BinsActivate
  );

  useEffect(() => {
    console.log("in socialx useeffect, data is: ", data);
    const correctedBin: SocialHxAttributes = cleanSocialHxBin(data);
    console.log(
      "in socialx useeffect, correctedbin marital status is: ",
      correctedBin.Marital_Status
    );
    setSocialHxFields(correctedBin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); //disabled because else it was creating issue and over-writing edits on every re-render.

  const onMarkAsReviewed = (reviewed: boolean) => {
    if (reviewed) {
      const today = new Date();
      const datePart = today.toLocaleDateString();
      const timePart = today.toLocaleTimeString();
      const reviewtime = datePart + " at " + timePart;

      setReviewedBy(currentPhysician ?? "[Couldn't Fetch Physician]");
      setReviewedAt(reviewtime);
    } else if (!reviewed) {
      setReviewedBy("");
      setReviewedAt("");
    }
  };

  const toggleEdit = (key: string) => {
    setActiveEdit((prev) => (prev === key ? null : key));
  };

  const onSave = () => {
    benchmark(SocialHxObject);
    setSuccessMsg("SocialHx Updated Successfuly");
    setShowSuccessMessage(true);
    setActiveEdit(null);
  };

  return (
    <Bin title="Social History" icon={<SocialHxBinIcon />}>
      {isBinsActive ? (
        <>
          <Stack
            spacing={0}
            sx={{ marginBottom: "20px", position: "relative" }}
          >
            {[
              {
                key: "MaritalStatus",
                View: MaritalStatusView,
                Edit: MaritalStatusEdit,
              },
              { key: "Identity", View: IdentityView, Edit: IdentityEdit },
              { key: "Occupation", View: OccupationView, Edit: OccupationEdit },
              { key: "Education", View: EducationView, Edit: EducationEdit },
              { key: "Diet", View: DietView, Edit: DietEdit },
              { key: "Exercise", View: ExerciseView, Edit: ExerciseEdit },
              { key: "Routine", View: RoutineView, Edit: RoutineEdit },
              { key: "Sleep", View: SleepView, Edit: SleepEdit },
              { key: "Stress", View: StressView, Edit: StressEdit },
            ].map(({ key, View, Edit }) => (
              <Box key={key} sx={{ padding: "2px" }}>
                {activeEdit === key ? (
                  <Edit onClose={() => toggleEdit(key)} />
                ) : (
                  <Box
                    onClick={() => {
                      toggleEdit(key);
                    }}
                  >
                    <View showSuccessMessage={showSuccessMessage} />
                  </Box>
                )}
              </Box>
            ))}
          </Stack>

          <MarkAsReviewedBox
            onMarkAsReviewed={onMarkAsReviewed}
            ReviewedBy={ReviewedBy}
            ReviewedAt={ReviewedAt}
          />

          <EditModeButton type="save" onClick={onSave}>
            Save
          </EditModeButton>
        </>
      ) : (
        <SocialHxInActive />
      )}

      {showSuccessMessage && (
        <div style={successAlertStyle}>
          <Alert
            msg={SuccessMsg}
            setShowAlert={setShowSuccessMessage}
            type={"success"}
          />
        </div>
      )}
    </Bin>
  );
};

export default SocialHx;
