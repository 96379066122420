import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchFieldProps {
  options: string[];
  label: string;
  onChange: (value: string) => void;
  value: string;
  required?: boolean;
  defaultOther?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  options,
  label,
  value,
  onChange,
  required = false,
  defaultOther = true,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (value !== inputValue) {
      if (options.includes(value)) {
        setInputValue(value);
      } else if (defaultOther) {
        setInputValue("Other");
      }
    }
  }, [value, options, inputValue, defaultOther]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color={active ? "#008AD8" : "text.secondary"}
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </Typography>
      <Autocomplete
        freeSolo
        value={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          onChange(newInputValue);
        }}
        options={options.filter((option) =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            InputProps={{
              ...params.InputProps,
              sx: {
                height: "clamp(35px, 4vh, 40px)",
                border: `1px solid ${
                  active
                    ? "#008AD8"
                    : required && value === ""
                    ? "orange"
                    : "#AEBECD"
                }`,
                backgroundColor: "white",
                fontSize: "16px",
                borderRadius: "8px",
                "&:focus": {
                  backgroundColor: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:focus .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
              startAdornment:
                inputValue === "" ? (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#AEBECD" }} />
                  </InputAdornment>
                ) : null,
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchField;
