import { api } from './baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type AssessmentInput = {
  a_p?: InputMaybe<Scalars['String']>;
  allergies?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  drugs?: InputMaybe<Scalars['String']>;
  familyHx?: InputMaybe<Scalars['String']>;
  hopi?: InputMaybe<Scalars['String']>;
  meds?: InputMaybe<Scalars['String']>;
  obgyn?: InputMaybe<Scalars['String']>;
  originalNotes?: InputMaybe<Scalars['String']>;
  pastMedicalHx?: InputMaybe<Scalars['String']>;
  revisedNotes?: InputMaybe<Scalars['String']>;
  screenings?: InputMaybe<Scalars['String']>;
  socialHx?: InputMaybe<Scalars['String']>;
  surgicalHx?: InputMaybe<Scalars['String']>;
  vaccine?: InputMaybe<Scalars['String']>;
};

export type AudioResult = {
  __typename?: 'AudioResult';
  packets?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  MedWarn: Scalars['String'];
  allergies: Scalars['String'];
  benchmarkId: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  discMeds: Scalars['String'];
  discPastMedicalHx: Scalars['String'];
  familyHx: Scalars['String'];
  habits: Scalars['String'];
  meds: Scalars['String'];
  obgyn: Scalars['String'];
  pastMedicalHx: Scalars['String'];
  socialHx: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  vaccine: Scalars['String'];
};

export type BenchmarkInput = {
  MedWarn: Scalars['String'];
  allergies: Scalars['String'];
  benchmarkId: Scalars['String'];
  discMeds: Scalars['String'];
  discPastMedicalHx: Scalars['String'];
  familyHx: Scalars['String'];
  habits: Scalars['String'];
  meds: Scalars['String'];
  obgyn: Scalars['String'];
  pastMedicalHx: Scalars['String'];
  socialHx: Scalars['String'];
  vaccine: Scalars['String'];
};

export type Clinic = {
  __typename?: 'Clinic';
  city?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<Array<Maybe<Patient>>>;
  province?: Maybe<Scalars['String']>;
};

export type ClinicWebUserInfo = {
  __typename?: 'ClinicWebUserInfo';
  active?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type HealthOrg = {
  __typename?: 'HealthOrg';
  demo?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  trainingOnly?: Maybe<Scalars['Boolean']>;
};

export type HealthOrgVisitDetails = {
  __typename?: 'HealthOrgVisitDetails';
  allergies?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  assocPhysician?: Maybe<Scalars['String']>;
  backPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  chiefComplaint?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  diagnosis?: Maybe<Scalars['String']>;
  drugs?: Maybe<Scalars['String']>;
  familyHx?: Maybe<Scalars['String']>;
  frontPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  hopi?: Maybe<Scalars['String']>;
  meds?: Maybe<Scalars['String']>;
  noteToPhysician?: Maybe<Scalars['String']>;
  obgyn?: Maybe<Scalars['String']>;
  originalNotes?: Maybe<Scalars['String']>;
  pastMedicalHx?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientIdType?: Maybe<Scalars['String']>;
  physician?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  reasonForComing?: Maybe<Scalars['String']>;
  revisedNotes?: Maybe<Scalars['String']>;
  screenings?: Maybe<Scalars['String']>;
  socialHx?: Maybe<Scalars['String']>;
  surgicalHx?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Array<Maybe<Scalars['String']>>>;
  transcription?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  vaccine?: Maybe<Scalars['String']>;
  visitId?: Maybe<Scalars['String']>;
  waitingFor?: Maybe<Scalars['String']>;
};

export type InitiateVisitInput = {
  assocPhysician?: InputMaybe<Scalars['String']>;
  chiefComplaint?: InputMaybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt: Scalars['Date'];
  noteToPhysician?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  reasonForComing?: InputMaybe<Scalars['String']>;
  urgency?: InputMaybe<Scalars['String']>;
  visitId: Scalars['String'];
  waitingFor?: InputMaybe<Scalars['String']>;
};

export type LabDisplay = {
  actualDate?: InputMaybe<Scalars['String']>;
  doctorNote?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  labId: Scalars['String'];
  labInformation?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  needRead: Scalars['Boolean'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  test: Scalars['String'];
};

export type LabOutput = {
  __typename?: 'LabOutput';
  actualDate?: Maybe<Scalars['Date']>;
  doctorNote?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  labId: Scalars['String'];
  labInformation?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  needRead: Scalars['Boolean'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  test: Scalars['String'];
};

export type Medication = {
  __typename?: 'Medication';
  dosage: Scalars['String'];
  medicationId: Scalars['String'];
  name: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  schedule?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status: Scalars['String'];
};

export type MedicationCreation = {
  dosage: Scalars['String'];
  medicationId: Scalars['String'];
  name: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  schedule?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type MedicationDay = {
  __typename?: 'MedicationDay';
  completions: Array<Maybe<MedicationDayCompletion>>;
  day: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type MedicationDayCompletion = {
  __typename?: 'MedicationDayCompletion';
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt: Scalars['String'];
};

export type MedicationDayCompletionInput = {
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt: Scalars['String'];
};

export type MedicationDayCompletionUpdate = {
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt?: InputMaybe<Scalars['String']>;
};

export type MedicationDayCreation = {
  completions?: InputMaybe<Array<InputMaybe<MedicationDayCompletionInput>>>;
  day: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type MedicationDayUpdate = {
  completionUpdates: Array<InputMaybe<MedicationDayCompletionUpdate>>;
  day: Scalars['String'];
};

export type Medicine = {
  __typename?: 'Medicine';
  Class?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  Number?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  Salt?: Maybe<Scalars['String']>;
  Trade_Name: Scalars['String'];
  Unit?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMedicationPhoto?: Maybe<Scalars['String']>;
  createBenchmark: Benchmark;
  createLab?: Maybe<LabOutput>;
  createMedication?: Maybe<Medication>;
  createMedicationDay?: Maybe<MedicationDay>;
  createPatient?: Maybe<Patient>;
  createRecording?: Maybe<Recording>;
  createRecordingArchive?: Maybe<Scalars['String']>;
  createSiteNote?: Maybe<SiteNote>;
  createVisit?: Maybe<Visit>;
  createVitals?: Maybe<Vitals>;
  createWebUser?: Maybe<WebUser>;
  deleteRecording?: Maybe<Scalars['Boolean']>;
  generateAssessment?: Maybe<Scalars['String']>;
  initiateVisit?: Maybe<Visit>;
  startTranscriptionSession?: Maybe<Scalars['String']>;
  transcribeAudio?: Maybe<Scalars['String']>;
  updateMedicationDay: Array<Maybe<MedicationDay>>;
  updatePatient?: Maybe<Patient>;
  updateRecordingNotes?: Maybe<Recording>;
  updateRecordingStatus?: Maybe<Recording>;
  updateVisitAssessment?: Maybe<Visit>;
  updateVisitDetails?: Maybe<Visit>;
  updateVisitProgress?: Maybe<Visit>;
};


export type MutationAddMedicationPhotoArgs = {
  patient: PatientId;
  photoId: PhotoId;
};


export type MutationCreateBenchmarkArgs = {
  benchmark: BenchmarkInput;
};


export type MutationCreateLabArgs = {
  lab: LabDisplay;
};


export type MutationCreateMedicationArgs = {
  medication: MedicationCreation;
};


export type MutationCreateMedicationDayArgs = {
  medicationDay: MedicationDayCreation;
};


export type MutationCreatePatientArgs = {
  patient: PatientCreation;
};


export type MutationCreateRecordingArgs = {
  recording: RecordingInput;
};


export type MutationCreateRecordingArchiveArgs = {
  archiveId: Scalars['String'];
  index: Scalars['Int'];
};


export type MutationCreateSiteNoteArgs = {
  note: SiteNoteInput;
};


export type MutationCreateVisitArgs = {
  clinicId: Scalars['String'];
  createdAt: Scalars['Date'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  visit: VisitInput;
  visitId: Scalars['String'];
};


export type MutationCreateVitalsArgs = {
  createdAt: Scalars['Date'];
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  vitals: VitalsInput;
};


export type MutationCreateWebUserArgs = {
  webUser: WebUserCreation;
};


export type MutationDeleteRecordingArgs = {
  recording: RecordingKeys;
};


export type MutationGenerateAssessmentArgs = {
  clinicId: Scalars['String'];
  sessionId: Scalars['String'];
  visitId?: InputMaybe<Scalars['String']>;
};


export type MutationInitiateVisitArgs = {
  visitInfo: InitiateVisitInput;
};


export type MutationStartTranscriptionSessionArgs = {
  visitId?: InputMaybe<Scalars['String']>;
};


export type MutationTranscribeAudioArgs = {
  audio: Scalars['String'];
  clinicId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
  traceId: Scalars['String'];
  visitId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMedicationDayArgs = {
  completionUpdates: Array<InputMaybe<MedicationDayUpdate>>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};


export type MutationUpdatePatientArgs = {
  patient: PatientUpdate;
};


export type MutationUpdateRecordingNotesArgs = {
  recording: RecordingKeys;
  updates: RecordingNotesUpdate;
};


export type MutationUpdateRecordingStatusArgs = {
  recording: RecordingKeys;
  status: RecordingStatusUpdate;
};


export type MutationUpdateVisitAssessmentArgs = {
  assessmentDetails: AssessmentInput;
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
};


export type MutationUpdateVisitDetailsArgs = {
  clinicId: Scalars['String'];
  visitDetails: VisitInput;
  visitId: Scalars['String'];
};


export type MutationUpdateVisitProgressArgs = {
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
  visitProgress: VisitProgress;
};

export type Patient = {
  __typename?: 'Patient';
  allergies?: Maybe<Array<Maybe<Scalars['String']>>>;
  birthday?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  labs?: Maybe<Array<Maybe<LabOutput>>>;
  lastName?: Maybe<Scalars['String']>;
  medicationDays?: Maybe<Array<Maybe<MedicationDay>>>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  photoDownloadUrl?: Maybe<Scalars['String']>;
  photoUploadUrl?: Maybe<Scalars['String']>;
};

export type PatientCreation = {
  allergies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  birthday: Scalars['Date'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PatientId = {
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PatientUpdate = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PhotoId = {
  photoId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allHealthOrgs?: Maybe<Array<Maybe<HealthOrg>>>;
  audioResults?: Maybe<AudioResult>;
  clinic?: Maybe<Clinic>;
  clinicsInHealthOrg: Array<Maybe<Clinic>>;
  healthOrgVisits: Array<Maybe<HealthOrgVisitDetails>>;
  patient?: Maybe<Patient>;
  patientsById?: Maybe<Array<Maybe<Patient>>>;
  recording: Recording;
  recordings: Array<Maybe<Recording>>;
  recordingsById: Array<Maybe<Recording>>;
  recordingsByPrefixId: Array<Maybe<Recording>>;
  siteNotes: Array<Maybe<SiteNote>>;
  siteNotesById: Array<Maybe<SiteNote>>;
  userForSession?: Maybe<WebUser>;
  usersForRole?: Maybe<Array<Maybe<Scalars['String']>>>;
  visit?: Maybe<Visit>;
  visits: Array<Maybe<Visit>>;
  vitals?: Maybe<Vitals>;
};


export type QueryAudioResultsArgs = {
  audioId: Scalars['String'];
};


export type QueryClinicArgs = {
  clinicId: Scalars['String'];
};


export type QueryClinicsInHealthOrgArgs = {
  orgId: Scalars['String'];
};


export type QueryHealthOrgVisitsArgs = {
  criteria?: InputMaybe<Array<VisitCriteria>>;
};


export type QueryPatientArgs = {
  id: Scalars['String'];
  patientIdType: Scalars['String'];
};


export type QueryPatientsByIdArgs = {
  ids: Array<PatientId>;
};


export type QueryRecordingArgs = {
  id: Scalars['String'];
};


export type QueryRecordingsArgs = {
  criteria: RecordingQueryInput;
};


export type QueryRecordingsByIdArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};


export type QueryRecordingsByPrefixIdArgs = {
  id: Scalars['String'];
};


export type QuerySiteNotesArgs = {
  criteria: SiteNoteQueryInput;
};


export type QuerySiteNotesByIdArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};


export type QueryUsersForRoleArgs = {
  role: Scalars['String'];
};


export type QueryVisitArgs = {
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
};


export type QueryVisitsArgs = {
  criteria: VisitCriteria;
};


export type QueryVitalsArgs = {
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type Recording = {
  __typename?: 'Recording';
  altTranscription?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  correctedTranscription?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  originalTranscription?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientIdType?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['Date']>;
  recordingId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type RecordingInput = {
  patientId?: InputMaybe<Scalars['String']>;
  patientIdType?: InputMaybe<Scalars['String']>;
  recordedAt: Scalars['Date'];
  recordingId: Scalars['String'];
  user: Scalars['String'];
};

export type RecordingKeys = {
  recordingId: Scalars['String'];
  user: Scalars['String'];
};

export type RecordingNotesUpdate = {
  altTranscription?: InputMaybe<Scalars['String']>;
  correctedTranscription?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  originalTranscription?: InputMaybe<Scalars['String']>;
};

export type RecordingQueryInput = {
  assignedTo?: InputMaybe<Scalars['String']>;
  recordedBy?: InputMaybe<Scalars['String']>;
  since?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RecordingStatusUpdate = {
  assignedTo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SiteNote = {
  __typename?: 'SiteNote';
  createdAt?: Maybe<Scalars['Date']>;
  downloadUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type SiteNoteInput = {
  createdAt: Scalars['Date'];
  filename: Scalars['String'];
  noteId: Scalars['String'];
  user: Scalars['String'];
};

export type SiteNoteQueryInput = {
  since?: InputMaybe<Scalars['Date']>;
};

export type Visit = {
  __typename?: 'Visit';
  a_p?: Maybe<Scalars['String']>;
  allergies?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  assocPhysician?: Maybe<Scalars['String']>;
  backPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  chiefComplaint?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  diagnoses?: Maybe<Array<Maybe<Scalars['String']>>>;
  drugs?: Maybe<Scalars['String']>;
  familyHx?: Maybe<Scalars['String']>;
  frontPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  hopi?: Maybe<Scalars['String']>;
  meds?: Maybe<Scalars['String']>;
  noteToPhysician?: Maybe<Scalars['String']>;
  obgyn?: Maybe<Scalars['String']>;
  originalNotes?: Maybe<Scalars['String']>;
  pastMedicalHx?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientIdType?: Maybe<Scalars['String']>;
  physician?: Maybe<WebUser>;
  questions?: Maybe<Scalars['String']>;
  reasonForComing?: Maybe<Scalars['String']>;
  revisedNotes?: Maybe<Scalars['String']>;
  screenings?: Maybe<Scalars['String']>;
  socialHx?: Maybe<Scalars['String']>;
  surgicalHx?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Array<Maybe<Scalars['String']>>>;
  transcription?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  vaccine?: Maybe<Scalars['String']>;
  visitId: Scalars['String'];
  waitingFor?: Maybe<Scalars['String']>;
};

export type VisitCriteria = {
  clinicId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientIdType?: InputMaybe<Scalars['String']>;
  physician?: InputMaybe<Scalars['String']>;
  since?: InputMaybe<Scalars['Date']>;
  waitingFor?: InputMaybe<Scalars['String']>;
};

export type VisitInput = {
  chiefComplaint?: InputMaybe<Scalars['String']>;
  noteToPhysician?: InputMaybe<Scalars['String']>;
  reasonForComing?: InputMaybe<Scalars['String']>;
  revisedNotes?: InputMaybe<Scalars['String']>;
};

export type VisitProgress = {
  assocPhysician?: InputMaybe<Scalars['String']>;
  nextStep?: InputMaybe<Scalars['String']>;
  urgency?: InputMaybe<Scalars['String']>;
};

export type Vitals = {
  __typename?: 'Vitals';
  backPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bloodOxygen?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  frontPainPoints?: Maybe<Array<Maybe<Scalars['Int']>>>;
  heartRate?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  respiratoryRate?: Maybe<Scalars['Int']>;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Int']>;
};

export type VitalsInput = {
  backPainPoints?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  bloodOxygen?: InputMaybe<Scalars['Int']>;
  diastolicBloodPressure?: InputMaybe<Scalars['Int']>;
  frontPainPoints?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  heartRate?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  respiratoryRate?: InputMaybe<Scalars['Int']>;
  systolicBloodPressure?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type WebUser = {
  __typename?: 'WebUser';
  clinics?: Maybe<Array<Maybe<ClinicWebUserInfo>>>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  healthOrg?: Maybe<HealthOrg>;
  orgId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type WebUserCreation = {
  email: Scalars['String'];
  externalId: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
};

export type WebUserInput = {
  organization?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AllHealthOrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllHealthOrgsQuery = { __typename?: 'Query', allHealthOrgs?: Array<{ __typename?: 'HealthOrg', orgId?: string | null, name?: string | null } | null> | null };

export type AudioResultsQueryVariables = Exact<{
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
}>;


export type AudioResultsQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', transcription?: string | null, questions?: string | null, originalNotes?: string | null, patientId?: string | null, patientIdType?: string | null, diagnoses?: Array<string | null> | null, assessment?: string | null, allergies?: string | null, drugs?: string | null, hopi?: string | null, meds?: string | null, obgyn?: string | null, screenings?: string | null, socialHx?: string | null, surgicalHx?: string | null, vaccine?: string | null, pastMedicalHx?: string | null, a_p?: string | null, familyHx?: string | null, symptoms?: Array<string | null> | null } | null };

export type BenchmarkSavingMutationVariables = Exact<{
  benchmark: BenchmarkInput;
}>;


export type BenchmarkSavingMutation = { __typename?: 'Mutation', createBenchmark: { __typename?: 'Benchmark', benchmarkId: string, socialHx: string, familyHx: string, meds: string, allergies: string, habits: string, discMeds: string, MedWarn: string, pastMedicalHx: string, discPastMedicalHx: string, vaccine: string, obgyn: string } };

export type ClinicByIdQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type ClinicByIdQuery = { __typename?: 'Query', clinic?: { __typename?: 'Clinic', clinicId: string, name?: string | null, country?: string | null, province?: string | null, city?: string | null } | null };

export type ClinicPatientsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type ClinicPatientsQuery = { __typename?: 'Query', clinic?: { __typename?: 'Clinic', patients?: Array<{ __typename?: 'Patient', patientId: string, patientIdType: string, firstName?: string | null, lastName?: string | null } | null> | null } | null };

export type ClinicsInHealthOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type ClinicsInHealthOrgQuery = { __typename?: 'Query', clinicsInHealthOrg: Array<{ __typename?: 'Clinic', clinicId: string, name?: string | null } | null> };

export type CreatePatientMutationVariables = Exact<{
  patient: PatientCreation;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient?: { __typename?: 'Patient', patientId: string, patientIdType: string } | null };

export type CreateRecordingMutationVariables = Exact<{
  recording: RecordingInput;
}>;


export type CreateRecordingMutation = { __typename?: 'Mutation', createRecording?: { __typename?: 'Recording', uploadUrl?: string | null } | null };

export type CreateRecordingArchiveMutationVariables = Exact<{
  archiveId: Scalars['String'];
  index: Scalars['Int'];
}>;


export type CreateRecordingArchiveMutation = { __typename?: 'Mutation', createRecordingArchive?: string | null };

export type CreateSiteNoteMutationVariables = Exact<{
  siteNote: SiteNoteInput;
}>;


export type CreateSiteNoteMutation = { __typename?: 'Mutation', createSiteNote?: { __typename?: 'SiteNote', uploadUrl?: string | null } | null };

export type CreateWebUserMutationVariables = Exact<{
  webUser: WebUserCreation;
}>;


export type CreateWebUserMutation = { __typename?: 'Mutation', createWebUser?: { __typename?: 'WebUser', email?: string | null } | null };

export type DeleteRecordingMutationVariables = Exact<{
  recordingKeys: RecordingKeys;
}>;


export type DeleteRecordingMutation = { __typename?: 'Mutation', deleteRecording?: boolean | null };

export type GenerateAssessmentMutationVariables = Exact<{
  clinicId: Scalars['String'];
  visitId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
}>;


export type GenerateAssessmentMutation = { __typename?: 'Mutation', generateAssessment?: string | null };

export type InitiateVisitMutationVariables = Exact<{
  visitInfo: InitiateVisitInput;
}>;


export type InitiateVisitMutation = { __typename?: 'Mutation', initiateVisit?: { __typename?: 'Visit', patientId?: string | null, patientIdType?: string | null, visitId: string, urgency?: string | null, waitingFor?: string | null, assocPhysician?: string | null } | null };

export type PatientQueryVariables = Exact<{
  id: Scalars['String'];
  patientIdType: Scalars['String'];
}>;


export type PatientQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', patientId: string, patientIdType: string, firstName?: string | null, lastName?: string | null, birthday?: any | null } | null };

export type PatientUploadPhotoQueryVariables = Exact<{
  id: Scalars['String'];
  patientIdType: Scalars['String'];
}>;


export type PatientUploadPhotoQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', photoUploadUrl?: string | null } | null };

export type PatientsByIdQueryVariables = Exact<{
  ids: Array<PatientId> | PatientId;
}>;


export type PatientsByIdQuery = { __typename?: 'Query', patientsById?: Array<{ __typename?: 'Patient', firstName?: string | null, lastName?: string | null, patientId: string, patientIdType: string } | null> | null };

export type PatientMedicationQueryVariables = Exact<{
  id: Scalars['String'];
  patientIdType: Scalars['String'];
}>;


export type PatientMedicationQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', medications?: Array<{ __typename?: 'Medication', medicationId: string, name: string, dosage: string, status: string, schedule?: Array<number | null> | null } | null> | null } | null };

export type PatientUpdateMutationVariables = Exact<{
  patient: PatientUpdate;
}>;


export type PatientUpdateMutation = { __typename?: 'Mutation', updatePatient?: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null } | null };

export type RecordingsByCriteriaQueryVariables = Exact<{
  criteria: RecordingQueryInput;
}>;


export type RecordingsByCriteriaQuery = { __typename?: 'Query', recordings: Array<{ __typename?: 'Recording', recordingId?: string | null, recordedAt?: any | null, user?: string | null, patientId?: string | null, status?: string | null, assignedTo?: string | null, originalTranscription?: string | null, correctedTranscription?: string | null, altTranscription?: string | null, notes?: string | null } | null> };

export type RecordingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecordingQuery = { __typename?: 'Query', recording: { __typename?: 'Recording', downloadUrl?: string | null } };

export type RecordingsByIdQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type RecordingsByIdQuery = { __typename?: 'Query', recordingsById: Array<{ __typename?: 'Recording', downloadUrl?: string | null } | null> };

export type RecordingsByPrefixIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecordingsByPrefixIdQuery = { __typename?: 'Query', recordingsByPrefixId: Array<{ __typename?: 'Recording', downloadUrl?: string | null } | null> };

export type SiteNotesQueryVariables = Exact<{
  criteria: SiteNoteQueryInput;
}>;


export type SiteNotesQuery = { __typename?: 'Query', siteNotes: Array<{ __typename?: 'SiteNote', filename?: string | null, noteId?: string | null, user?: string | null, createdAt?: any | null } | null> };

export type SiteNotesByIdQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type SiteNotesByIdQuery = { __typename?: 'Query', siteNotesById: Array<{ __typename?: 'SiteNote', downloadUrl?: string | null } | null> };

export type StartTranscriptionSessionMutationVariables = Exact<{
  visitId?: InputMaybe<Scalars['String']>;
}>;


export type StartTranscriptionSessionMutation = { __typename?: 'Mutation', startTranscriptionSession?: string | null };

export type TranscribeAudioMutationVariables = Exact<{
  clinicId?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
  traceId: Scalars['String'];
  audio: Scalars['String'];
}>;


export type TranscribeAudioMutation = { __typename?: 'Mutation', transcribeAudio?: string | null };

export type UpdateRecordingStatusMutationVariables = Exact<{
  keys: RecordingKeys;
  update: RecordingStatusUpdate;
}>;


export type UpdateRecordingStatusMutation = { __typename?: 'Mutation', updateRecordingStatus?: { __typename?: 'Recording', status?: string | null, assignedTo?: string | null } | null };

export type UpdateRecordingNotesMutationVariables = Exact<{
  keys: RecordingKeys;
  update: RecordingNotesUpdate;
}>;


export type UpdateRecordingNotesMutation = { __typename?: 'Mutation', updateRecordingNotes?: { __typename?: 'Recording', originalTranscription?: string | null, correctedTranscription?: string | null, altTranscription?: string | null, notes?: string | null } | null };

export type UpdateVisitAssessmentMutationVariables = Exact<{
  visitId: Scalars['String'];
  clinicId: Scalars['String'];
  assessment: AssessmentInput;
}>;


export type UpdateVisitAssessmentMutation = { __typename?: 'Mutation', updateVisitAssessment?: { __typename?: 'Visit', patientId?: string | null, patientIdType?: string | null, assessment?: string | null } | null };

export type UpdateVisitDetailsMutationVariables = Exact<{
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  createdAt: Scalars['Date'];
  visitId: Scalars['String'];
  clinicId: Scalars['String'];
  visit: VisitInput;
  vitals: VitalsInput;
}>;


export type UpdateVisitDetailsMutation = { __typename?: 'Mutation', updateVisitDetails?: { __typename?: 'Visit', patientId?: string | null, patientIdType?: string | null, visitId: string, chiefComplaint?: string | null, noteToPhysician?: string | null, reasonForComing?: string | null } | null, createVitals?: { __typename?: 'Vitals', patientId: string, patientIdType: string, eventId: string, eventType: string, height?: number | null, weight?: number | null, temperature?: number | null, heartRate?: number | null, respiratoryRate?: number | null, bloodOxygen?: number | null, systolicBloodPressure?: number | null, diastolicBloodPressure?: number | null, frontPainPoints?: Array<number | null> | null, backPainPoints?: Array<number | null> | null } | null };

export type UpdateVisitNotesMutationVariables = Exact<{
  visitId: Scalars['String'];
  clinicId: Scalars['String'];
  visit: VisitInput;
}>;


export type UpdateVisitNotesMutation = { __typename?: 'Mutation', updateVisitDetails?: { __typename?: 'Visit', patientId?: string | null, patientIdType?: string | null } | null };

export type UpdateVisitProgressMutationVariables = Exact<{
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
  visitProgress: VisitProgress;
}>;


export type UpdateVisitProgressMutation = { __typename?: 'Mutation', updateVisitProgress?: { __typename?: 'Visit', patientId?: string | null, patientIdType?: string | null, visitId: string, urgency?: string | null, waitingFor?: string | null, assocPhysician?: string | null } | null };

export type UserForSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserForSessionQuery = { __typename?: 'Query', userForSession?: { __typename?: 'WebUser', email?: string | null, givenName?: string | null, familyName?: string | null, role?: string | null, externalId?: string | null, clinics?: Array<{ __typename?: 'ClinicWebUserInfo', clinicId?: string | null, orgId?: string | null, name?: string | null, country?: string | null, province?: string | null, city?: string | null, active?: boolean | null } | null> | null, healthOrg?: { __typename?: 'HealthOrg', orgId?: string | null, trainingOnly?: boolean | null, demo?: boolean | null } | null } | null };

export type UsersForRoleQueryVariables = Exact<{
  role: Scalars['String'];
}>;


export type UsersForRoleQuery = { __typename?: 'Query', usersForRole?: Array<string | null> | null };

export type VisitByIdQueryVariables = Exact<{
  clinicId: Scalars['String'];
  visitId: Scalars['String'];
}>;


export type VisitByIdQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', clinicId?: string | null, patientId?: string | null, visitId: string, chiefComplaint?: string | null, reasonForComing?: string | null, noteToPhysician?: string | null } | null };

export type VisitsQueryVariables = Exact<{
  criteria: VisitCriteria;
}>;


export type VisitsQuery = { __typename?: 'Query', visits: Array<{ __typename?: 'Visit', visitId: string, clinicId?: string | null, transcription?: string | null, originalNotes?: string | null, patientId?: string | null, patientIdType?: string | null, allergies?: string | null, drugs?: string | null, hopi?: string | null, meds?: string | null, obgyn?: string | null, screenings?: string | null, socialHx?: string | null, surgicalHx?: string | null, vaccine?: string | null, pastMedicalHx?: string | null, a_p?: string | null, familyHx?: string | null, createdAt?: any | null, assocPhysician?: string | null, physician?: { __typename?: 'WebUser', email?: string | null, familyName?: string | null, givenName?: string | null } | null } | null> };

export type VitalsQueryVariables = Exact<{
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  eventType: Scalars['String'];
  eventId: Scalars['String'];
}>;


export type VitalsQuery = { __typename?: 'Query', vitals?: { __typename?: 'Vitals', height?: number | null, weight?: number | null, temperature?: number | null, systolicBloodPressure?: number | null, diastolicBloodPressure?: number | null, heartRate?: number | null, respiratoryRate?: number | null, bloodOxygen?: number | null, eventId: string, eventType: string, patientId: string, patientIdType: string, frontPainPoints?: Array<number | null> | null, backPainPoints?: Array<number | null> | null } | null };


export const AllHealthOrgsDocument = `
    query allHealthOrgs {
  allHealthOrgs {
    orgId
    name
  }
}
    `;
export const AudioResultsDocument = `
    query audioResults($clinicId: String!, $visitId: String!) {
  visit(clinicId: $clinicId, visitId: $visitId) {
    transcription
    questions
    originalNotes
    patientId
    patientIdType
    diagnoses
    assessment
    allergies
    drugs
    hopi
    meds
    obgyn
    screenings
    socialHx
    surgicalHx
    vaccine
    pastMedicalHx
    a_p
    familyHx
    symptoms
  }
}
    `;
export const BenchmarkSavingDocument = `
    mutation benchmarkSaving($benchmark: BenchmarkInput!) {
  createBenchmark(benchmark: $benchmark) {
    benchmarkId
    socialHx
    familyHx
    meds
    allergies
    habits
    discMeds
    MedWarn
    pastMedicalHx
    discPastMedicalHx
    vaccine
    obgyn
  }
}
    `;
export const ClinicByIdDocument = `
    query clinicById($clinicId: String!) {
  clinic(clinicId: $clinicId) {
    clinicId
    name
    country
    province
    city
  }
}
    `;
export const ClinicPatientsDocument = `
    query clinicPatients($clinicId: String!) {
  clinic(clinicId: $clinicId) {
    patients {
      patientId
      patientIdType
      firstName
      lastName
    }
  }
}
    `;
export const ClinicsInHealthOrgDocument = `
    query clinicsInHealthOrg($orgId: String!) {
  clinicsInHealthOrg(orgId: $orgId) {
    clinicId
    name
  }
}
    `;
export const CreatePatientDocument = `
    mutation createPatient($patient: PatientCreation!) {
  createPatient(patient: $patient) {
    patientId
    patientIdType
  }
}
    `;
export const CreateRecordingDocument = `
    mutation createRecording($recording: RecordingInput!) {
  createRecording(recording: $recording) {
    uploadUrl
  }
}
    `;
export const CreateRecordingArchiveDocument = `
    mutation createRecordingArchive($archiveId: String!, $index: Int!) {
  createRecordingArchive(archiveId: $archiveId, index: $index)
}
    `;
export const CreateSiteNoteDocument = `
    mutation createSiteNote($siteNote: SiteNoteInput!) {
  createSiteNote(note: $siteNote) {
    uploadUrl
  }
}
    `;
export const CreateWebUserDocument = `
    mutation createWebUser($webUser: WebUserCreation!) {
  createWebUser(webUser: $webUser) {
    email
  }
}
    `;
export const DeleteRecordingDocument = `
    mutation deleteRecording($recordingKeys: RecordingKeys!) {
  deleteRecording(recording: $recordingKeys)
}
    `;
export const GenerateAssessmentDocument = `
    mutation generateAssessment($clinicId: String!, $visitId: String, $sessionId: String!) {
  generateAssessment(
    clinicId: $clinicId
    visitId: $visitId
    sessionId: $sessionId
  )
}
    `;
export const InitiateVisitDocument = `
    mutation initiateVisit($visitInfo: InitiateVisitInput!) {
  initiateVisit(visitInfo: $visitInfo) {
    patientId
    patientIdType
    visitId
    urgency
    waitingFor
    assocPhysician
  }
}
    `;
export const PatientDocument = `
    query patient($id: String!, $patientIdType: String!) {
  patient(id: $id, patientIdType: $patientIdType) {
    patientId
    patientIdType
    firstName
    lastName
    birthday
    patientId
    patientIdType
  }
}
    `;
export const PatientUploadPhotoDocument = `
    query patientUploadPhoto($id: String!, $patientIdType: String!) {
  patient(id: $id, patientIdType: $patientIdType) {
    photoUploadUrl
  }
}
    `;
export const PatientsByIdDocument = `
    query patientsById($ids: [PatientId!]!) {
  patientsById(ids: $ids) {
    firstName
    lastName
    patientId
    patientIdType
  }
}
    `;
export const PatientMedicationDocument = `
    query patientMedication($id: String!, $patientIdType: String!) {
  patient(id: $id, patientIdType: $patientIdType) {
    medications {
      medicationId
      name
      dosage
      status
      schedule
    }
  }
}
    `;
export const PatientUpdateDocument = `
    mutation patientUpdate($patient: PatientUpdate!) {
  updatePatient(patient: $patient) {
    firstName
    lastName
  }
}
    `;
export const RecordingsByCriteriaDocument = `
    query recordingsByCriteria($criteria: RecordingQueryInput!) {
  recordings(criteria: $criteria) {
    recordingId
    recordedAt
    user
    patientId
    status
    assignedTo
    originalTranscription
    correctedTranscription
    altTranscription
    notes
  }
}
    `;
export const RecordingDocument = `
    query recording($id: String!) {
  recording(id: $id) {
    downloadUrl
  }
}
    `;
export const RecordingsByIdDocument = `
    query recordingsById($ids: [String]!) {
  recordingsById(ids: $ids) {
    downloadUrl
  }
}
    `;
export const RecordingsByPrefixIdDocument = `
    query recordingsByPrefixId($id: String!) {
  recordingsByPrefixId(id: $id) {
    downloadUrl
  }
}
    `;
export const SiteNotesDocument = `
    query siteNotes($criteria: SiteNoteQueryInput!) {
  siteNotes(criteria: $criteria) {
    filename
    noteId
    user
    createdAt
  }
}
    `;
export const SiteNotesByIdDocument = `
    query siteNotesById($ids: [String]!) {
  siteNotesById(ids: $ids) {
    downloadUrl
  }
}
    `;
export const StartTranscriptionSessionDocument = `
    mutation startTranscriptionSession($visitId: String) {
  startTranscriptionSession(visitId: $visitId)
}
    `;
export const TranscribeAudioDocument = `
    mutation transcribeAudio($clinicId: String, $visitId: String, $sessionId: String!, $traceId: String!, $audio: String!) {
  transcribeAudio(
    clinicId: $clinicId
    visitId: $visitId
    sessionId: $sessionId
    traceId: $traceId
    audio: $audio
  )
}
    `;
export const UpdateRecordingStatusDocument = `
    mutation updateRecordingStatus($keys: RecordingKeys!, $update: RecordingStatusUpdate!) {
  updateRecordingStatus(recording: $keys, status: $update) {
    status
    assignedTo
  }
}
    `;
export const UpdateRecordingNotesDocument = `
    mutation updateRecordingNotes($keys: RecordingKeys!, $update: RecordingNotesUpdate!) {
  updateRecordingNotes(recording: $keys, updates: $update) {
    originalTranscription
    correctedTranscription
    altTranscription
    notes
  }
}
    `;
export const UpdateVisitAssessmentDocument = `
    mutation updateVisitAssessment($visitId: String!, $clinicId: String!, $assessment: AssessmentInput!) {
  updateVisitAssessment(
    visitId: $visitId
    clinicId: $clinicId
    assessmentDetails: $assessment
  ) {
    patientId
    patientIdType
    assessment
  }
}
    `;
export const UpdateVisitDetailsDocument = `
    mutation updateVisitDetails($patientId: String!, $patientIdType: String!, $createdAt: Date!, $visitId: String!, $clinicId: String!, $visit: VisitInput!, $vitals: VitalsInput!) {
  updateVisitDetails(visitId: $visitId, clinicId: $clinicId, visitDetails: $visit) {
    patientId
    patientIdType
    visitId
    chiefComplaint
    noteToPhysician
    reasonForComing
  }
  createVitals(
    patientId: $patientId
    patientIdType: $patientIdType
    eventId: $visitId
    eventType: "V"
    createdAt: $createdAt
    vitals: $vitals
  ) {
    patientId
    patientIdType
    eventId
    eventType
    height
    weight
    temperature
    heartRate
    respiratoryRate
    bloodOxygen
    systolicBloodPressure
    diastolicBloodPressure
    frontPainPoints
    backPainPoints
  }
}
    `;
export const UpdateVisitNotesDocument = `
    mutation updateVisitNotes($visitId: String!, $clinicId: String!, $visit: VisitInput!) {
  updateVisitDetails(visitId: $visitId, clinicId: $clinicId, visitDetails: $visit) {
    patientId
    patientIdType
  }
}
    `;
export const UpdateVisitProgressDocument = `
    mutation updateVisitProgress($clinicId: String!, $visitId: String!, $visitProgress: VisitProgress!) {
  updateVisitProgress(
    clinicId: $clinicId
    visitId: $visitId
    visitProgress: $visitProgress
  ) {
    patientId
    patientIdType
    visitId
    urgency
    waitingFor
    assocPhysician
  }
}
    `;
export const UserForSessionDocument = `
    query userForSession {
  userForSession {
    email
    givenName
    familyName
    role
    externalId
    clinics {
      clinicId
      orgId
      name
      country
      province
      city
      active
    }
    healthOrg {
      orgId
      trainingOnly
      demo
    }
  }
}
    `;
export const UsersForRoleDocument = `
    query usersForRole($role: String!) {
  usersForRole(role: $role)
}
    `;
export const VisitByIdDocument = `
    query visitById($clinicId: String!, $visitId: String!) {
  visit(visitId: $visitId, clinicId: $clinicId) {
    clinicId
    patientId
    visitId
    chiefComplaint
    reasonForComing
    noteToPhysician
  }
}
    `;
export const VisitsDocument = `
    query visits($criteria: VisitCriteria!) {
  visits(criteria: $criteria) {
    visitId
    clinicId
    transcription
    originalNotes
    patientId
    patientIdType
    allergies
    drugs
    hopi
    meds
    obgyn
    screenings
    socialHx
    surgicalHx
    vaccine
    pastMedicalHx
    a_p
    familyHx
    createdAt
    assocPhysician
    physician {
      email
      familyName
      givenName
    }
  }
}
    `;
export const VitalsDocument = `
    query vitals($patientId: String!, $patientIdType: String!, $eventType: String!, $eventId: String!) {
  vitals(
    patientId: $patientId
    patientIdType: $patientIdType
    eventType: $eventType
    eventId: $eventId
  ) {
    height
    weight
    temperature
    systolicBloodPressure
    diastolicBloodPressure
    heartRate
    respiratoryRate
    bloodOxygen
    eventId
    eventType
    patientId
    patientIdType
    frontPainPoints
    backPainPoints
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    allHealthOrgs: build.query<AllHealthOrgsQuery, AllHealthOrgsQueryVariables | void>({
      query: (variables) => ({ document: AllHealthOrgsDocument, variables })
    }),
    audioResults: build.query<AudioResultsQuery, AudioResultsQueryVariables>({
      query: (variables) => ({ document: AudioResultsDocument, variables })
    }),
    benchmarkSaving: build.mutation<BenchmarkSavingMutation, BenchmarkSavingMutationVariables>({
      query: (variables) => ({ document: BenchmarkSavingDocument, variables })
    }),
    clinicById: build.query<ClinicByIdQuery, ClinicByIdQueryVariables>({
      query: (variables) => ({ document: ClinicByIdDocument, variables })
    }),
    clinicPatients: build.query<ClinicPatientsQuery, ClinicPatientsQueryVariables>({
      query: (variables) => ({ document: ClinicPatientsDocument, variables })
    }),
    clinicsInHealthOrg: build.query<ClinicsInHealthOrgQuery, ClinicsInHealthOrgQueryVariables>({
      query: (variables) => ({ document: ClinicsInHealthOrgDocument, variables })
    }),
    createPatient: build.mutation<CreatePatientMutation, CreatePatientMutationVariables>({
      query: (variables) => ({ document: CreatePatientDocument, variables })
    }),
    createRecording: build.mutation<CreateRecordingMutation, CreateRecordingMutationVariables>({
      query: (variables) => ({ document: CreateRecordingDocument, variables })
    }),
    createRecordingArchive: build.mutation<CreateRecordingArchiveMutation, CreateRecordingArchiveMutationVariables>({
      query: (variables) => ({ document: CreateRecordingArchiveDocument, variables })
    }),
    createSiteNote: build.mutation<CreateSiteNoteMutation, CreateSiteNoteMutationVariables>({
      query: (variables) => ({ document: CreateSiteNoteDocument, variables })
    }),
    createWebUser: build.mutation<CreateWebUserMutation, CreateWebUserMutationVariables>({
      query: (variables) => ({ document: CreateWebUserDocument, variables })
    }),
    deleteRecording: build.mutation<DeleteRecordingMutation, DeleteRecordingMutationVariables>({
      query: (variables) => ({ document: DeleteRecordingDocument, variables })
    }),
    generateAssessment: build.mutation<GenerateAssessmentMutation, GenerateAssessmentMutationVariables>({
      query: (variables) => ({ document: GenerateAssessmentDocument, variables })
    }),
    initiateVisit: build.mutation<InitiateVisitMutation, InitiateVisitMutationVariables>({
      query: (variables) => ({ document: InitiateVisitDocument, variables })
    }),
    patient: build.query<PatientQuery, PatientQueryVariables>({
      query: (variables) => ({ document: PatientDocument, variables })
    }),
    patientUploadPhoto: build.query<PatientUploadPhotoQuery, PatientUploadPhotoQueryVariables>({
      query: (variables) => ({ document: PatientUploadPhotoDocument, variables })
    }),
    patientsById: build.query<PatientsByIdQuery, PatientsByIdQueryVariables>({
      query: (variables) => ({ document: PatientsByIdDocument, variables })
    }),
    patientMedication: build.query<PatientMedicationQuery, PatientMedicationQueryVariables>({
      query: (variables) => ({ document: PatientMedicationDocument, variables })
    }),
    patientUpdate: build.mutation<PatientUpdateMutation, PatientUpdateMutationVariables>({
      query: (variables) => ({ document: PatientUpdateDocument, variables })
    }),
    recordingsByCriteria: build.query<RecordingsByCriteriaQuery, RecordingsByCriteriaQueryVariables>({
      query: (variables) => ({ document: RecordingsByCriteriaDocument, variables })
    }),
    recording: build.query<RecordingQuery, RecordingQueryVariables>({
      query: (variables) => ({ document: RecordingDocument, variables })
    }),
    recordingsById: build.query<RecordingsByIdQuery, RecordingsByIdQueryVariables>({
      query: (variables) => ({ document: RecordingsByIdDocument, variables })
    }),
    recordingsByPrefixId: build.query<RecordingsByPrefixIdQuery, RecordingsByPrefixIdQueryVariables>({
      query: (variables) => ({ document: RecordingsByPrefixIdDocument, variables })
    }),
    siteNotes: build.query<SiteNotesQuery, SiteNotesQueryVariables>({
      query: (variables) => ({ document: SiteNotesDocument, variables })
    }),
    siteNotesById: build.query<SiteNotesByIdQuery, SiteNotesByIdQueryVariables>({
      query: (variables) => ({ document: SiteNotesByIdDocument, variables })
    }),
    startTranscriptionSession: build.mutation<StartTranscriptionSessionMutation, StartTranscriptionSessionMutationVariables | void>({
      query: (variables) => ({ document: StartTranscriptionSessionDocument, variables })
    }),
    transcribeAudio: build.mutation<TranscribeAudioMutation, TranscribeAudioMutationVariables>({
      query: (variables) => ({ document: TranscribeAudioDocument, variables })
    }),
    updateRecordingStatus: build.mutation<UpdateRecordingStatusMutation, UpdateRecordingStatusMutationVariables>({
      query: (variables) => ({ document: UpdateRecordingStatusDocument, variables })
    }),
    updateRecordingNotes: build.mutation<UpdateRecordingNotesMutation, UpdateRecordingNotesMutationVariables>({
      query: (variables) => ({ document: UpdateRecordingNotesDocument, variables })
    }),
    updateVisitAssessment: build.mutation<UpdateVisitAssessmentMutation, UpdateVisitAssessmentMutationVariables>({
      query: (variables) => ({ document: UpdateVisitAssessmentDocument, variables })
    }),
    updateVisitDetails: build.mutation<UpdateVisitDetailsMutation, UpdateVisitDetailsMutationVariables>({
      query: (variables) => ({ document: UpdateVisitDetailsDocument, variables })
    }),
    updateVisitNotes: build.mutation<UpdateVisitNotesMutation, UpdateVisitNotesMutationVariables>({
      query: (variables) => ({ document: UpdateVisitNotesDocument, variables })
    }),
    updateVisitProgress: build.mutation<UpdateVisitProgressMutation, UpdateVisitProgressMutationVariables>({
      query: (variables) => ({ document: UpdateVisitProgressDocument, variables })
    }),
    userForSession: build.query<UserForSessionQuery, UserForSessionQueryVariables | void>({
      query: (variables) => ({ document: UserForSessionDocument, variables })
    }),
    usersForRole: build.query<UsersForRoleQuery, UsersForRoleQueryVariables>({
      query: (variables) => ({ document: UsersForRoleDocument, variables })
    }),
    visitById: build.query<VisitByIdQuery, VisitByIdQueryVariables>({
      query: (variables) => ({ document: VisitByIdDocument, variables })
    }),
    visits: build.query<VisitsQuery, VisitsQueryVariables>({
      query: (variables) => ({ document: VisitsDocument, variables })
    }),
    vitals: build.query<VitalsQuery, VitalsQueryVariables>({
      query: (variables) => ({ document: VitalsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAllHealthOrgsQuery, useLazyAllHealthOrgsQuery, useAudioResultsQuery, useLazyAudioResultsQuery, useBenchmarkSavingMutation, useClinicByIdQuery, useLazyClinicByIdQuery, useClinicPatientsQuery, useLazyClinicPatientsQuery, useClinicsInHealthOrgQuery, useLazyClinicsInHealthOrgQuery, useCreatePatientMutation, useCreateRecordingMutation, useCreateRecordingArchiveMutation, useCreateSiteNoteMutation, useCreateWebUserMutation, useDeleteRecordingMutation, useGenerateAssessmentMutation, useInitiateVisitMutation, usePatientQuery, useLazyPatientQuery, usePatientUploadPhotoQuery, useLazyPatientUploadPhotoQuery, usePatientsByIdQuery, useLazyPatientsByIdQuery, usePatientMedicationQuery, useLazyPatientMedicationQuery, usePatientUpdateMutation, useRecordingsByCriteriaQuery, useLazyRecordingsByCriteriaQuery, useRecordingQuery, useLazyRecordingQuery, useRecordingsByIdQuery, useLazyRecordingsByIdQuery, useRecordingsByPrefixIdQuery, useLazyRecordingsByPrefixIdQuery, useSiteNotesQuery, useLazySiteNotesQuery, useSiteNotesByIdQuery, useLazySiteNotesByIdQuery, useStartTranscriptionSessionMutation, useTranscribeAudioMutation, useUpdateRecordingStatusMutation, useUpdateRecordingNotesMutation, useUpdateVisitAssessmentMutation, useUpdateVisitDetailsMutation, useUpdateVisitNotesMutation, useUpdateVisitProgressMutation, useUserForSessionQuery, useLazyUserForSessionQuery, useUsersForRoleQuery, useLazyUsersForRoleQuery, useVisitByIdQuery, useLazyVisitByIdQuery, useVisitsQuery, useLazyVisitsQuery, useVitalsQuery, useLazyVitalsQuery } = injectedRtkApi;

