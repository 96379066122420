import { FamilyHxAttributes } from "../components/v2/Bins/FamilyHx/FamilyHx";
import { MedConditionAttributes } from "../components/v2/Bins/FamilyHx/MedConditionView";
import { FamilyHxIgnoreAttributes } from "./constants";

export const CheckFamilyHxIncomplete = (
  FamilyHistory: FamilyHxAttributes
): boolean => {
  let incomplete = false;
  (Object.keys(FamilyHistory) as (keyof FamilyHxAttributes)[]).forEach(
    (key) => {
      if (FamilyHxIgnoreAttributes.includes(key)) {
        return;
      }
      if (key === "Age_at_death" && FamilyHistory.Status !== "Deceased") {
        return;
      }
      if (
        FamilyHistory[key] === "" ||
        FamilyHistory[key] === null ||
        FamilyHistory[key] === undefined
      ) {
        incomplete = true;
      }
    }
  );
  return incomplete;
};

export const CheckMedicalConditionIncomplete = (
  MedCondition: MedConditionAttributes
): boolean => {
  let incomplete = false;
  if (
    MedCondition.Name === "" ||
    MedCondition.Name === null ||
    MedCondition.Name === undefined ||
    MedCondition.Age === "" ||
    MedCondition.Age === null ||
    MedCondition.Age === undefined
  ) {
    incomplete = true;
  }
  return incomplete;
};
