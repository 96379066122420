import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Button } from "@mui/material";
import { RootState } from "../../../../state/reducers";
import { SocialHxFormatters } from "../../../../utils/socialHxFormatters";
import { setField } from "../../../../state/SocialHxSlice";
import { DeleteConfirmation } from "../../../DialgueBoxes";
import { commonStyles } from "../../../../constants/StyleConstants";
import { ExerciseAttributes } from "./SocialHx";
import { SocialHxEditTitle } from "./Edits";
import { EditProps } from "./Edits";
import { ExerciseItem } from "./ExerciseItem";

export const ExerciseEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Exercise } = useSelector((state: RootState) => state.socialHx);
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.getExerciseInfo(Exercise);

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState<string>("");

  const handleExerciseChange = (
    index: number,
    field: keyof ExerciseAttributes,
    value: string
  ) => {
    const updatedExercises = [...Exercise];
    updatedExercises[index] = {
      ...updatedExercises[index],
      [field]: value,
    };
    dispatch(
      setField({
        field: "Exercise",
        value: updatedExercises,
      })
    );
  };

  const handleDeleteRequest = (index: number, name: string) => {
    setDeleteIndex(index);
    setDeleteName(name);
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setDeleteIndex(null);
    setDeleteName("");
  };

  const handleAddExercise = () => {
    const newExercise: ExerciseAttributes = {
      Exercise_Name: "",
      Exercise_Frequency: "",
      Exercise_Duration: "",
    };
    dispatch(
      setField({
        field: "Exercise",
        value: [...(Exercise || []), newExercise],
      })
    );
  };

  const handleDeleteExercise = (index: number) => {
    if (!Array.isArray(Exercise)) {
      return;
    }
    const updatedExercises = Exercise.filter((_, i) => i !== index);
    dispatch(
      setField({
        field: "Exercise",
        value: updatedExercises,
      })
    );
    setShowDeleteConfirmation(false);
    setDeleteIndex(null);
    setDeleteName("");
  };

  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Exercise"} incomplete={incomplete} />
      </div>
      {/* Exercise List Section */}
      <Box sx={commonStyles.EditBox}>
        {Array.isArray(Exercise) &&
          Exercise.map((exercise, index) => (
            <Box key={index} sx={{ marginBottom: "16px" }}>
              <ExerciseItem
                index={index}
                exercise={exercise}
                handleDeleteRequest={handleDeleteRequest}
                handleExerciseChange={handleExerciseChange}
              />
            </Box>
          ))}

        {/* Add Exercise Button */}
        {!Array.isArray(Exercise) ||
        !Exercise.some((ex) => ex.Exercise_Name === "No Exercise") ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "16px" }}
          >
            <Button
              onClick={handleAddExercise}
              variant="contained"
              sx={{
                backgroundColor: "#E1E7EC",
                fontSize: "16px",
                color: "#404B5A",
                "&:hover": {
                  backgroundColor: "#D3D3D3",
                },
              }}
            >
              + Add Exercise
            </Button>
          </Stack>
        ) : null}
      </Box>
      {/* Delete Confirmation Dialog */}
      {showDeleteConfirmation && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            width: "100%",
          }}
        >
          <DeleteConfirmation
            handleDeleteClose={handleCancelDelete}
            handleDeleteConfirm={() =>
              deleteIndex !== null && handleDeleteExercise(deleteIndex)
            }
            name={deleteName}
          />
        </div>
      )}
    </Box>
  );
};

export default ExerciseEdit;
