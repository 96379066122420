import React, { useState } from "react";
import { Typography, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SystemStyleObject } from "@mui/system";

interface DateFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  width?: string | number;
  sx?: SystemStyleObject;
}

const DateField: React.FC<DateFieldProps> = ({
  label,
  value,
  onChange,
  required = false,
  width = "100%",
  sx = {},
}) => {
  const [active, setActive] = useState(false);

  return (
    <FormControl sx={{ width: width || "100%" }}>
      <Typography
        variant="body2"
        fontSize="clamp(12px, 0.8vw, 16px)"
        color={active ? "#008AD8" : "text.secondary"}
        textAlign={"left"}
        sx={{ marginLeft: "4px" }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <TextField
        type="date"
        fullWidth
        value={value}
        onChange={onChange}
        onFocus={() => setActive(true)} // Set active to true when focused
        onBlur={() => setActive(false)} // Set active to false when focus is lost
        InputProps={{
          sx: {
            height: "clamp(35px, 4vh, 40px)",
            border: `1px solid ${
              value === "" ? "orange" : active ? "#008AD8" : "#CCD4DB"
            }`,
            backgroundColor: "white",
            fontSize: "16px",
            borderRadius: "8px",
            textAlign: "left",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
      />
    </FormControl>
  );
};

export default DateField;
