import { DateTime } from "luxon";
import * as R from "ramda";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { SerializedError } from "@reduxjs/toolkit";
import {
  delay,
  type ClientNotificationMessage,
  removeNils,
} from "shared-utils";
import { setAssessment } from "../state/assessmentSlice";
import {
  type CreateRecordingArchiveMutation,
  useCreateRecordingArchiveMutation,
  useGenerateAssessmentMutation,
  useLazyAudioResultsQuery,
  useStartTranscriptionSessionMutation,
  useTranscribeAudioMutation,
  useCreateRecordingMutation,
  RecordingInput,
  CreateRecordingMutation,
  StartTranscriptionSessionMutation,
} from "../graphql/generated";
import { useServiceBag } from "../services/ServiceBag";
import { RootState } from "../state/reducers";
import { trackEvent } from "../app/eventTracker";
import useTranscriptionState, { type Packet } from "./useTranscriptionState";
import { useLazyWebSocket } from "./useWebSocket";
import useRecorder from "./useRecorder";
import useCurrentHealthOrg from "./useCurrentHealthOrg";

// This file is where most of the work happens when performing a recording. It sends the audio
// in chunks, and receives the transcription via web socket. When the recording ends,
// if the web socket has not worked, it queries the server for the transcription results.
// Then, it starts the assessment process. Again, this tries to use the web socket but
// falls back to an explicit query if the web socket doesn't work.

type AssessmentStatus = "notStarted" | "inProgress" | "ready";

interface CreateRecordingArchiveResult {
  data: CreateRecordingArchiveMutation;
  error?: ErrorResponse | SerializedError;
}

interface CreateRecordingResult {
  data: CreateRecordingMutation;
  error?: ErrorResponse | SerializedError;
}

interface StartSessionResult {
  data?: StartTranscriptionSessionMutation;
  error?: ErrorResponse | SerializedError;
}

interface AssessmentResult {
  notes?: string;
  symptoms?: string[];
  prediction?: {
    diseases: string[];
    assessment: Record<string, string>;
  };
}

// move to shared utils
export const visitBins = [
  "allergies",
  "drugs",
  "hopi",
  "meds",
  "obgyn",
  "screenings",
  "socialHx",
  "surgicalHx",
  "pastMedicalHx",
  "vaccine",
  "a_p",
  "familyHx",
] as const;

export const leftBins = [
  "screenings",
  "surgicalHx",
  "pastMedicalHx",
  "vaccine",
  "a_p",
] as const;

export const rightBins = [
  "socialHx",
  "allergies",
  "drugs",
  "hopi",
  "meds",
  "obgyn",
] as const;

type BinType = typeof visitBins;

export type BinSet = {
  [K in BinType[number]]: string;
};

const extractBinData = R.pick(visitBins);

type TranscriptionStatus = BinSet & {
  transcriptionInProgress: boolean;
  upcomingAudio: string[];
};

// const emptyBin = {
//   allergies: "",
//   drugs: "",
//   hopi: "",
//   meds: "",
//   obgyn: "",
//   screenings: "",
//   socialHx: "",
//   surgicalHx: "",
//   vaccine: "",
//   pastMedicalHx: "",
//   a_p: "",
// };

const emptyBin = {
  allergies: "", //JSON.stringify(["penicillin", "Shawarma"]),
  drugs: "", //JSON.stringify([{"name":"Tylenol","dose":"500mg","frequency":"every 4 hours","route":"oral","startDate":"2021-09-01","endDate":"2021-09-30","notes":"for pain","id":"1"}]),
  hopi: "",
  meds: "",
  obgyn: "",
  screenings: "",
  socialHx: "",
  surgicalHx: "",
  vaccine: "",
  pastMedicalHx: "",
  a_p: "",
  familyHx: "",
};

function useAudioProcessing(
  clinicId: string,
  visitId: string | undefined | null
) {
  // There is some complexity here around the value of visitId. If we are using the simplified
  // workflow, without entering vitals, then visitId will initially be null. This hook will be
  // reevaluated when visitId changes, but by the time that happens, we've already started a setTimeout
  // that relies on the visitId. So startProcessing passes in the "real" visitId before this hook
  // gets updated. Thus we end up with two visitIds circulating. This makes things a bit confusing.
  // That should be cleaned up.

  const healthOrg = useCurrentHealthOrg();

  const realTime = !healthOrg?.trainingOnly;

  const { fileService, envService } = useServiceBag();
  const idRef = useRef<string | null>(null);
  const assessmentTimerRef = useRef<number | null>(null);
  const lastWebHookEvent = useRef<DateTime | null>(null);

  const didStopRef = useRef(false);
  const assessmentStatusRef = useRef<AssessmentStatus>("notStarted");

  const [transcribeAudio] = useTranscribeAudioMutation();
  const [startSession, startSessionState] =
    useStartTranscriptionSessionMutation();
  const [generateAssessment] = useGenerateAssessmentMutation();
  const [refreshAudioResults] = useLazyAudioResultsQuery();
  const audioSessionId =
    startSessionState.data?.startTranscriptionSession || "";
  // only used when creating a recording, to track the dr. who made it.
  // if we have a visit, this won't be used.
  const userEmail = useSelector(
    (state: RootState) => state.currentUser.currentUser?.email
  );
  const audioSessionFired = useRef(false);
  const transcriptionStatus = useRef<TranscriptionStatus>({
    transcriptionInProgress: false,
    upcomingAudio: [],
    ...emptyBin,
  });

  const [bins, setBins] = useState<BinSet>(emptyBin);

  // if we have a vist, we create recording archives in S3. If we don't, we create
  // a recording object in the DB for use in training the model.
  const [createRecordingArchive] = useCreateRecordingArchiveMutation();
  const [createRecording] = useCreateRecordingMutation();

  const dispatch = useDispatch();

  const { transcription, questions, appendArrayToTranscription } =
    useTranscriptionState();

  const audioIndexRef = useRef(0);

  // we have the assessment details, fire a redux action to store them.
  const putAssessmentInStore = useCallback(
    //[explanation]: this function is called when the transcription is done and the assessment is ready to be displayed. It takes the assessment details and stores them in the redux store.
    (result: AssessmentResult) => {
      dispatch(
        setAssessment({
          assessment: result.prediction?.assessment || {},
          notes: result.notes,
          diagnoses: result.prediction?.diseases || [],
          symptoms: result.symptoms || [],
        })
      );
    },
    [dispatch]
  );

  // we got transcription results, display them.
  const displayTranscriptionResults = useCallback(
    //[explanation]: this function is called when the transcription is done and the assessment is ready to be displayed. It takes the transcription results and displays them.
    (transcription: Packet[]) => {
      if (
        transcription &&
        questions &&
        !(Array.isArray(transcription) && transcription.length === 0)
      ) {
        appendArrayToTranscription(transcription, questions); //append array function imported from useTranscriptionState.ts, it appends the transcription (treated as packets) to alr transcription.
      }
    },
    [appendArrayToTranscription, questions]
  );

  // process the transcription results. They might come from a web socket OR an
  // explicit query.
  // If we have nothing left to process and the user has stopped recording,
  // we can start the assessment process.
  const handleTranscriptionUpdate = useCallback(
    //[explanation]: basically, as the comments say above, this is function of interest, we have transcription, we must now process it.
    (
      transcription?: Packet[],
      questions?: string[],
      bins?: BinSet,
      passedVisitId?: string
    ) => {
      displayTranscriptionResults(transcription || []); //this just appends the transcription to the existing transcription
      const finishedWithTranscription =
        didStopRef.current &&
        transcriptionStatus.current.upcomingAudio.length === 0 &&
        assessmentStatusRef.current === "notStarted";
      console.log(
        "didStopRef.current",
        didStopRef.current,
        "transcriptionStatus.current.upcomingAudio.length",
        transcriptionStatus.current.upcomingAudio.length,
        "assessmentStatusRef.current",
        assessmentStatusRef.current
      );
      console.log("finished with transcription", finishedWithTranscription);
      console.log(
        `TIMER: finished with transcription? ${
          finishedWithTranscription ? "yes" : "no"
        }`
      );

      // if we just got the last result, and the user has pressed stop,
      // we can fetch the assessment.
      if (finishedWithTranscription) {
        //if we are done with transcription and the user has stopped recording, we can now fetch the assessment.
        assessmentStatusRef.current = "inProgress";
        console.log(
          `User pressed stop, assesment requested [timestamp] ${envService.formattedCurrentTime()}`
        );
        void generateAssessment({
          clinicId: clinicId,
          visitId: passedVisitId || visitId,
          sessionId: audioSessionId,
        });
      }
      return assessmentStatusRef.current === "ready";
    },
    [
      audioSessionId,
      clinicId,
      envService,
      displayTranscriptionResults,
      generateAssessment,
      // refreshFinalResults,
      visitId,
    ]
  );

  // we have received audio from the browser, and are not currently processing audio.
  // Send this audio to the server.
  const transcribeCapturedAudio = useCallback(
    //just audio to text transcription, not of interest rn
    (data: string, passedVisitId?: string) => {
      const traceId = envService.randomUUID();
      console.log(
        `audio sent to server for transcription for visit  ${
          visitId || "-"
        } - ${traceId}... [timestamp] ${envService.formattedCurrentTime()}`
      );
      transcriptionStatus.current.transcriptionInProgress = true;
      return transcribeAudio({
        clinicId,
        visitId: passedVisitId || visitId,
        sessionId: audioSessionId,
        traceId,
        audio: data,
      }).then(() => {});
    },
    [audioSessionId, clinicId, envService, transcribeAudio, visitId]
  );

  // if we have audio queued up, process the next one.
  const processNextInQueue = useCallback(
    //not of interest
    (visitId: string) => {
      const data = transcriptionStatus.current.upcomingAudio.shift();
      if (data) {
        console.log("TIMER: processing queued audio");
        void transcribeCapturedAudio(data, visitId);
      }
    },
    [transcribeCapturedAudio]
  );

  // this is used to explicitly fetch the transcription results, if it looks like
  // the web socket is not working. (We also used to have a refresh button, but we
  // on longer need it.)
  const refreshResults = useCallback(
    (visitId?: string) => {
      if (
        lastWebHookEvent.current &&
        lastWebHookEvent.current.plus({ seconds: 45 }) > DateTime.now() // i think this refreshes the results if the last webhook event was more than 45 seconds ago
      ) {
        console.log(
          "TIMER: we don't need to refresh because of hook",
          "now",
          envService.formattedCurrentTime(),
          "wb",
          lastWebHookEvent.current.toLocaleString(DateTime.TIME_WITH_SECONDS)
        );
        return false;
      }
      console.log(`TIMER: try to refresh results for ${visitId || "-"}`);
      if (visitId == null) {
        return;
      }
      refreshAudioResults({
        //this part just fetches the results automatically, it fetches transcription, question, notes, all bins
        clinicId: clinicId,
        visitId: visitId,
      })
        .then((result) => {
          console.log(
            `got results via API: ${JSON.stringify(
              result
            )} [timestamp] ${envService.formattedCurrentTime()}`
          );
          const visit = result.data?.visit;
          if (visit) {
            // notes are generated after we request assesment (by clicking done), when we get notes we know transcription has finished.
            // transcription is also updated when we request the final assesment, that's why it's being updated
            // in either case.
            if (visit.originalNotes) {
              console.log(
                "Inside original notes, meaning transcription has ended",
                visit.originalNotes
              );
              dispatch(
                setAssessment({
                  assessment: JSON.parse(visit.assessment || "{}"),
                  notes: visit.originalNotes || "",
                  diagnoses: removeNils(visit.diagnoses),
                  symptoms: removeNils(visit.symptoms),
                })
              );
              assessmentStatusRef.current = "ready";
              window.clearTimeout(assessmentTimerRef.current || undefined);
            }
            const packets = JSON.parse(visit.transcription || "{}") as Packet[];
            const questions = JSON.parse(visit.questions || "[]") as string[];
            console.log(
              "TIMER: handle visit transcription results for refresh"
            );
            const binSet = { ...emptyBin, ...visit } as BinSet;
            setBins(binSet);
            handleTranscriptionUpdate(packets, questions, binSet, visitId);
          }
          processNextInQueue(visitId);
        })
        .catch((error) => {
          console.log(`error refreshing results: ${JSON.stringify(error)}`);
        });
    },
    [
      clinicId,
      dispatch,
      envService,
      handleTranscriptionUpdate,
      processNextInQueue,
      refreshAudioResults,
    ]
  );

  // takes a transcript/question list sent via web socket, processes it, and
  // appends it to the current transcription.
  // returns whether we are finished.
  const handleTranscriptionUpdateFromWebSocket = useCallback(
    //this is the function of interest,
    (msg: ClientNotificationMessage) => {
      lastWebHookEvent.current = DateTime.now();
      console.log("[SIGNALLING LIVE UPDATE FROM WEBHOOK]: ", msg.transcript);
      if (msg.transcript) {
        const transcriptInfo = JSON.parse(msg.transcript);
        if (transcriptInfo.notes) {
          console.log(`we got notes: ${transcriptInfo.notes as string}`);
          putAssessmentInStore(transcriptInfo);
          assessmentStatusRef.current = "ready";
        }
        transcriptionStatus.current.transcriptionInProgress = false;

        const binSet = extractBinData(transcriptInfo) as BinSet;

        const finished = handleTranscriptionUpdate(
          transcriptInfo.packets,
          transcriptInfo.questions,
          binSet
        );

        setBins(binSet);
        console.log("[THE ABSOLUTE IMPORTANT MESSAGE] bins is: ", binSet); //another attempt at looking at bins

        //create a new state for separate bins

        processNextInQueue(visitId || "");
        return finished;
      }
    },
    [
      handleTranscriptionUpdate,
      processNextInQueue,
      putAssessmentInStore,
      visitId,
    ]
  );

  // these methods start/stop listening to the websocket.
  const [startListeningToSocket, stopListeningToSocket] =
    useLazyWebSocket<ClientNotificationMessage>((m) => {
      if (handleTranscriptionUpdateFromWebSocket(m)) {
        console.log("shutting down socket and clearing out interval");
        window.clearTimeout(assessmentTimerRef.current || undefined);
        idRef.current = null;
        stopListeningToSocket();
      }
    });

  const uploadAudio = useCallback(
    async (s3Url: string | null | undefined, recordingDataURI: string) => {
      console.log(`upload to ${s3Url || "-"} as string}`);
      if (s3Url != null) {
        await fileService.uploadBase64ToS3(s3Url, recordingDataURI);
      }
    },
    [fileService]
  );

  const archiveAudio = useCallback(
    (archiveId: string, index: number, recordingDataURI: string) => {
      async function archiveAudioAsync() {
        console.log(`archiving audio for ${archiveId} at index ${index}`);
        const response = (await createRecordingArchive({
          archiveId,
          index,
        })) as CreateRecordingArchiveResult;
        const s3Url = response.data?.createRecordingArchive;
        await uploadAudio(s3Url, recordingDataURI);
      }
      void archiveAudioAsync();
    },
    [createRecordingArchive, uploadAudio]
  );

  const saveRecording = useCallback(
    (recordingDataURI: string) => {
      async function saveRecordingAsync() {
        const recording: RecordingInput = {
          recordedAt: envService.currentTime(),
          recordingId: envService.randomUUID(),
          user: userEmail || "",
        };
        const response = (await createRecording({
          recording,
        })) as CreateRecordingResult;
        console.log(`we created a recording! ${JSON.stringify(recording)}`);
        const s3Url = response.data?.createRecording?.uploadUrl;
        await uploadAudio(s3Url, recordingDataURI);
      }
      void saveRecordingAsync();
    },
    [createRecording, envService, uploadAudio, userEmail]
  );

  // the browser gave us some audio data!
  // save it in S3 (how we do this varies with the context)
  // if we are currently waiting for a previous audio to be processed,
  // add it to the queue.
  // If we aren't processing anything, it goes right to the server for transcription.
  const processCapturedAudio = (data: string) => {
    console.log(
      `audio captured: processing audio [timestamp] ${envService.formattedCurrentTime()}`
    );
    // make sure we are listening at the web socket.
    if (idRef.current !== audioSessionId) {
      idRef.current = audioSessionId;
      startListeningToSocket({ clinic: audioSessionId || "", name: "AUDIO" });
    }

    // save the audio. How we do this depends on whether this is a visit or
    // if we are training the model.
    console.log("processCapturedAudio: prepare to archive");

    if (visitId) {
      audioIndexRef.current += 1;
      archiveAudio(visitId, audioIndexRef.current, data);
    } else {
      saveRecording(data);
    }

    // transcribe, unless we are currently doing a transcripion.
    // in that case, queue it up.
    if (transcriptionStatus.current.transcriptionInProgress) {
      console.log("processCapturedAudio: queueing audio");
      transcriptionStatus.current.upcomingAudio.push(data);
      return Promise.resolve();
    }
    console.log("processCapturedAudio: transcribe captured");
    return transcribeCapturedAudio(data).then(() => {});
  };

  const onAudioDataReady = useCallback(processCapturedAudio, [
    audioSessionId,
    visitId,
    envService,
    transcribeCapturedAudio,
    startListeningToSocket,
    archiveAudio,
    saveRecording,
  ]);

  const onAudioFailure = useCallback(() => {
    console.log("recording failed ");
  }, []);

  const { recorder, recordingInProgress } = useRecorder(
    onAudioDataReady,
    onAudioFailure
  );

  // start the session with the model. This happens as soon as the page loads, and
  // it might take a while if the model is doing a cold start.
  const startTranscriptionSession = () => {
    async function startTranscriptionSessionAsync() {
      console.log(`starting session for ${visitId || "-"}`);
      for (let i = 0; i < 50; i++) {
        const result = (await startSession({ visitId })) as StartSessionResult;
        if (result.data?.startTranscriptionSession) {
          console.log(
            `reached model [timestamp] ${envService.formattedCurrentTime()}`
          );
          return;
        }
        await delay(15 * 1000);
        console.log("retrying start session");
      }
      enqueueSnackbar(
        "Unable to reach model, please try again in a few minutes.",
        {
          variant: "error",
        }
      );
    }
    if (!audioSessionFired.current) {
      audioSessionFired.current = true;
      void startTranscriptionSessionAsync();
    }
  };

  // start session when we have the visit loaded.
  // intentially done without a list of dependencies so it only executes once.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startTranscriptionSession, []);

  // the user hit the play button.
  const startProcessing = useCallback(
    (visitId?: string) => {
      console.log(
        `recording started [timestamp] ${envService.formattedCurrentTime()}`
      );
      // see the note at the beginning of the hook that explains why we have
      // visitId passed in here even though the hook also gets the parameter.
      // Basically at this point the hook has not yet been updated with the
      // new visitId, and we need it to set the timer function.
      console.log(`TIMER: start polling for visit ${visitId || "--"}`);
      // poll in case the web socket doesn't work.
      assessmentTimerRef.current = window.setInterval(() => {
        refreshResults(visitId);
      }, 15 * 1000);

      void recorder.start(realTime ? 20 * 1000 : undefined);
      trackEvent("FeRecordingStarted");
    },
    [realTime, recorder, refreshResults, envService]
  );

  // the user hit the stop button.
  const stopProcessing = useCallback(() => {
    recorder.stop();
    didStopRef.current = true;
    trackEvent("FeRecordingStopped"); //tracking events for improved user behaviour

    // in case the web socket is broken, set a timer to fetch the results
    // after giving the web socket time to function.
    console.log(`set timer to refresh results`);
  }, [recorder]);

  // we currently don't offer the user a cancel button.
  // But if we did...
  const cancelProcessing = useCallback(() => {
    recorder.cancel();
  }, [recorder]);

  return {
    startProcessing,
    stopProcessing,
    cancelProcessing,
    refreshResults,
    recordingInProgress,
    transcription,
    questions,
    bins,
    audioSessionId,
    waitingForAssessment: assessmentStatusRef.current === "inProgress",
    assessmentReady: assessmentStatusRef.current === "ready",
  };
}

export default useAudioProcessing;
