import React, { useState } from "react";
import { Stack, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SelectChangeEvent } from "@mui/material";
import { getFieldOptions } from "../../../../utils/BinUtils";
import { DeleteConfirmation } from "../../../DialgueBoxes";
import InputField from "../ui-components/InputField";
import DropdownField from "../ui-components/DropdownFIeld";
import EditModeButton from "../ui-components/BinButtons";
import EditModeHeading from "../ui-components/EditModeHeadings";
import { AlertStyle } from "../../../../utils/constants";
import {
  boxStyle,
  editableContainerStyle,
} from "../../../../constants/StyleConstants";
import FamilyHxBinConditionEditable from "./EditMedicalCondition";
import { FamilyHxAttributes } from "./FamilyHx";
import { MedConditionAttributes } from "./MedConditionView";

function AddOrEditFamilyHx({
  Bin,
  onClose,
  onSave,
  onDelete,
  inEditing,
  status,
}: {
  Bin: FamilyHxAttributes | null | undefined;
  onClose: () => void;
  onSave: (newRelation: FamilyHxAttributes, KeyRelation: string) => void;
  onDelete: (KeyRelation: string) => void;
  inEditing: boolean;
  status: string;
}) {
  const [Relation, setRelation] = useState(Bin?.Relation || "");
  const [Status, setStatus] = useState(Bin?.Status || "");
  const [AgeDeath, setAgeDeath] = useState(Bin?.Age_at_death || "");
  const [AdditionalNotes, setAdditionalNotes] = useState(
    Bin?.Additional_notes || ""
  );
  const [MedicalCondition, setMedicalCondition] = useState<
    MedConditionAttributes[]
  >(Bin?.Medical_condition || [{ Name: "", Age: "" }]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [delName, setDelName] = useState(`Relation ${Bin?.Relation || ""}`);
  const [isDeceased, setIsDeceased] = useState(Bin?.Status === "Deceased");

  const EditKeyRelation = Bin?.Relation || "";

  const handleFieldChange =
    (setter: (value: string) => void) =>
    (
      event: SelectChangeEvent<string> | React.ChangeEvent<{ value: string }>
    ) => {
      setter(event.target.value);
    };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setStatus(value);
    setIsDeceased(value === "Deceased");
    if (value !== "Deceased") {
      setAgeDeath("");
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setDelName("");
  };

  const handleConfirmDelete = () => {
    onDelete(EditKeyRelation);
    handleCancelDelete();
    onClose();
  };

  const handleSave = () => {
    const newRelation: FamilyHxAttributes = {
      Relation,
      Status,
      Age_at_death: AgeDeath,
      Additional_notes: AdditionalNotes,
      Medical_condition: MedicalCondition,
      Reviewed_by: Bin?.Reviewed_by || "",
      Reviewed_at: Bin?.Reviewed_at || "",
    };
    onSave(newRelation, EditKeyRelation);
    onClose();
  };

  return (
    <>
      <EditModeHeading type={inEditing ? "Edit" : "Add"} title="Relation" />

      <Box sx={boxStyle}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "red",
            padding: "0",
            "& .MuiSvgIcon-root": { fontSize: "16px" },
          }}
        >
          <CloseIcon />
        </IconButton>

        <DropdownField
          label="Relationship"
          value={Relation}
          onChange={handleFieldChange(setRelation)}
          options={
            status !== "Adopted"
              ? getFieldOptions("familyHx", "Relation")
              : getFieldOptions("familyHx", "Relation_adopted")
          }
          required
          width="100%"
          sx={{ marginTop: "16px" }}
        />

        <DropdownField
          label="Status"
          value={Status}
          onChange={handleStatusChange}
          options={getFieldOptions("familyHx", "Status")}
          required
          width="100%"
          sx={{ marginTop: "8px" }}
        />

        {isDeceased && (
          <InputField
            label="Age at death"
            value={AgeDeath}
            onChange={handleFieldChange(setAgeDeath)}
            required
            num
            sx={{ marginTop: "8px" }}
          />
        )}

        <Box sx={editableContainerStyle}>
          <FamilyHxBinConditionEditable
            MedConditions={MedicalCondition}
            setMedicalCondition={setMedicalCondition}
          />
        </Box>

        <InputField
          label="Additional Notes"
          value={AdditionalNotes}
          onChange={handleFieldChange(setAdditionalNotes)}
          required={false}
          sx={{ marginTop: "8px" }}
        />

        {showDeleteConfirmation && (
          <div style={AlertStyle}>
            <DeleteConfirmation
              handleDeleteClose={handleCancelDelete}
              handleDeleteConfirm={handleConfirmDelete}
              name={delName}
            />
          </div>
        )}
      </Box>

      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ marginTop: "2vh", marginBottom: "2vh" }}
      >
        {inEditing && (
          <EditModeButton
            type="delete"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Delete
          </EditModeButton>
        )}
        <EditModeButton type="save" onClick={handleSave}>
          Save
        </EditModeButton>
      </Stack>
    </>
  );
}

export default AddOrEditFamilyHx;
