import React from "react";
import { Button } from "@mui/material";

interface EditModeButtonProps {
  type: "save" | "discontinue" | "delete";
  onClick: () => void;
  children: React.ReactNode;
}

export const EditModeButton: React.FC<EditModeButtonProps> = ({
  type,
  onClick,
  children,
}) => {
  const minWidth = type === "discontinue" ? "150px" : "90px";
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor:
          type === "discontinue"
            ? "#008AD8"
            : type === "save"
            ? "#0CAF5D"
            : "#FF8080",
        color: "white",
        borderRadius: "8px",
        width: `clamp(${minWidth}, 6vw, 150px)`,
        height: "clamp(25px, 3vh, 35px)",
        marginLeft: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        "&:hover": {
          backgroundColor:
            type === "save"
              ? "#008000"
              : type === "delete"
              ? "#ad2831"
              : "#1a659e",
        },
      }}
    >
      {children}
    </Button>
  );
};

interface ListModeButtonProps {
  type: "Add" | "Expand";
  onClick: () => void;
  children: React.ReactNode;
}

export const ListModeButton: React.FC<ListModeButtonProps> = ({
  type,
  onClick,
  children,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: type === "Add" ? "#E9F5FC" : "#E9E9E9",
        color: "#404B5A",
        width: "clamp(150px, 30%, 190px)",
        paddingTop: "6px",
        paddingBottom: "6px",
        border: "none",
        outline: "none",
        cursor: "pointer",
      }}
    >
      {children}
    </Button>
  );
};

export default EditModeButton;
