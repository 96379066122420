import React from "react";
import { Stack, Typography } from "@mui/material";

interface WarningTextProps {
  condition: boolean;
  message: string;
  color: string;
  iconSize?: string | number;
  iconSrc: string;
}

const WarningText: React.FC<WarningTextProps> = ({
  condition,
  message,
  color,
  iconSize = "16px",
  iconSrc,
}) => {
  if (!condition) {
    return null;
  }

  return (
    <Stack direction="row">
      <img
        src={iconSrc}
        style={{
          marginRight: 4,
          marginBottom: 0,
          width: iconSize,
          height: iconSize,
          color: color,
        }}
        alt="alert icon"
      />
      <Typography
        component="span"
        sx={{
          color: color,
          mr: 1,
          fontSize: "14px",
          textAlign: "left",
        }}
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default WarningText;
