import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";

interface ReviewedBoxProps {
  onMarkAsReviewed: (val: boolean) => void;
  ReviewedBy: string;
  ReviewedAt: string;
}

const MarkAsReviewedBox: React.FC<ReviewedBoxProps> = ({
  onMarkAsReviewed,
  ReviewedBy,
  ReviewedAt,
}) => {
  return (
    <Box
      mt={2}
      width={"100%"}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
    >
      <Box display="flex" justifyContent={"flex-start"} alignItems="center">
        <Checkbox
          size="small"
          onChange={(event) => {
            onMarkAsReviewed(event.target.checked);
          }}
          sx={{
            p: 0,
            mr: 1,
            fontSize: 9,
            width: 12,
            height: 12,
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
          }}
        />
        <Typography
          component="span"
          sx={{ color: "#404B5A", fontSize: 12, textAlign: "left" }}
        >
          Mark as reviewed
        </Typography>
      </Box>
      {ReviewedBy && ReviewedAt !== "" && (
        <Typography
          fontSize="10px"
          color="text.secondary"
          fontStyle="italic"
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.4",
            wordWrap: "break-word",
            textAlign: "right",
          }}
        >
          Last reviewed by Dr. {ReviewedBy} on {ReviewedAt}
        </Typography>
      )}
    </Box>
  );
};

export default MarkAsReviewedBox;
