import "./App.css";
import { CookiesProvider } from "react-cookie";
// import "../css/react-grid-layout.css";
// import "../css/react-resizable.css";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { store } from "../state/store";
import { ServiceBagProvider } from "../services/ServiceBag";
import LocalizationProvider from "./LocalizedStringsProvider";
import SehatThemeProvider from "./theme/SehatThemeProvider";
import router from "./router";
import NotificationListener from "./NotificationListener";
import SecurityGuardrail from "./SecurityGuardrail";

function App() {
  return (
    <div className="App">
      <SnackbarProvider />
      <LocalizationProvider>
        <SehatThemeProvider>
          <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
              <SecurityGuardrail>
                <CookiesProvider>
                  <ServiceBagProvider>
                    <NotificationListener />
                    <RouterProvider router={router} />
                  </ServiceBagProvider>
                </CookiesProvider>
              </SecurityGuardrail>
            </DndProvider>
          </Provider>
        </SehatThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
