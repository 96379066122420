import { useState } from "react";
import { IconButton, Box, Stack, Button } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getFieldOptions } from "../../../../utils/BinUtils";
import InputField from "../ui-components/InputField";
import DropdownField from "../ui-components/DropdownFIeld";
import { DeleteConfirmation } from "../../../DialgueBoxes";
import { MedConditionAttributes } from "./MedConditionView";

function FamilyHxBinConditionEditable({
  MedConditions,
  setMedicalCondition,
}: {
  MedConditions: MedConditionAttributes[];
  setMedicalCondition: (arr: MedConditionAttributes[]) => void;
}) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState<string>("");

  const handleMedicalConditionChange = (
    index: number,
    field: keyof MedConditionAttributes,
    value: string
  ) => {
    const updatedConditions = [...MedConditions];
    updatedConditions[index] = {
      ...updatedConditions[index],
      [field]: value,
    };
    setMedicalCondition(updatedConditions);
  };

  const handleDeleteCondition = (index: number) => {
    const updatedConditions = MedConditions.filter((_, i) => i !== index);
    setMedicalCondition(updatedConditions);
    setShowDeleteConfirmation(false);
    setDeleteIndex(null);
    setDeleteName("");
  };

  const handleAddCondition = () => {
    const newCondition: MedConditionAttributes = { Name: "", Age: "" };
    setMedicalCondition([...MedConditions, newCondition]);
  };

  const handleDeleteRequest = (index: number, name: string) => {
    setDeleteIndex(index);
    setDeleteName(name);
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setDeleteIndex(null);
    setDeleteName("");
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleDeleteCondition(deleteIndex);
    }
  };

  return (
    <div>
      {MedConditions.map((condition, index) => (
        <div key={index} style={{ marginTop: "8px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DropdownField
              label="Medical Condition"
              value={condition.Name || ""}
              onChange={(e: SelectChangeEvent<string>) =>
                handleMedicalConditionChange(index, "Name", e.target.value)
              }
              options={getFieldOptions("familyHx", "Medical_condition")}
              required
              width="50%"
            />
            <InputField
              label="Age of Onset"
              value={condition.Age || ""}
              onChange={(e) =>
                handleMedicalConditionChange(index, "Age", e.target.value)
              }
              required
              num={true}
              width="30%"
            />
            <IconButton
              onClick={() =>
                handleDeleteRequest(index, condition.Name || "this condition")
              }
              sx={{
                padding: "0.3vw",
                "& .MuiSvgIcon-root": { fontSize: "25px" },
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </div>
      ))}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "16px" }}
      >
        <Button
          onClick={handleAddCondition}
          variant="contained"
          sx={{
            backgroundColor: "#E1E7EC",
            fontSize: "16px",
            color: "#404B5A",
            "&:hover": {
              backgroundColor: "#D3D3D3",
            },
          }}
        >
          + Add Condition
        </Button>
      </Stack>

      {showDeleteConfirmation && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            width: "100%",
          }}
        >
          <DeleteConfirmation
            handleDeleteClose={handleCancelDelete}
            handleDeleteConfirm={handleConfirmDelete}
            name={"condition: " + deleteName}
          />
        </div>
      )}
    </div>
  );
}

export default FamilyHxBinConditionEditable;
