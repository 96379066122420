import { useState } from "react";
import { Stack, Typography, MenuItem, Box, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import WarningIcon from "../assets/WarningIcon.svg";

export function ErrorComponent({
  handleCancel,
  name,
  dosageValue,
}: {
  handleCancel: () => void;
  name: string | JSX.Element;
  dosageValue: number;
}): JSX.Element {
  return (
    <div
      style={{
        width: "70%",
        border: "1px solid #CCD4DB",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Stack direction="row">
        <img
          src={WarningIcon}
          style={{
            marginRight: 4,
            marginBottom: 0,
            width: "2vw",
            height: "2vw",
            color: "red",
          }}
          alt="!!"
        />
        <Typography
          variant="h6"
          sx={{ color: "black", textAlign: "left", padding: "10px" }}
        >
          Error
        </Typography>
      </Stack>
      <Box
        sx={{ height: "2px", backgroundColor: "red", marginBottom: "10px" }}
      />

      <Typography sx={{ color: "gray", textAlign: "left", padding: "10px" }}>
        The calculated Dosage of {name} is {dosageValue}, which exceeds the
        recommended limit (4000). Please adjust the dosage to proceed.
      </Typography>

      <Box
        sx={{ height: "1px", backgroundColor: "gray", marginBottom: "10px" }}
      />
      <Stack direction="row" justifyContent="right" sx={{ padding: "10px" }}>
        <Button
          onClick={handleCancel}
          sx={{
            backgroundColor: "#FCD9D9",
            color: "#FF8080",
            width: "45%",
            "&:hover": { backgroundColor: "#F8BABA" },
          }}
        >
          Cancel
        </Button>
      </Stack>
    </div>
  );
}

export function WarningComponent({
  handleCancel,
  handleSave,
  medName,
  conflictName,
  conflictClass,
}: {
  handleCancel: () => void;
  handleSave: () => void;
  medName: string;
  conflictName: string;
  conflictClass: string;
}): JSX.Element {
  return (
    <div
      style={{
        width: "70%",
        border: "1px solid #CCD4DB",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Stack direction="row">
        <img
          src={WarningIcon}
          style={{
            marginRight: 4,
            marginBottom: 0,
            width: "2vw",
            height: "2vw",
            color: "orange",
          }}
          alt="!!"
        />
        <Typography
          variant="h6"
          sx={{ color: "black", textAlign: "left", padding: "10px" }}
        >
          Warning
        </Typography>
      </Stack>
      <Box
        sx={{ height: "2px", backgroundColor: "red", marginBottom: "10px" }}
      />
      <Typography sx={{ color: "gray", textAlign: "left", padding: "10px" }}>
        {conflictName !== "" && conflictClass !== "" ? (
          <>
            You have already added another drug "{conflictName}" from the "
            {conflictClass}" class. Are you sure you want to continue?
          </>
        ) : (
          <>
            You have already added another drug from this drug class, are you
            sure you want to continue?
          </>
        )}
      </Typography>
      <Box
        sx={{ height: "1px", backgroundColor: "gray", marginBottom: "10px" }}
      />
      <Stack direction="row" justifyContent="right" sx={{ padding: "10px" }}>
        <Button
          onClick={handleCancel}
          sx={{
            backgroundColor: "#EC8B36",
            color: "white",
            width: "45%",
            "&:hover": { backgroundColor: "#D9782F" },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleSave}
          sx={{
            borderColor: "#EC8B36",
            color: "#EC8B36",
            width: "45%",
            "&:hover": {
              backgroundColor: "rgba(236, 139, 54, 0.1)",
              borderColor: "#D9782F",
              color: "#D9782F",
            },
          }}
        >
          Save
        </Button>
      </Stack>
    </div>
  );
}

export function MedsAllergyWarning({
  handleCancel,
  handleSave,
  medName,
  conflictName,
}: {
  handleCancel: () => void;
  handleSave: () => void;
  medName: string;
  conflictName: string;
}): JSX.Element {
  return (
    <div
      style={{
        width: "70%",
        border: "1px solid #CCD4DB",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Stack direction="row">
        <img
          src={WarningIcon}
          style={{
            marginRight: 4,
            marginBottom: 0,
            width: "2vw",
            height: "2vw",
            color: "orange",
          }}
          alt="!!"
        />
        <Typography
          variant="h6"
          sx={{ color: "black", textAlign: "left", padding: "10px" }}
        >
          Warning
        </Typography>
      </Stack>
      <Box
        sx={{ height: "2px", backgroundColor: "red", marginBottom: "10px" }}
      />
      <Typography sx={{ color: "gray", textAlign: "left", padding: "10px" }}>
        {medName} belongs to a drug class that is listed as an allergy "
        {conflictName}". Are you sure you want to continue?
      </Typography>
      <Box
        sx={{ height: "1px", backgroundColor: "gray", marginBottom: "10px" }}
      />
      <Stack direction="row" justifyContent="right" sx={{ padding: "10px" }}>
        <Button
          onClick={handleCancel}
          sx={{
            backgroundColor: "#EC8B36",
            color: "white",
            width: "45%",
            "&:hover": { backgroundColor: "#D9782F" },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleSave}
          sx={{
            borderColor: "#EC8B36",
            color: "#EC8B36",
            width: "45%",
            "&:hover": {
              backgroundColor: "rgba(236, 139, 54, 0.1)",
              borderColor: "#D9782F",
              color: "#D9782F",
            },
          }}
        >
          Save
        </Button>
      </Stack>
    </div>
  );
}

export function DeleteConfirmation({
  handleDeleteClose,
  handleDeleteConfirm,
  name,
}: {
  handleDeleteClose: () => void;
  handleDeleteConfirm: () => void;
  name: string | JSX.Element;
}): JSX.Element {
  return (
    <div
      style={{
        width: "70%",
        border: "1px solid #CCD4DB",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: "black", textAlign: "center", padding: "10px" }}
      >
        Confirm Deletion
      </Typography>

      <Typography sx={{ color: "black", textAlign: "center", padding: "10px" }}>
        Are you sure you want to delete "{name}"?
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <Button
          onClick={handleDeleteClose}
          sx={{ backgroundColor: "#EDF2FC", color: "#008AD8", width: "45%" }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleDeleteConfirm}
          sx={{ backgroundColor: "#D24E4B", color: "white", width: "45%" }}
        >
          Delete
        </Button>
      </Stack>
    </div>
  );
}

export function DiscontinueReason({
  handleDiscontinueClose,
  handleDiscontinueConfirm,
  name,
  setDelReason,
}: {
  handleDiscontinueClose: () => void;
  handleDiscontinueConfirm: () => void;
  name: string | JSX.Element;
  setDelReason: (value: string) => void;
}): JSX.Element {
  const [value, setValue] = useState("");

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    setDelReason(event.target.value);
  };

  return (
    <div
      style={{
        width: "70%",
        border: "1px solid #CCD4DB",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "black",
          textAlign: "center",
          padding: "10px",
          marginBottom: "5px",
        }}
      >
        Are you sure you want to discontinue "{name}"?
      </Typography>
      <FormControl sx={{ width: "100%" }}>
        <Typography
          variant="h6" // Adjust the variant as needed
          sx={{
            fontSize: "16px",
            marginBottom: "0px", // Spacing below the label
            paddingLeft: "0.45vw", // Align label horizontally
            paddingBottom: "0px",
            color: "#CCD4DB",
          }}
        >
          Reason for Discontinuing?
        </Typography>

        <Select
          size="small"
          displayEmpty
          fullWidth
          value={value}
          onChange={handleChange}
          sx={{
            height: "4vh",
            border: `1px solid #CCD4DB`,
            backgroundColor: "white",
            fontSize: "16px",
          }}
        >
          <MenuItem value="Adverse Drug Effect">Adverse Drug Effect</MenuItem>
          <MenuItem value="Drug Interaction">Drug Interaction</MenuItem>
          <MenuItem value="Duplicate Therapy">Duplicate Therapy</MenuItem>
          <MenuItem value="Ineffective treatment">
            Ineffective treatment
          </MenuItem>
          <MenuItem value="No longer needed">No longer needed</MenuItem>
          <MenuItem value="Improve Adherence">Improve Adherence</MenuItem>
          <MenuItem value="Non-formulary">Non-formulary</MenuItem>
          <MenuItem value="Availability Issues">Availability Issues</MenuItem>
          <MenuItem value="Regulatory Recall">Regulatory Recall</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <Button
          onClick={handleDiscontinueClose}
          sx={{ backgroundColor: "#EDF2FC", color: "#008AD8", width: "45%" }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleDiscontinueConfirm}
          sx={{ backgroundColor: "#D24E4B", color: "white", width: "45%" }}
        >
          Delete
        </Button>
      </Stack>
    </div>
  );
}
