import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Medicine } from "./Medicines";

interface MedicineHistoryProps {
  ActiveMeds: Medicine[] | null;
  DiscMeds: Medicine[] | null;
  closeMedicineHistory: () => void;
}

const MedicineHistory: React.FC<MedicineHistoryProps> = ({
  ActiveMeds,
  DiscMeds,
  closeMedicineHistory,
}) => {
  const [filterBy, setFilterBy] = useState<string>("status");
  const [sortedMeds, setSortedMeds] = useState<Medicine[]>([]);
  const TotalMeds = useMemo(() => {
    return [...(ActiveMeds || []), ...(DiscMeds || [])];
  }, [ActiveMeds, DiscMeds]);

  const displayValue = (value: string | undefined | null) =>
    value === undefined || value === "" ? "--" : value;

  useEffect(() => {
    const sortedArray = [...TotalMeds];
    if (filterBy === "startDate") {
      sortedArray.sort((a, b) => (a.Start_date > b.Start_date ? 1 : -1));
    } else if (filterBy === "endDate") {
      sortedArray.sort((a, b) => (a.End_date > b.End_date ? 1 : -1));
    } else if (filterBy === "status") {
      sortedArray.sort((a, b) => (a.Deleted > b.Deleted ? 1 : -1)); // Active meds first
    }
    setSortedMeds(sortedArray);
  }, [filterBy, TotalMeds]);

  return (
    <TableContainer component={Paper}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
          borderBottom: "1px solid #ccc",
          position: "relative", // Keep elements in position
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            flex: 1,
            textAlign: "center",
            position: "absolute", // Centered text won't shift
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Medicine History
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginLeft: "auto", // Push dropdown and close button to the far right
          }}
        >
          <Select
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value)}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  zIndex: 1300,
                },
              },
            }}
          >
            <MenuItem value="status">Status (Active/Inactive)</MenuItem>
            <MenuItem value="startDate">Start Date</MenuItem>
            <MenuItem value="endDate">End Date</MenuItem>
          </Select>
          <IconButton
            onClick={closeMedicineHistory}
            sx={{ color: "gray" }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#0CAF5D" }}>
            <TableCell sx={{ color: "white" }}>Sr No</TableCell>
            <TableCell sx={{ color: "white" }}>Trade Name</TableCell>
            <TableCell sx={{ color: "white" }}>Salt</TableCell>
            <TableCell sx={{ color: "white" }}>Route</TableCell>
            <TableCell sx={{ color: "white" }}>Dosage</TableCell>
            <TableCell sx={{ color: "white" }}>Frequency</TableCell>
            <TableCell sx={{ color: "white" }}>Strength</TableCell>
            <TableCell sx={{ color: "white" }}>Prescribed By</TableCell>
            <TableCell sx={{ color: "white" }}>Start Date</TableCell>
            <TableCell sx={{ color: "white" }}>End Date</TableCell>
            <TableCell sx={{ color: "white" }}>Discontinued By</TableCell>
            <TableCell sx={{ color: "white" }}>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedMeds.map((med, index) => {
            const cellStyle = {
              color: med.Deleted !== "true" ? "#0CAF5D" : "inherit",
            };

            return (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Trade_Name)}
                </TableCell>
                <TableCell sx={cellStyle}>{displayValue(med.Salt)}</TableCell>
                <TableCell sx={cellStyle}>{displayValue(med.Route)}</TableCell>
                <TableCell sx={cellStyle}>
                  {`${displayValue(med.Number) || ""} ${
                    displayValue(med.Unit) || ""
                  }`}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Frequency)}
                </TableCell>
                <TableCell sx={cellStyle}>{displayValue(med.Number)}</TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Prescribed_by)}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Start_date)}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.End_date)}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Discontinued_by)}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {displayValue(med.Delete_Reason)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MedicineHistory;
