/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Papa from "papaparse";

export const fetchCSV = async () => {
  const response = await fetch("/FormattedSheet.csv");
  const csvText = await response.text();
  const salts: string[] = [];
  // Parse the CSV file
  Papa.parse(csvText, {
    header: true,
    skipEmptyLines: true,
    complete: (result: any) => {
      const data = result.data;
      salts.push(
        ...data
          .map((row: any) => row["SALT NAME"])
          .filter((salt: string | undefined) => salt?.trim())
      );
    },
  });

  const uniqueSalts = Array.from(new Set(salts));

  return uniqueSalts;
};

interface AllergyCSVRow {
  Allergen: string;
  "Allergen Class": string;
  Reaction: string;
  Categorization: string;
}

export const assignCategoriesToAllergies = async (
  allergies: any[]
): Promise<any[]> => {
  try {
    // Fetch and parse the CSV file
    const fetchCSV = async (filePath: string): Promise<AllergyCSVRow[]> => {
      const response = await fetch(filePath);
      const csvText = await response.text();

      return new Promise((resolve, reject) => {
        Papa.parse<AllergyCSVRow>(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            if (result.errors.length) {
              reject(result.errors);
            } else {
              resolve(result.data);
            }
          },
        });
      });
    };

    const csvData = await fetchCSV("/AllergiesSheet.csv");

    // Update the allergies array with the appropriate category
    const updatedAllergies = allergies.map((allergy) => {
      const allergenClass = allergy.Allergen_Class;
      const reaction = allergy.Reaction[0]; // Take the first reaction

      const matchingRow = csvData.find((row) => {
        // Find the matching row in the CSV file
        const csvAllergenClasses = row["Allergen Class"]
          ?.toLowerCase()
          .split(",")
          .map((cls: any) => cls.trim());
        return (
          csvAllergenClasses?.includes(allergenClass?.toLowerCase()) &&
          row.Reaction.toLowerCase() === reaction?.toLowerCase()
        );
      });

      return {
        ...allergy,
        Category: matchingRow ? matchingRow.Categorization : allergy.Category,
      };
    });

    return updatedAllergies;
  } catch (error) {
    console.error("Error while assigning categories to allergies:", error);
    throw error;
  }
};
export const areRequiredAttributesPresent = (
  object: any,
  requiredKeys: string[] = ["Allergen", "Reaction", "Category", "Start_date"]
) => {
  return requiredKeys.every((key: any) => {
    const value = object[key];
    return value !== null && value !== undefined && value !== "";
  });
};
