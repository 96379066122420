import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Stack, Typography, Box } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { commonStyles } from "../../../../constants/StyleConstants";
import { RootState } from "../../../../state/reducers";
import { SocialHxFormatters } from "../../../../utils/socialHxFormatters";
import { setField, setSocialHxOption } from "../../../../state/SocialHxSlice";
import DropdownField from "../ui-components/DropdownFIeld";
import InputField from "../ui-components/InputField";
import { getFieldOptions } from "../../../../utils/BinUtils";
import MultiSelectListField from "../ui-components/MultiSelectList";

interface SocialHxEditTitleProps {
  title: string;
  incomplete: boolean;
}

export const SocialHxEditTitle: React.FC<SocialHxEditTitleProps> = ({
  title,
  incomplete,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography
        variant="h6"
        component="div"
        sx={commonStyles.EditTitle(incomplete)}
      >
        {title}
      </Typography>
      <ArrowDropDownIcon sx={{ fontSize: "1.2vw" }} />
    </Stack>
  );
};

export interface EditProps {
  onClose: () => void;
}

export const MaritalStatusEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Marital_Status } = useSelector((state: RootState) => state.socialHx);
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatField(
    Marital_Status,
    "Marital Status"
  );
  const MaritalStatusOptions = getFieldOptions("socialHx", "Marital_Status");
  const handleMaritalStatusChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "Other") {
      dispatch(setSocialHxOption({ option: "Marital_Status", value: true }));
    }
    dispatch(setField({ field: "Marital_Status", value: event.target.value }));
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Marital Status"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="row" spacing={1}>
          {socialhxOtherOptions.Marital_Status ? (
            <InputField
              label="Marital Status"
              value={Marital_Status}
              onChange={handleMaritalStatusChange}
              required
            />
          ) : (
            <DropdownField
              label="Marital Status"
              value={Marital_Status}
              onChange={handleMaritalStatusChange}
              options={MaritalStatusOptions}
              required
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const IdentityEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Identity } = useSelector((state: RootState) => state.socialHx);
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatField(Identity, "Identity");
  const IdentityOptions = getFieldOptions("socialHx", "Identity");
  const handleIdentityChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "Other") {
      dispatch(setSocialHxOption({ option: "Identity", value: true }));
    }
    dispatch(setField({ field: "Identity", value: event.target.value }));
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Identity"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="row" spacing={1}>
          {socialhxOtherOptions.Identity ? (
            <InputField
              label="Identity"
              value={Identity}
              onChange={handleIdentityChange}
              required
            />
          ) : (
            <DropdownField
              label="Identity"
              value={Identity}
              onChange={handleIdentityChange}
              options={IdentityOptions}
              required
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const EducationEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Education } = useSelector((state: RootState) => state.socialHx);
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatField(Education, "Education");
  const EducationOptions = getFieldOptions("socialHx", "Education");
  const handleEducationChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "Education", value: event.target.value }));
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Education"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="row" spacing={1}>
          <DropdownField
            label="Education"
            value={Education}
            onChange={handleEducationChange}
            options={EducationOptions}
            required
            width="100%"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export const RoutineEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Routine } = useSelector((state: RootState) => state.socialHx);
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatField(Routine, "Routine");
  const RoutineOptions = getFieldOptions("socialHx", "Routine");
  const handleRoutineChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "Other") {
      dispatch(setSocialHxOption({ option: "Routine", value: true }));
    }
    dispatch(setField({ field: "Routine", value: event.target.value }));
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Routine"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="row" spacing={1}>
          {socialhxOtherOptions.Routine ? (
            <InputField
              label="Routine"
              value={Routine}
              onChange={handleRoutineChange}
              required
            />
          ) : (
            <DropdownField
              label="Routine"
              value={Routine}
              onChange={handleRoutineChange}
              options={RoutineOptions}
              required
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const OccupationEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Occupation, Occupation_Type } = useSelector(
    (state: RootState) => state.socialHx
  );
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatField(
    Occupation,
    "Occupation",
    Occupation_Type
  );
  const OccupationTypeOptions = getFieldOptions("socialHx", "Occupation_Type");
  const handleOccupationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setField({ field: "Occupation", value: event.target.value }));
  };
  const handleOccupationTypeChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "Other") {
      dispatch(setSocialHxOption({ option: "Occupation_Type", value: true }));
    }
    dispatch(setField({ field: "Occupation_Type", value: event.target.value }));
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Occupation"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="column" spacing={1}>
          <InputField
            label="Occupation"
            value={Occupation}
            onChange={handleOccupationChange}
            required
          />
          {socialhxOtherOptions.Occupation_Type ? (
            <InputField
              label="Occupation Type"
              value={Occupation_Type}
              onChange={handleOccupationTypeChange}
              required
            />
          ) : (
            <DropdownField
              label="Occupation Type"
              value={Occupation_Type}
              onChange={handleOccupationTypeChange}
              options={OccupationTypeOptions}
              required
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const SleepEdit: React.FC<EditProps> = ({ onClose }) => {
  const { Sleep_Same_Time, Sleep_Duration, Sleep_Quality, Insomnia_Symptoms } =
    useSelector((state: RootState) => state.socialHx);
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatSleepTitle(
    Sleep_Same_Time,
    Sleep_Duration,
    Sleep_Quality,
    Insomnia_Symptoms
  );
  const SleepSameTimeOptions = getFieldOptions("socialHx", "Sleep_Same_Time");
  const SleepDurationOptions = getFieldOptions("socialHx", "Sleep_Duration");
  const SleepQualityOptions = getFieldOptions("socialHx", "Sleep_Quality");
  const InsomniaSymptomsOptions = getFieldOptions(
    "socialHx",
    "Insomnia_Symptoms"
  );
  const handleSleepSameTimeChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "Sleep_Same_Time", value: event.target.value }));
  };
  const handleSleepQualityChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "Other") {
      dispatch(setSocialHxOption({ option: "Sleep_Quality", value: true }));
    }
    dispatch(setField({ field: "Sleep_Quality", value: event.target.value }));
  };
  const handleSleepDurationChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "Sleep_Duration", value: event.target.value }));
  };
  const handleInsomniaSymptomsChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setField({ field: "Insomnia_Symptoms", value: event.target.value })
    );
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Sleep"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="column" spacing={1}>
          <DropdownField
            label="Does the patient sleep at the same time?"
            value={Sleep_Same_Time}
            onChange={handleSleepSameTimeChange}
            options={SleepSameTimeOptions}
            required
            width="100%"
          />
          {socialhxOtherOptions.Sleep_Quality ? (
            <InputField
              label="Sleep Quality"
              value={Sleep_Quality}
              onChange={handleSleepQualityChange}
              required
            />
          ) : (
            <DropdownField
              label="Sleep Quality"
              value={Sleep_Quality}
              onChange={handleSleepQualityChange}
              options={SleepQualityOptions}
              required
              width="100%"
            />
          )}
          <DropdownField
            label="Sleep Duration"
            value={Sleep_Duration}
            onChange={handleSleepDurationChange}
            options={SleepDurationOptions}
            required
            width="100%"
          />
          <DropdownField
            label="Has the patient observed any insomnia symptoms?"
            value={Insomnia_Symptoms}
            onChange={handleInsomniaSymptomsChange}
            options={InsomniaSymptomsOptions}
            required
            width="100%"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export const StressEdit: React.FC<EditProps> = ({ onClose }) => {
  const {
    Stress_Level,
    Stress_Symptoms,
    Stress_Management,
    Stress_Symptoms_Other,
    Stress_Management_Other,
  } = useSelector((state: RootState) => state.socialHx);
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatStressTitle(
    Stress_Level,
    Stress_Symptoms,
    Stress_Management
  );
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const StressLevelOptions = getFieldOptions("socialHx", "Stress_Level");
  const StressSymptomsOptions = getFieldOptions("socialHx", "Stress_Symptoms");
  const StressManagementOptions = getFieldOptions(
    "socialHx",
    "Stress_Management"
  );
  const handleStressLevelChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "Stress_Level", value: event.target.value }));
  };

  const handleStressSymptomsChange = (val: string[]) => {
    if (val.includes("Other")) {
      dispatch(setSocialHxOption({ option: "Stress_Symptoms", value: true }));
    } else {
      dispatch(setSocialHxOption({ option: "Stress_Symptoms", value: false }));
    }
    dispatch(setField({ field: "Stress_Symptoms", value: val }));
  };

  const handleStressSymptomsOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setField({ field: "Stress_Symptoms_Other", value: event.target.value })
    );
  };

  const handleStressManagementChange = (val: string[]) => {
    if (val.includes("Other")) {
      dispatch(setSocialHxOption({ option: "Stress_Management", value: true }));
    } else {
      dispatch(
        setSocialHxOption({ option: "Stress_Management", value: false })
      );
    }
    dispatch(setField({ field: "Stress_Management", value: val }));
  };

  const handleStressManagementOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setField({ field: "Stress_Management_Other", value: event.target.value })
    );
  };
  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Stress"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="column" spacing={1}>
          <DropdownField
            label="Stress Level"
            value={Stress_Level}
            onChange={handleStressLevelChange}
            options={StressLevelOptions}
            required
            width="100%"
          />

          <MultiSelectListField
            name="Stress Symptoms"
            label="Stress Symptoms"
            value={Stress_Symptoms}
            options={StressSymptomsOptions}
            onChange={handleStressSymptomsChange}
            required
          />

          {socialhxOtherOptions.Stress_Symptoms && (
            <InputField
              label="Specify The Other Stress Symptoms"
              value={Stress_Symptoms_Other}
              onChange={handleStressSymptomsOtherChange}
              required
            />
          )}

          <MultiSelectListField
            name="Stress Management"
            label="Stress Management"
            value={Stress_Management}
            options={StressManagementOptions}
            onChange={handleStressManagementChange}
            required
          />

          {socialhxOtherOptions.Stress_Management && (
            <InputField
              label="Specify The Other Stress Management"
              value={Stress_Management_Other}
              onChange={handleStressManagementOtherChange}
              required
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const DietEdit: React.FC<EditProps> = ({ onClose }) => {
  const {
    Set_Meal_Schedule,
    Diet_Pattern,
    cups_fruit,
    cups_veggies,
    helpings_packaged,
    specific_consumptions,
    sugar_spoons,
    Artificial_Sweetener,
    Diet_Pattern_Other,
  } = useSelector((state: RootState) => state.socialHx);
  const dispatch = useDispatch();
  const { incomplete } = SocialHxFormatters.formatDiet(
    Set_Meal_Schedule,
    Diet_Pattern,
    cups_fruit,
    cups_veggies,
    helpings_packaged,
    specific_consumptions,
    sugar_spoons,
    Artificial_Sweetener
  );
  const socialhxOtherOptions = useSelector(
    (state: RootState) => state.socialHx.socialhxOtherOptions
  );
  const SetMealScheduleOptions = getFieldOptions(
    "socialHx",
    "Set_Meal_Schedule"
  );
  const DietPatternOptions = getFieldOptions("socialHx", "Diet_Pattern");
  const CupsFruitOptions = getFieldOptions("socialHx", "cups_fruit");
  const CupsVeggiesOptions = getFieldOptions("socialHx", "cups_veggies");
  const HelpingsPackagedOptions = getFieldOptions(
    "socialHx",
    "helpings_packaged"
  );
  const SpecificConsumptionsOptions = getFieldOptions(
    "socialHx",
    "specific_consumptions"
  );
  const SugarSpoonsOptions = getFieldOptions("socialHx", "sugar_spoons");
  const ArtificialSweetenerOptions = getFieldOptions(
    "socialHx",
    "Artificial_Sweetener"
  );

  const handleSetMealScheduleChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setField({ field: "Set_Meal_Schedule", value: event.target.value })
    );
  };

  const handleDietPatternChange = (val: string[]) => {
    if (val.includes("Other")) {
      dispatch(setSocialHxOption({ option: "Diet_Pattern", value: true }));
    } else {
      dispatch(setSocialHxOption({ option: "Diet_Pattern", value: false }));
    }
    dispatch(setField({ field: "Diet_Pattern", value: val }));
  };

  const handleDietPatternOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setField({ field: "Diet_Pattern_Other", value: event.target.value })
    );
  };

  const handleCupsFruitChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "cups_fruit", value: event.target.value }));
  };

  const handleCupsVeggiesChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "cups_veggies", value: event.target.value }));
  };

  const handleHelpingsPackagedChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setField({ field: "helpings_packaged", value: event.target.value })
    );
  };

  const handleSugarSpoonsChange = (event: SelectChangeEvent<string>) => {
    dispatch(setField({ field: "sugar_spoons", value: event.target.value }));
  };

  const handleArtificialSweetenerChange = (
    event: SelectChangeEvent<string>
  ) => {
    dispatch(
      setField({ field: "Artificial_Sweetener", value: event.target.value })
    );
  };

  const handleSpecificConsumptionsChange = (val: string[]) => {
    dispatch(setField({ field: "specific_consumptions", value: val }));
  };

  return (
    <Box>
      {/* Title Section */}
      <div onClick={onClose}>
        <SocialHxEditTitle title={"Dietary Habits"} incomplete={incomplete} />
      </div>
      {/* Edit Box Section */}
      <Box sx={commonStyles.EditBox}>
        <Stack direction="column" spacing={1}>
          <DropdownField
            label="Does the patient follow a set meal plan?"
            value={Set_Meal_Schedule}
            onChange={handleSetMealScheduleChange}
            options={SetMealScheduleOptions}
            required
            width="100%"
          />

          <MultiSelectListField
            name="Diet Patterns"
            label="Has the patient observed any of the following patterns?"
            value={Diet_Pattern}
            onChange={handleDietPatternChange}
            options={DietPatternOptions}
            required
          />
          {socialhxOtherOptions.Diet_Pattern && (
            <InputField
              label="Specify The Other Diet Patterns"
              value={Diet_Pattern_Other}
              onChange={handleDietPatternOtherChange}
              required
            />
          )}

          <Stack
            direction="row"
            alignItems={"space-between"}
            justifyContent={"space-between"}
          >
            <DropdownField
              label="cups of fruits"
              value={cups_fruit}
              onChange={handleCupsFruitChange}
              options={CupsFruitOptions}
              required
              width="48%"
            />

            <DropdownField
              label="cups of veggies"
              value={cups_veggies}
              onChange={handleCupsVeggiesChange}
              options={CupsVeggiesOptions}
              required
              width="48%"
            />
          </Stack>

          <DropdownField
            label="# of helpings of packed/canned/frozen food per day"
            value={helpings_packaged}
            onChange={handleHelpingsPackagedChange}
            options={HelpingsPackagedOptions}
            required
            width="100%"
          />

          <MultiSelectListField
            name="Has the Patient consumed any of the following?"
            label="Has the Patient consumed any of the following in the day?"
            value={specific_consumptions}
            options={SpecificConsumptionsOptions}
            onChange={handleSpecificConsumptionsChange}
            required
          />

          <DropdownField
            label="Tea spoons of sugar in the patient's diet?"
            value={sugar_spoons}
            onChange={handleSugarSpoonsChange}
            options={SugarSpoonsOptions}
            required
            width="100%"
          />

          <DropdownField
            label="Artificial sweetener exposure?"
            value={Artificial_Sweetener}
            onChange={handleArtificialSweetenerChange}
            options={ArtificialSweetenerOptions}
            required
            width="100%"
          />
        </Stack>
      </Box>
    </Box>
  );
};
