//This file may be a bit lengthy, but it has all helper view functions for socialHx.
import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { commonStyles } from "../../../../constants/StyleConstants";
import { RootState } from "../../../../state/reducers";
import { SocialHxFormatters } from "../../../../utils/socialHxFormatters";

interface ViewProps {
  showSuccessMessage: boolean;
}

export const MaritalStatusView: React.FC<ViewProps> = ({
  showSuccessMessage,
}) => {
  const { Marital_Status } = useSelector((state: RootState) => state.socialHx);
  const { formattedValue, incomplete } = SocialHxFormatters.formatField(
    Marital_Status,
    "Marital Status"
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems={"center"}
      sx={commonStyles.stack(opacity)}
    >
      <Typography
        variant="h4"
        component="div"
        sx={commonStyles.title(incomplete)}
      >
        Marital Status{!incomplete && ": "}
      </Typography>
      <Typography component="span" sx={commonStyles.value(incomplete)}>
        {SocialHxFormatters.TrimString(formattedValue)}
      </Typography>
    </Stack>
  );
};

export const IdentityView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Identity } = useSelector((state: RootState) => state.socialHx);

  const { formattedValue, incomplete } = SocialHxFormatters.formatField(
    Identity,
    "Identity"
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Typography
        variant="h4"
        component="div"
        sx={commonStyles.title(incomplete)}
      >
        Identity{!incomplete && ": "}
      </Typography>
      <Typography component="span" sx={commonStyles.value(incomplete)}>
        {SocialHxFormatters.TrimString(formattedValue)}
      </Typography>
    </Stack>
  );
};

export const OccupationView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Occupation, Occupation_Type } = useSelector(
    (state: RootState) => state.socialHx
  );

  const { formattedValue, incomplete } = SocialHxFormatters.formatField(
    Occupation,
    "Occupation",
    Occupation_Type
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={commonStyles.stack(opacity)}
    >
      <Stack
        direction="row"
        justifyContent={"flex-start"}
        sx={commonStyles.stack2(opacity)}
      >
        <Typography
          variant="h4"
          component="div"
          sx={commonStyles.title(incomplete)}
        >
          Occupation{formattedValue !== "" && ": "}
        </Typography>
        <Typography component="span" sx={commonStyles.value(incomplete)}>
          {SocialHxFormatters.TrimString(formattedValue)}
        </Typography>
      </Stack>
      <Typography component="span" sx={commonStyles.subvalue}>
        {SocialHxFormatters.capitalizeFirstWord(
          SocialHxFormatters.TrimString(Occupation_Type)
        )}
      </Typography>
    </Stack>
  );
};

export const EducationView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Education } = useSelector((state: RootState) => state.socialHx);

  const { formattedValue, incomplete } = SocialHxFormatters.formatField(
    Education,
    "Education"
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Typography
        variant="h4"
        component="div"
        sx={commonStyles.title(incomplete)}
      >
        Education{!incomplete && ": "}
      </Typography>
      <Typography component="span" sx={commonStyles.value(incomplete)}>
        {SocialHxFormatters.TrimString(formattedValue)}
      </Typography>
    </Stack>
  );
};

export const RoutineView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Routine } = useSelector((state: RootState) => state.socialHx);

  const { formattedValue, incomplete } = SocialHxFormatters.formatField(
    Routine,
    "Routine"
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Typography
        variant="h4"
        component="div"
        sx={commonStyles.title(incomplete)}
      >
        Routine{!incomplete && ": "}
      </Typography>
      <Typography component="span" sx={commonStyles.value(incomplete)}>
        {SocialHxFormatters.TrimString(formattedValue)}
      </Typography>
    </Stack>
  );
};

export const DietView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const {
    Set_Meal_Schedule,
    Diet_Pattern,
    cups_fruit,
    cups_veggies,
    helpings_packaged,
    specific_consumptions,
    sugar_spoons,
    Artificial_Sweetener,
  } = useSelector((state: RootState) => state.socialHx);
  const { DietTitle, DietSubtitle, incomplete } = SocialHxFormatters.formatDiet(
    Set_Meal_Schedule,
    Diet_Pattern,
    cups_fruit,
    cups_veggies,
    helpings_packaged,
    specific_consumptions,
    sugar_spoons,
    Artificial_Sweetener
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Typography
        variant="h4"
        component="div"
        sx={commonStyles.title(incomplete)}
      >
        {DietTitle}
      </Typography>
      <Typography component="span" sx={commonStyles.subvalue}>
        {DietSubtitle}
      </Typography>
    </Stack>
  );
};

export const ExerciseView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Exercise } = useSelector((state: RootState) => state.socialHx);
  const { ExerciseTitle, ExerciseSubtitle, incomplete } =
    SocialHxFormatters.getExerciseInfo(Exercise);
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={commonStyles.stack(opacity)}
      >
        <Typography
          variant="h4"
          component="div"
          sx={commonStyles.title(incomplete)}
        >
          Exercise{ExerciseTitle !== "" && ": "}
        </Typography>
        <Typography component="span" sx={commonStyles.value(incomplete)}>
          {SocialHxFormatters.TrimString(ExerciseTitle)}
        </Typography>
      </Stack>
      <Typography component="span" sx={commonStyles.subvalue}>
        {ExerciseSubtitle}
      </Typography>
    </Stack>
  );
};

export const SleepView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Sleep_Same_Time, Sleep_Duration, Sleep_Quality, Insomnia_Symptoms } =
    useSelector((state: RootState) => state.socialHx);
  const { SleepTitle, incomplete } = SocialHxFormatters.formatSleepTitle(
    Sleep_Same_Time,
    Sleep_Duration,
    Sleep_Quality,
    Insomnia_Symptoms
  );
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={commonStyles.stack(opacity)}
      >
        <Stack direction="row" justifyContent="flex-start">
          <Typography
            variant="h4"
            component="div"
            sx={commonStyles.title(incomplete)}
          >
            Sleep{SleepTitle !== "" && ": "}
          </Typography>
          <Typography component="span" sx={commonStyles.value(incomplete)}>
            {SleepTitle}
          </Typography>
        </Stack>

        <Typography component="span" sx={commonStyles.subvalue}>
          {SocialHxFormatters.capitalizeFirstWord(Sleep_Duration)}
        </Typography>
      </Stack>
      <Typography component="span" sx={commonStyles.subvalue}>
        {SocialHxFormatters.capitalizeFirstWord(
          SocialHxFormatters.TrimString(Sleep_Quality)
        )}
      </Typography>
    </Stack>
  );
};

export const StressView: React.FC<ViewProps> = ({ showSuccessMessage }) => {
  const { Stress_Level, Stress_Management, Stress_Symptoms } = useSelector(
    (state: RootState) => state.socialHx
  );
  const { StressTitle, incomplete } = SocialHxFormatters.formatStressTitle(
    Stress_Level,
    Stress_Management,
    Stress_Symptoms
  );
  const StressSubtitle =
    SocialHxFormatters.formatStressManagement(Stress_Management);
  const opacity = showSuccessMessage ? 0.5 : 1;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      sx={commonStyles.stack(opacity)}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={commonStyles.stack(opacity)}
      >
        <Typography
          variant="h4"
          component="div"
          sx={commonStyles.title(incomplete)}
        >
          Stress Level{StressTitle !== "" && ": "}
        </Typography>
        <Typography component="span" sx={commonStyles.value(incomplete)}>
          {StressTitle}
        </Typography>
      </Stack>
      <Typography component="span" sx={commonStyles.subvalue}>
        {SocialHxFormatters.capitalizeFirstWord(
          SocialHxFormatters.TrimString(StressSubtitle)
        )}
      </Typography>
    </Stack>
  );
};
